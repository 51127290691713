import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import city from "@/store/modules/city";
import shippingRoute from "@/store/modules/shippingRoute";
import customer from "@/store/modules/customer";
import customerDelegate from "@/store/modules/customerDelegate";
import customerProduct from "@/store/modules/customerProduct";
import deliver from "@/store/modules/deliver";
import shipment from "@/store/modules/shipment";
import trip from "@/store/modules/trip";
import adjustmentCustomer from "@/store/modules/adjustmentCustomer";
import shipmentReturnedToCustomer from "@/store/modules/shipmentReturnedToCustomer";
import user from "@/store/modules/user";
import auth from "@/store/modules/auth";
import statistic from "@/store/modules/statistic";
import financial from "@/store/modules/financial";
import typeExpense from "@/store/modules/typeExpense";
import expense from "@/store/modules/expense";
import typeRevenue from "@/store/modules/typeRevenue";
import revenue from "@/store/modules/revenue";
import branch from "@/store/modules/branch";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        app,
        city,
        shippingRoute,
        customer,
        customerDelegate,
        customerProduct,
        deliver,
        shipment,
        trip,
        user,
        auth,
        adjustmentCustomer,
        shipmentReturnedToCustomer,
        statistic,
        financial,
        typeExpense,
        expense,
        typeRevenue,
        revenue,
        branch
    }
});
