import typeApi from "@/apis/typeRevenueApi";

/**
 * Add short type object
 * This function it returns only properties that typesCitites needs them
 * @returns {object} type
 */
function shortType(type) {
    return {
        id: type.id,
        name: type.name
    };
}

export default {
    namespaced: true,

    state: {
        types: [],
        typesShort: []
    },

    getters: {
        getById: state => id => {
            return state.typesShort.length && state.typesShort.find(item => item.id === id);
        }
    },

    mutations: {
        ADD_ITEM(state, type) {
            if (state.types.data) {
                state.types.data.unshift(type);
                state.types.meta.to++;
                state.types.meta.total++;
            }
            state.typesShort.push(shortType(type));
        },
        UPDATE_ITEM(state, type) {
            state.types.data = [...state.types.data.map(item => (item.id !== type.id ? item : type))];
            let index = state.typesShort.findIndex(e => e.id === type.id);
            state.typesShort[index] = shortType(type);
        },
        SET_ALL(state, types) {
            state.types = types;
        },
        SET_ALL_SHORT(state, types) {
            state.typesShort = types;
        }
    },

    actions: {
        add({ commit }, data) {
            return typeApi.create(data).then(r => {
                Toast.success("Added successfully");
                commit("ADD_ITEM", r.data.data);
            });
        },

        fetchAll({ commit }, { page = 1, params = {} }) {
            return typeApi.getAll(page, params).then(r => {
                commit("SET_ALL", r.data);
            });
        },

        fetchAllShort({ commit, state }) {
            if (!state.typesShort.length) {
                return typeApi.getAllShort().then(r => {
                    commit("SET_ALL_SHORT", r.data.data);
                });
            }
        },

        update({ commit }, data) {
            return typeApi.update(data).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE_ITEM", r.data.data);
            });
        }
    }
};
