<template>
    <div>
        <breadcrumbs :items="breadcrumbs" add-link="/shipments-returned-to-customers/create" />

        <base-card class="my-4 mx-auto" :icon="$t('shipments_returned_to_customers.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline card-main-title">{{ returning.id }}</div>

                    <div>
                        <!-- btn edit item  -->
                        <v-btn fab small dark :color="$store.state.app.color" :to="`/shipments-returned-to-customers/update/${returning.id}`">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>

                        <v-btn
                            fab
                            small
                            color="blue-grey darken-3"
                            dark
                            class="ms-1"
                            :to="`/shipments-returned-to-customers/print/${returning.id}`"
                            target="_blank"
                        >
                            <v-icon>mdi-printer</v-icon>
                        </v-btn>
                    </div>
                </div>
            </template>

            <template #content>
                <v-divider></v-divider>

                <!-- Start Trip info -->
                <v-card flat>
                    <div class="px-5">
                        <v-row>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field
                                    :value="`${returning.customer_id} - ${returning.customer.name}`"
                                    :label="$t('attributes.customer')"
                                    readonly
                                    append-icon="mdi-information-outline"
                                    @click:append="$router.push(`/customers/${returning.customer_id}`)"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="returning.count" :label="$t('attributes.count')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="returning.date" :label="$t('attributes.date')" readonly></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="6">
                                <v-textarea v-model.trim="returning.extra" :label="$t('attributes.extra')" rows="1" auto-grow readonly></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea v-model.trim="returning.note" :label="$t('attributes.note')" rows="1" auto-grow readonly></v-textarea>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field
                                    :value="returning.created_at | datetimeFormat"
                                    :label="$t('attributes.created_at')"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field
                                    :value="returning.updated_at | datetimeFormat"
                                    :label="$t('attributes.updated_at')"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="returning.creator.name" :label="$t('attributes.created_by')" readonly></v-text-field>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="mb-5 ">
                        <v-divider></v-divider>

                        <!-- Start shipment items -->
                        <app-header
                            :label="`${$t('attributes.shipments')} (${returning.shipments.length})`"
                            :prepend-inner-icon="$t('shipments.icon')"
                            class="px-5 py-3"
                        ></app-header>

                        <v-data-table
                            :headers="headers"
                            :items="returning.shipments"
                            hide-default-footer
                            :items-per-page="1000"
                            class="elevation-1 mt-5"
                        >
                            <template v-slot:item.code="{ item }">
                                <v-btn text color="info" :to="`/shipments/${item.id}`">{{ item.code }}</v-btn>
                            </template>
                            <template v-slot:item.state.name="{ item }">
                                <v-chip :color="item.state.color" dark>{{ item.state.name }}</v-chip>
                            </template>
                            <template v-slot:item.price_with_shipping="{ item }">
                                {{ item.price + (item.fee_on_customer ? 0 : item.fee) }}
                            </template>
                        </v-data-table>
                        <!-- End shipment items -->
                    </div>
                </v-card>
                <!-- End Trip info -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import store from "@/store/index";

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        let id = routeTo.params.id;
        store.dispatch("shipmentReturnedToCustomer/fetchById", id).then(() => {
            next();
        });
    },

    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t("shipments_returned_to_customers.title"),
                    to: "/shipments-returned-to-customers",
                    exact: true,
                    disabled: false
                },
                {
                    text: this.$route.params.id,
                    disabled: true
                }
            ],
            headers: [
                { text: "#", value: "index", width: "30", sortable: false },
                { text: this.$t("attributes.shipment"), value: "code" },
                { text: this.$t("attributes.state"), value: "state.name" },
                { text: this.$t("attributes.receiver_phone"), value: "receiver_phone" },
                { text: this.$t("attributes.city"), value: "city.name" },
                { text: this.$t("attributes.price_with_shipping"), value: "price_with_shipping" },
                { text: this.$t("attributes.fee"), value: "fee" },
                { text: this.$t("attributes.created_at"), value: "created_at" }
            ]
        };
    },
    created() {
        this.returning.shipments.map((e, index) => (e.index = index + 1));
    },
    computed: {
        ...mapState({
            returning: state => state.shipmentReturnedToCustomer.item
        })
    }
};
</script>
