<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('adjustment_customers.available_balance.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("adjustment_customers.available_balance.title") }}</div>
                </div>
            </template>

            <template #content>
                <v-divider></v-divider>

                <!-- Start search panel -->
                <v-expansion-panels class="" focusable>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="justify-start">
                            <h5 class="subtitle-1"><v-icon>mdi-magnify</v-icon> {{ $t("options.search") }}</h5>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <!-- Start search options -->
                            <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                                <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                                    <v-row align="center">
                                        <v-col cols="12" sm="6" lg="3" v-for="(field, index) in searchFields" :key="index">
                                            <v-text-field
                                                v-model.trim="search[field]"
                                                :label="$t(`attributes.${field}`)"
                                                maxlength="32"
                                                clearable
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="3">
                                            <validation-provider rules="select_object" :name="$t('attributes.city')" v-slot="{ errors }">
                                                <cities-field clearable v-model="search.city" :error-messages="errors" />
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="3">
                                            <validation-provider rules="select_object" :name="$t('attributes.branch')" v-slot="{ errors }">
                                                <branches-field clearable v-model="search.branch" :error-messages="errors" />
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <validation-provider
                                                rules="date|before_or_equal:today"
                                                vid="received_at_from"
                                                :name="$t('search.received_at_from')"
                                                v-slot="{ errors }"
                                            >
                                                <date-field
                                                    v-model="search.received_at_from"
                                                    :label="$t('search.received_at_from')"
                                                    :error-messages="errors"
                                                    clearable
                                                ></date-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <validation-provider
                                                rules="date|before_or_equal:today|after_or_equal:@received_at_from"
                                                vid="received_at_to"
                                                :name="$t('search.received_at_to')"
                                                v-slot="{ errors }"
                                            >
                                                <date-field
                                                    v-model="search.received_at_to"
                                                    :label="$t('search.received_at_to')"
                                                    :error-messages="errors"
                                                    clearable
                                                ></date-field>
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <validation-provider
                                                rules="date|before_or_equal:today"
                                                vid="created_at_from"
                                                :name="$t('search.created_at_from')"
                                                v-slot="{ errors }"
                                            >
                                                <date-field
                                                    v-model="search.created_at_from"
                                                    :label="$t('search.created_at_from')"
                                                    :error-messages="errors"
                                                    clearable
                                                ></date-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <validation-provider
                                                rules="date|before_or_equal:today|after_or_equal:@created_at_from"
                                                vid="created_at_to"
                                                :name="$t('search.created_at_to')"
                                                v-slot="{ errors }"
                                            >
                                                <date-field
                                                    v-model="search.created_at_to"
                                                    :label="$t('search.created_at_to')"
                                                    :error-messages="errors"
                                                    clearable
                                                ></date-field>
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                            <v-btn :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </validation-observer>
                            <!-- End search options -->
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <!-- End search panel -->

                <v-divider></v-divider>

                <v-data-table :headers="headers" :items="data.data" :items-per-page="100" hide-default-footer class="elevation-1">
                    <template v-slot:item.customer="{ item }">
                        <v-btn v-if="item.customer_id" text color="info" :to="`/customers/${item.customer_id}`">
                            <bdi>{{ item.customer_id }}</bdi> - {{ item.customer.name }}
                        </v-btn>
                        <template v-else>
                            {{ item.anonymous_sender.name }}
                        </template>
                    </template>
                    <template v-slot:item.count="{ item }">
                        {{ formatNumber(item.count) }}
                    </template>
                    <template v-slot:item.amount="{ item }">
                        {{ formatNumber(item.amount) }}
                    </template>
                </v-data-table>

                <!-- Start pagination -->
                <pagination :data="data.meta || {}" @page-changed="filterData" />
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";
import CitiesField from "@/components/data/CitiesField.vue";
import BranchesField from "@/components/data/BranchesField.vue";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;

    store.dispatch("adjustmentCustomer/fetchAvailableBalance", { page, params }).then(() => {
        next();
    });
}

export default {
    components: { CitiesField, BranchesField },

    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    data: function() {
        return {
            search: Object.assign({}, this.$route.query),
            searchFields: ["customer", "trip"],
            headers: [
                { text: this.$t("attributes.customer"), value: "customer" },
                { text: this.$t("attributes.amount"), value: "amount" },
                { text: this.$t("attributes.count"), value: "count" }
            ]
        };
    },

    computed: {
        ...mapState({
            data: state => state.adjustmentCustomer.adjustments
        })
    },

    methods: {
        formatNumber(val) {
            return Number(val).toLocaleString();
        },

        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            params.city = params.city && params.city.id;
            params.branch = params.branch && params.branch.id;
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        }
    }
};
</script>
