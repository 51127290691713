import Axios from "axios";

const base_url = "cp/users";

export default {
    /**
     * Display a listing of the Users.
     *
     * @param {int} page number of page for pagination
     * @param {object} params filter (search) options
     * @returns {Promise}
     */
    getAll(page = 1, params = {}) {
        return Axios.get(`${base_url}?page=${page}`, { params });
    },

    /**
     * Store a new User in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    create(data) {
        return Axios.post(base_url, data);
    },

    /**
     * Show data for a given User.
     *
     * @param {int} id
     * @param {boolean} withProducts return products of User or not
     * @returns {Promise}
     */
    getById(id, withProducts = false) {
        return Axios.get(`${base_url}/${id}`, { params: { withProducts } });
    },

    /**
     * Update the given User in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    update(data) {
        return Axios.put(`${base_url}/${data.id}`, data);
    },

    /**
     * Remove the specified User from server.
     *
     * @param {int} id
     * @returns {Promise}
     */
    delete(id) {
        return Axios.delete(`${base_url}/${id}`);
    }
};
