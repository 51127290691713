<template>
    <div>
        <div class="title-box">
            <h4 class="invoice-title">فاتورة ترجيع شحنات لزبون</h4>
        </div>

        <!-- Start trip info -->
        <v-row>
            <v-col cols="auto">
                <div class="trip-title">
                    رقم العملية : <bdi>{{ returning.id }}</bdi>
                </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <div class="trip-title">
                    التاريخ : <bdi>{{ returning.date }}</bdi>
                </div>
                <div class="trip-title">
                    الزبون : <bdi>{{ returning.customer.name }}</bdi>
                </div>
            </v-col>
        </v-row>
        <!-- End trip info -->

        <!-- Start shipments -->
        <v-simple-table class="table-info mt-0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>الرمز</th>
                        <th>المستلم</th>
                        <th>رقم المستلم</th>
                        <th>المدينة</th>
                        <th>القيمة مع الشحن</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in shipments" :key="item.id">
                        <td>{{ index + 1 }}</td>
                        <td>
                            <bdi class="no-wrap">{{ item.id }}</bdi>
                        </td>
                        <td>
                            <bdi>{{ item.receiver_name }}</bdi>
                        </td>
                        <td>
                            <bdi class="no-wrap">{{ item.receiver_phone }}</bdi>
                        </td>
                        <td>
                            <bdi>{{ item.city.name }}</bdi>
                        </td>
                        <td>
                            <bdi class="no-wrap">{{ item.amount }}</bdi>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <!-- End shipments -->

        <!-- Start summery -->
        <v-simple-table class="table-info">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>الموظف</th>
                        <th>التاريخ</th>
                        <th>العدد</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <bdi>{{ user.name }}</bdi>
                        </td>
                        <td>
                            <bdi>{{ statistics.date }}</bdi>
                        </td>
                        <td>
                            <bdi>{{ statistics.count }}</bdi>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <!-- End summery -->
    </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    store.dispatch("shipmentReturnedToCustomer/fetchById", routeTo.params.id).then(() => {
        next();
    });
}

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    computed: {
        ...mapState({
            returning: state => state.shipmentReturnedToCustomer.item,
            user: state => state.auth.user
        }),

        shipments() {
            this.returning.shipments.map(s => {
                s.id = _getShipmentCode(s);
                s.amount = s.price + (s.fee_on_customer ? 0 : s.fee);
            });
            return this.returning.shipments;
        },

        statistics() {
            return {
                count: this.shipments.length,
                amount: this.returning.amount, // this.shipments.reduce((sum, s) => sum + s.amount, 0),
                date: _getCurrentDateTime()
            };
        }
    },

    mounted() {
        setTimeout(() => {
            window.onafterprint = window.close;
            print();
        }, 1000);
    }
};
</script>

<style lang="scss" scoped>
.trip-title {
    font-family: "Cairo", sans-serif !important;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 12px;
    bdi {
        font-weight: normal;
    }
}
.mt-0 {
    margin-top: 0 !important;
}
body {
    direction: rtl;
    font-family: "Cairo", sans-serif !important;
    table,
    th,
    tr,
    td {
        font-family: "Cairo", sans-serif !important;
        border: thin solid rgba(0, 0, 0, 0.5);
        border-collapse: collapse;
        text-align: center;
        padding: 8px 5px !important;
        height: auto !important;
        font-size: 12px !important;
    }
    .title-box {
        text-align: center;
    }
    .invoice-title {
        font-family: "Cairo", sans-serif !important;
        padding: 5px;
        display: inline-block;
        color: #333;
        border-bottom: 1px solid;
    }
    .table-info {
        margin-top: 20px;
        th {
            background-color: #ddd;
        }
    }
    .no-wrap {
        white-space: nowrap;
    }
}
</style>
