<template>
    <v-row>
        <!-- Start user data -->
        <v-col md="3">
            <v-card>
                <div class="text-center pt-4">
                    <v-avatar size="164">
                        <v-img src="https://cdn.vuetifyjs.com/images/cards/cooking.png"></v-img>
                    </v-avatar>
                </div>

                <v-card-title class="text-center justify-center">{{ customer.name }}</v-card-title>

                <v-divider></v-divider>

                <v-list>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ customer.owner_name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-phone</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ customer.phone }}</v-list-item-title>
                            <v-list-item-title>{{ customer.phone2 }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-email</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ customer.email }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-map-marker</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>{{ customer.address }}</v-list-item-content>
                    </v-list-item>

                    <template v-if="customer.website">
                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-link</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <a target="_blank" :href="customer.website">{{ customer.website }}</a>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <template v-if="customer.file">
                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-file</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <a target="_blank" :href="customer.file_url">{{ customer.file }}</a>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-divider inset></v-divider>

                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-clock-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>{{ customer.created_at }}</v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-clock-outline</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>{{ customer.activated_at }}</v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-update</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>{{ customer.updated_at }}</v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-col>
        <!-- End user data -->

        <!-- Start tabs -->
        <v-col md="9">
            <template>
                <v-card>
                    <v-toolbar flat>
                        <v-tabs v-model="tabs" fixed-tabs>
                            <v-tab href="#customer-products" class="primary--text">{{ $t("attributes.products") }}</v-tab>
                            <v-tab href="#customer-delegates" class="primary--text">{{ $t("attributes.delegates") }}</v-tab>
                            <v-tab href="#customer-logins" class="primary--text">{{ $t("attributes.logins") }}</v-tab>
                            <v-tab href="#customer-shipments" class="primary--text">{{ $t("attributes.shipments") }}</v-tab>
                        </v-tabs>
                    </v-toolbar>

                    <v-tabs-items v-model="tabs">
                        <!-- Start products tab -->
                        <v-tab-item value="customer-products">
                            <v-card flat>
                                <app-products ref="customerProducts"></app-products>
                            </v-card>
                        </v-tab-item>
                        <!-- End products tab -->

                        <!-- Start delegates tab -->
                        <v-tab-item value="customer-delegates">
                            <v-card flat>
                                <customer-delegates ref="customerDelegates"></customer-delegates>
                            </v-card>
                        </v-tab-item>
                        <!-- End delegates tab -->

                        <!-- Start logins tab -->
                        <v-tab-item value="customer-logins">
                            <v-card flat>
                                <v-card-text>Logins tab</v-card-text>
                            </v-card>
                        </v-tab-item>
                        <!-- End logins tab -->

                        <!-- Start shipments tab -->
                        <v-tab-item value="customer-shipments">
                            <v-card flat>
                                <v-card-text>Shipments tab</v-card-text>
                            </v-card>
                        </v-tab-item>
                        <!-- End shipments tab -->
                    </v-tabs-items>
                </v-card>
            </template>
        </v-col>
        <!-- End tabs -->
    </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";
import AppProducts from "./Products";
import CustomerDelegates from "./Delegates";

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        store.dispatch("customer/fetchCustomer", routeTo.params.id).then(() => {
            next();
        });
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        this.$refs.customerProducts.fetchData(routeTo).then(() => {
            next();
        });
    },

    components: { AppProducts, CustomerDelegates },

    data: function() {
        return {
            tabs: null
        };
    },

    computed: {
        ...mapState({
            customer: state => state.customer.customer
        })
    }
};
</script>
