import Axios from "axios";

const base_url = "cp/customer-delegates";

export default {
    /**
     * Display a listing of the Customers delegates.
     *
     * @param {int} page number of page for pagination
     * @param {object} params filter (search) options
     * @returns {Promise}
     */
    getAll(page = 1, params = {}) {
        return Axios.get(`${base_url}`, { params: { ...params, page } });
    },

    /**
     * Store a new Customer Delegate in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    create(data) {
        return Axios.post(base_url, data);
    },

    /**
     * Show data for a given customer Delegate.
     *
     * @param {int} id
     * @returns {Promise}
     */
    getById(id) {
        return Axios.get(`${base_url}/${id}`);
    },

    /**
     * Get customer delegates depends on customer id.
     *
     * @param {int} id
     * @returns {Promise}
     */
    getByCustomerId(id) {
        return Axios.get(`${base_url}/short/${id}`);
    },

    /**
     * Update the given Customer Delegate in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    update(data) {
        return Axios.put(`${base_url}/${data.id}`, data);
    },

    /**
     * Remove the specified Customer Delegate from server.
     *
     * @param {int} id
     * @returns {Promise}
     */
    delete(id) {
        return Axios.delete(`${base_url}/${id}`);
    }
};
