import store from "@/store/index";

/**
 *  Check if current user has access permissions to do something
 *
 * @param {String|Array} permissions
 * @param {Boolean}
 */
window._hasPermissions = function(permissions) {
    return true;
    //if (Array.isArray(permissions)) {
    //    return permissions.some(p => store.state.auth.user.permissions.includes(p));
    //}
    //return store.state.auth.user.permissions.includes(permissions);
};

/**
 *  Format datetime
 *
 * @param {String} date
 * @param {Boolean}
 */
window._datetimeFormat = function(date) {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD hh:mm a");
};

/**
 *  Format date
 *
 * @param {String} date
 * @param {Boolean}
 */
window._dateFormat = function(date) {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD");
};

/**
 *  get Current date
 */
window._getCurrentDate = function() {
    return moment().format("YYYY-MM-DD");
};

/**
 *  get Current date time
 */
window._getCurrentDateTime = function() {
    return moment().format("YYYY-MM-DD hh:mm a");
};

/**
 *  get Shipment code
 */
window._getShipmentCode = function(shipment) {
    return `S${(shipment.customer_id && shipment.customer_id) || ""}-${shipment.city.code}${shipment.id}`;
};
