<template>
    <div>
        <breadcrumbs :items="breadcrumbs" add-link="/trips/create" />

        <base-card class="my-4 mx-auto" :icon="$t('trips.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline card-main-title">{{ trip.id }}</div>

                    <div>
                        <!-- btn edit item  -->
                        <v-btn v-if="trip.state.id != 'closed'" fab small dark :color="$store.state.app.color" :to="`/trips/update/${trip.id}`">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>

                        <v-btn fab small color="blue-grey darken-3" dark class="ms-1" :to="`/trips/print/${trip.id}`" target="_blank">
                            <v-icon>mdi-printer</v-icon>
                        </v-btn>
                    </div>
                </div>

                <!-- Start Add form dialog -->
                <dialog-base type="UPDATE" v-model="formDialog" @canceled="formDialogClose" @confirmed="validateAndSave">
                    <template #content>
                        <validation-observer ref="shipmentsForm" v-slot="{ handleSubmit }">
                            <v-form class="px-3" @submit.prevent="handleSubmit(validateAndSave)">
                                <!-- show errors if there is errors when add/update item -->
                                <has-errors :errors="errors"></has-errors>

                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            outlined
                                            v-model.trim="shipment.shipment_id"
                                            :label="$t('attributes.shipment')"
                                            readonly
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <validation-provider rules="required" :name="$t('attributes.state')" v-slot="{ errors }">
                                            <shipment-statuses-field
                                                outlined
                                                v-model="shipment.state"
                                                :error-messages="errors"
                                                :only-options="['on_the_way', 'missing', 'unable_to_deliver', 'returned_to_stock', 'deliverd']"
                                                @input="shipment.note = null"
                                            />
                                        </validation-provider>
                                    </v-col>

                                    <v-col cols="12">
                                        <validation-provider rules="max:100" :name="$t('attributes.note')" v-slot="{ errors }">
                                            <v-textarea
                                                v-model.trim="shipment.note"
                                                :label="$t('attributes.note')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="100"
                                                outlined
                                                rows="1"
                                                auto-grow
                                                clearable
                                            ></v-textarea>
                                        </validation-provider>
                                    </v-col>
                                </v-row>

                                <!-- This button to allow to user to submit form by clicking enter -->
                                <button type="submit" class="hide"></button>
                            </v-form>
                        </validation-observer>
                    </template>
                </dialog-base>
                <!-- End Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>

                <!-- Start Trip info -->
                <v-card flat>
                    <div class="px-5">
                        <v-row>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="trip.deliver.name" :label="$t('attributes.deliver')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="trip.branch.name" :label="$t('attributes.branch')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field
                                    v-model.trim="trip.shipping_route.name"
                                    :label="$t('attributes.shipping_route')"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="trip.state.name" :label="$t('attributes.state')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="trip.amount" :label="$t('attributes.amount')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="trip.deliver_fee" :label="$t('attributes.deliver_fee')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="trip.fee" :label="$t('attributes.fee')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field
                                    v-model.trim="trip.total_price_with_shipping_for_delivered"
                                    :label="$t('attributes.price_with_shipping')"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field
                                    :value="trip.total_price_with_shipping_for_delivered - trip.deliver_fee"
                                    :label="$t('attributes.price_after_deliver_fee')"
                                    readonly
                                    filled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="trip.fee - trip.deliver_fee" :label="$t('attributes.earnings')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="trip.start_date" :label="$t('attributes.start_date')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="trip.end_date" :label="$t('attributes.end_date')" readonly></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="6">
                                <v-textarea v-model.trim="trip.extra" :label="$t('attributes.extra')" rows="1" auto-grow readonly></v-textarea>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-textarea v-model.trim="trip.note" :label="$t('attributes.note')" rows="1" auto-grow readonly></v-textarea>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="trip.created_at | datetimeFormat" :label="$t('attributes.created_at')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="trip.updated_at | datetimeFormat" :label="$t('attributes.updated_at')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="trip.creator.name" :label="$t('attributes.created_by')" readonly></v-text-field>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="mb-5 ">
                        <v-divider></v-divider>

                        <!-- Start shipment items -->
                        <app-header
                            :label="`${$t('attributes.shipments')} (${trip.shipments.length})`"
                            :prepend-inner-icon="$t('shipments.icon')"
                            class="px-5 py-3"
                        ></app-header>

                        <v-data-table :headers="headers" :items="trip.shipments" hide-default-footer :items-per-page="1000" class="elevation-1 mt-5">
                            <template v-slot:item.shipment_id="{ item }">
                                <v-btn text color="info" :to="`/shipments/${item.shipment_id}`">{{ item.shipment.code }}</v-btn>
                            </template>
                            <template v-slot:item.state="{ item }">
                                <v-chip :color="item.state.color" dark>{{ item.state.name }}</v-chip>
                            </template>
                            <template v-slot:item.price="{ item }">
                                {{ item.shipment.price + (item.shipment.fee_on_customer ? 0 : item.shipment.fee) }}
                            </template>
                            <template v-slot:item.actions="{ item }" v-if="trip.state.id != 'closed'">
                                <v-btn x-small fab color="info" dark @click="prepareToUpdateItem(item)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                        <!-- End shipment items -->
                    </div>
                </v-card>
                <!-- End Trip info -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import store from "@/store/index";
import ShipmentStatusesField from "@/components/data/ShipmentStatusesField.vue";
import TripApi from "@/apis/tripApi";

export default {
    components: { ShipmentStatusesField },

    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        let id = routeTo.params.id;
        store.dispatch("trip/fetchTrip", id).then(() => {
            next();
        });
    },

    data() {
        return {
            breadcrumbs: [
                {
                    text: this.$t("trips.title"),
                    to: "/trips",
                    exact: true,
                    disabled: false
                },
                {
                    text: this.$route.params.id,
                    disabled: true
                }
            ],
            formDialog: false,
            shipment: {},
            errors: [],
            headers: [
                { text: "#", value: "index", width: "30", sortable: false },
                { text: this.$t("attributes.shipment"), value: "shipment_id" },
                { text: this.$t("attributes.state"), value: "state" },
                { text: this.$t("attributes.receiver_phone"), value: "shipment.receiver_phone" },
                { text: this.$t("attributes.city"), value: "shipment.city.name" },
                { text: this.$t("attributes.price_with_shipping"), value: "price" },
                { text: this.$t("attributes.deliver_fee"), value: "shipment.deliver_fee" },
                { text: this.$t("attributes.note"), value: "note" },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ]
        };
    },
    created() {
        this.trip.shipments.map((e, index) => (e.index = index + 1));
    },
    computed: {
        ...mapState({
            trip: state => state.trip.trip
        })
    },

    methods: {
        /**
         * Validate form if valid then call save method to send request to server
         */
        validateAndSave() {
            this.$refs.shipmentsForm.validate().then(success => {
                if (!success) return;
                this.save();
            });
        },

        /**
         * Add data to server
         */
        save() {
            this.$store.state.app.loading = true;
            this.errors = {};

            // I took copy of object becuase I will change in properties of object and I do not want it effect in data that user insert them
            let shipment = Object.assign({}, this.shipment);
            //shipment.state = shipment.state && shipment.state.id;

            TripApi.updateShipment(shipment.trip_id, shipment.id, shipment)
                .then(r => {
                    this.formDialogClose();
                    Toast.success(this.$t("successes.operation_completed_successfully"));

                    let shipment_index = this.trip.shipments.findIndex(s => s.id == shipment.id);
                    this.trip.shipments[shipment_index].state = this.$store.getters["shipment/getStateById"](shipment.state);
                    this.trip.shipments[shipment_index].note = shipment.note;

                    if (r.data.data && r.data.data.deliver_fee_for_trip != undefined) {
                        this.trip.total_price_with_shipping_for_delivered = r.data.data.price_with_shipping_for_trip;
                        this.trip.fee = r.data.data.fee_for_trip;
                        this.trip.deliver_fee = r.data.data.deliver_fee_for_trip;
                    }
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Close form dialog which responsible on add/delete data
         */
        formDialogClose() {
            this.formDialog = false;
            this.errors = {};
            this.$refs.shipmentsForm.reset();
            this.shipment = {};
        },

        /**
         *  Set configuration for Add item
         */
        prepareToAddItem() {
            this.shipment = { active: false };
            this.formDialog = true;
        },

        /**
         *  Set configuration for update item
         */
        prepareToUpdateItem(item) {
            this.formDialog = true;
            this.shipment = Object.assign({}, item);
            this.shipment.state = this.shipment.state.id;
        }
    }
};
</script>
