<template>
    <v-combobox
        :items="data || []"
        item-text="name"
        :value="value"
        :label="label || $t('attributes.deliver')"
        @input="$emit('input', $event)"
        :clearable="clearable"
        :outlined="outlined"
        :error-messages="errorMessages"
    >
        <template slot="selection" slot-scope="data">
            <!-- HTML that describe how select should render items when the select is open -->
            {{ data.item.id }} - {{ data.item.name }}
        </template>
        <template slot="item" slot-scope="data">
            <!-- HTML that describe how select should render items when the select is open -->
            {{ data.item.id }} - {{ data.item.name }}
        </template>
    </v-combobox>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "DeliversField",

    props: {
        value: [String, Array, Object],
        label: { type: String },
        errorMessages: [String, Array],
        clearable: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false }
    },

    computed: {
        ...mapState({
            data: state => state.deliver.deliversShort
        })
    },
    created() {
        this.$store.dispatch("deliver/fetchDeliversShort");
    }
};
</script>
