<template>
    <v-row>
        <v-col cols="auto" class="pt-5">
            <h4>{{ index + 1 }}</h4>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
            <validation-provider rules="numeric" :name="$t('attributes.product')" v-slot="{ errors }">
                <v-select
                    :items="products"
                    item-value="id"
                    item-text="name"
                    :label="$t('attributes.product')"
                    :error-messages="errors"
                    :value="value.customer_product_id"
                    @input="updateValue('customer_product_id', $event)"
                    clearable
                    outlined
                    dense
                />
            </validation-provider>
        </v-col>

        <v-col cols="12" sm="6" lg="2">
            <validation-provider rules="required|numeric|min_value:1" :name="$t('attributes.count')" v-slot="{ errors }">
                <v-text-field
                    :label="$t('attributes.count')"
                    :value="value.count"
                    @input="updateValue('count', $event)"
                    :error-messages="errors"
                    type="number"
                    outlined
                    dense
                ></v-text-field>
            </validation-provider>
        </v-col>

        <v-col cols="12" sm="12" lg>
            <validation-provider
                :rules="`${value.customer_product_id ? '' : 'required|'}min:3|max:100`"
                :name="$t('attributes.desc')"
                v-slot="{ errors }"
            >
                <v-textarea
                    :label="$t('attributes.desc')"
                    :value="value.desc"
                    @input="updateValue('desc', $event)"
                    :error-messages="errors"
                    counter
                    :rows="1"
                    :maxlength="100"
                    auto-grow
                    clearable
                    outlined
                    dense
                ></v-textarea>
            </validation-provider>
        </v-col>
        <v-col cols="auto">
            <!-- btn add a new item  -->
            <v-btn fab small color="info" @click.stop="$emit('add-click', $event)"><v-icon>mdi-plus</v-icon></v-btn>
            <!-- btn delete an item  -->
            <v-btn fab small class="ms-1" color="error" @click.stop="$emit('delete-click', { index, id: value.id })">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "ShipmentItem",
    props: {
        value: { type: Object, required: true },
        index: { type: Number, required: true },
        products: { type: Array, required: true }
    },
    methods: {
        updateValue(key, value) {
            this.$emit("input", { ...this.value, [key]: value });
        }
    }
};
</script>
