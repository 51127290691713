import shipmentApi from "@/apis/shipmentApi";
import commentApi from "@/apis/shipmentCommentApi";
import i18n from "@/plugins/i18n.js";

function formatData(shipment, getters) {
    shipment.sender = shipment.customer || shipment.anonymous_sender;
    shipment.state = getters.getStateById(shipment.state);
    shipment.history && shipment.history.map(s => (s.state = getters.getStateById(s.state)));
    shipment.code = shipment.city && _getShipmentCode(shipment);
    return shipment;
}

export default {
    namespaced: true,

    state: {
        shipments: [],
        shipment: {},
        statuses: [
            { id: "missing", name: i18n.t("attributes.shipment_statuses.missing"), color: "red accent-4" },
            { id: "waiting_accept", name: i18n.t("attributes.shipment_statuses.waiting_accept"), color: "yellow accent-4" },
            { id: "accepted", name: i18n.t("attributes.shipment_statuses.accepted"), color: "blue-grey darken-3" },
            { id: "in_stock", name: i18n.t("attributes.shipment_statuses.in_stock"), color: "blue-grey darken-3" },
            { id: "prepare_to_deliver", name: i18n.t("attributes.shipment_statuses.prepare_to_deliver"), color: "blue-grey darken-3" },
            { id: "on_the_way", name: i18n.t("attributes.shipment_statuses.on_the_way"), color: "orange" },
            { id: "unable_to_deliver", name: i18n.t("attributes.shipment_statuses.unable_to_deliver"), color: "brown" },
            { id: "returned_to_stock", name: i18n.t("attributes.shipment_statuses.returned_to_stock"), color: "red" },
            { id: "returned_to_customer", name: i18n.t("attributes.shipment_statuses.returned_to_customer"), color: "deep-orange" },
            { id: "deliverd", name: i18n.t("attributes.shipment_statuses.deliverd"), color: "green" },
            { id: "completed", name: i18n.t("attributes.shipment_statuses.completed"), color: "info" },
            { id: "closed", name: i18n.t("attributes.shipment_statuses.closed"), color: "primary" }
        ],
        private_comments: [],
        public_comments: []
    },

    getters: {
        getById: state => id => {
            return state.shipments.data && state.shipments.data.find(item => item.id === id);
        },
        getStateById: state => id => state.statuses.find(item => item.id === id)
    },

    mutations: {
        ADD_SHIPMENT(state, shipment) {
            if (state.shipments.data) {
                state.shipments.data.unshift(shipment);
                state.shipments.meta.to++;
                state.shipments.meta.total++;
            }
        },
        UPDATE_SHIPMENT(state, shipment) {
            if (state.shipments.data) {
                state.shipments.data = [...state.shipments.data.map(c => (c.id !== shipment.id ? c : shipment))];
            }
        },
        SET_SHIPMENTS(state, shipments) {
            state.shipments = shipments;
        },
        SET_SHIPMENT(state, shipment) {
            state.shipment = shipment;
            state.private_comments = [];
            state.public_comments = [];
        },
        SET_PUBLIC_COMMENTS(state, comments) {
            state.public_comments = comments;
        },
        SET_PRIVATE_COMMENTS(state, comments) {
            state.private_comments = comments;
        },
        ADD_PUBLIC_COMMENTS(state, comment) {
            if (!state.public_comments) {
                state.public_comments = [];
            }
            state.public_comments.push(comment);
        },
        ADD_PRIVATE_COMMENTS(state, comment) {
            if (!state.private_comments) {
                state.private_comments = [];
            }
            state.private_comments.push(comment);
        },
        DELETE_PUBLIC_COMMENTS(state, id) {
            if (state.public_comments) {
                state.public_comments = state.public_comments.filter(c => c.id != id);
            }
        },
        DELETE_PRIVATE_COMMENTS(state, id) {
            if (state.private_comments) {
                state.private_comments = state.private_comments.filter(c => c.id != id);
            }
        },
        DELETE_SHIPMENT(state, id) {
            if (state.shipments.data) {
                state.shipments.data = state.shipments.data.filter(item => item.id !== id);
                state.shipments.meta.to--;
                state.shipments.meta.total--;
            }
        }
    },

    actions: {
        addShipment({ commit, getters }, shipment) {
            return shipmentApi.create(shipment).then(r => {
                Toast.success("Added successfully");
                commit("ADD_SHIPMENT", formatData(r.data.data, getters));
                return Promise.resolve(r);
            });
        },

        fetchShipments({ commit, getters }, { page = 1, params = {} }) {
            return shipmentApi.getAll(page, params).then(r => {
                r.data.data.map(s => formatData(s, getters));
                commit("SET_SHIPMENTS", r.data);
            });
        },

        fetchShipment({ commit, getters }, id) {
            return shipmentApi.getById(id).then(r => {
                commit("SET_SHIPMENT", formatData(r.data.data, getters));
            });
        },

        fetchPublicComments({ state, commit }, id) {
            return commentApi.getByShipmentId("public", id || state.shipment.id).then(r => {
                commit("SET_PUBLIC_COMMENTS", r.data.data);
            });
        },

        fetchPrivateComments({ state, commit }, id) {
            return commentApi.getByShipmentId("private", id || state.shipment.id).then(r => {
                commit("SET_PRIVATE_COMMENTS", r.data.data);
            });
        },

        createPublicComment({ state, commit }, id) {
            return commentApi.create("public", id || state.shipment.id).then(r => {
                commit("ADD_PUBLIC_COMMENTS", r.data.data);
            });
        },

        createPrivateComment({ state, commit }, id) {
            return commentApi.create("private", id || state.shipment.id).then(r => {
                commit("ADD_PRIVATE_COMMENTS", r.data.data);
            });
        },

        deletePublicComment({ commit }, { shipment_id, id }) {
            console.log({ shipment_id, id });
            return commentApi.delete("public", shipment_id, id).then(r => {
                commit("DELETE_PUBLIC_COMMENTS", id);
            });
        },

        deletePrivateComment({ commit }, { shipment_id, id }) {
            return commentApi.delete("private", shipment_id, id).then(r => {
                commit("DELETE_PRIVATE_COMMENTS", id);
            });
        },

        updateShipment({ commit, getters }, shipment) {
            return shipmentApi.update(shipment).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE_SHIPMENT", formatData(r.data.data, getters));
                return Promise.resolve(r);
            });
        },

        deleteShipment({ commit }, id) {
            return shipmentApi.delete(id).then(r => {
                Toast.success("Successfully deleted");
                commit("DELETE_SHIPMENT", id);
            });
        }
    }
};
