import Axios from "axios";

const base_url = "cp/type-expenses";

export default {
    /**
     * Display a listing of the type-expenses.
     *
     * @param {int} page number of page for pagination
     * @param {object} params filter (search) options
     * @returns {Promise}
     */
    getAll(page = 1, params = {}) {
        return Axios.get(base_url + "?page=" + page, { params });
    },

    /**
     * Get all type-expenses with specific properties of type-expenses.
     *
     * @returns {Promise}
     */
    getAllShort() {
        return Axios.get(`${base_url}/short`);
    },

    /**
     * Store a new City in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    create(data) {
        return Axios.post(base_url, data);
    },

    /**
     * Update the given city in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    update(data) {
        return Axios.put(base_url + "/" + data.id, data);
    }
};
