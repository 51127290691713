<template>
    <div>
        <breadcrumbs :items="breadcrumbs"></breadcrumbs>

        <base-card class="my-4 mx-auto" :icon="$t('adjustment_customers.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline card-main-title">{{ isUpdate ? adjustment.id : $t("options.add") }}</div>
                </div>

                <!-- Start Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>

                <validation-observer ref="adjustmentForm" v-slot="{ handleSubmit }">
                    <v-form class="px-3" @submit.prevent="handleSubmit(save)">
                        <!-- show errors if there is errors when add/update item -->
                        <has-errors :errors="errors"></has-errors>

                        <!-- Start Adjustment data -->
                        <v-row class="pt-5">
                            <!-- Start Customer data -->
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="numeric|min:1" :name="$t('attributes.customer')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        clearable
                                        v-model.trim="adjustment.customer_id"
                                        :label="$t('attributes.customer')"
                                        :error-messages="errors"
                                        counter
                                        type="number"
                                        append-icon="mdi-magnify"
                                        @click:append="bringCustomer"
                                        @input="customerChanged"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|min:3|max:32" :name="$t('attributes.sender_name')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        v-model.trim="adjustment.customer.name"
                                        readonly
                                        :label="$t('attributes.sender_name')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="32"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|phone" :name="$t('attributes.sender_phone')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        readonly
                                        v-model.trim="adjustment.customer.phone"
                                        :label="$t('attributes.sender_phone')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="10"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="phone" :name="$t('attributes.sender_phone2')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        readonly
                                        v-model.trim="adjustment.customer.phone2"
                                        :label="$t('attributes.sender_phone2')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="10"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <!-- End Customer data -->

                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|numeric|min:0" :name="$t('attributes.amount')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        v-model="adjustment.amount"
                                        :label="$t('attributes.amount')"
                                        :error-messages="errors"
                                        counter
                                        type="number"
                                        readonly
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|date|before_or_equal:today" :name="$t('attributes.date')" v-slot="{ errors }">
                                    <date-field
                                        outlined
                                        v-model="adjustment.date"
                                        :label="$t('attributes.date')"
                                        :error-messages="errors"
                                    ></date-field>
                                </validation-provider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider rules="max:200" :name="$t('attributes.note')" v-slot="{ errors }">
                                    <v-textarea
                                        v-model.trim="adjustment.note"
                                        :label="$t('attributes.note')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="200"
                                        rows="1"
                                        outlined
                                        clearable
                                        auto-grow
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider rules="max:200" :name="$t('attributes.extra')" v-slot="{ errors }">
                                    <v-textarea
                                        v-model.trim="adjustment.extra"
                                        :label="$t('attributes.extra')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="200"
                                        rows="1"
                                        outlined
                                        clearable
                                        auto-grow
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <!-- End Adjustment data -->

                        <v-divider></v-divider>

                        <div class="text-end px-5 py-3">
                            <v-btn color="info" min-width="150px" type="submit">{{ $t("options.save") }}</v-btn>
                        </div>
                    </v-form>
                </validation-observer>
                <!-- End Add form dialog -->
            </template>
        </base-card>

        <!-- Start selected shipment -->
        <v-card class="my-8">
            <v-card-title primary-title>{{ $t("attributes.selected_shipments") }} ({{ adjustment.shipments.length }})</v-card-title>

            <v-data-table :headers="headers" :items="adjustment.shipments" hide-default-footer :items-per-page="1000" class="elevation-1">
                <template v-slot:item.code="{ item }">
                    <v-btn text color="info" :to="`/shipments/${item.id}`">{{ item.code }}</v-btn>
                </template>
                <template v-slot:item.price_with_shipping="{ item }">
                    {{ item.price + (item.fee_on_customer ? 0 : item.fee) }}
                </template>
                <template v-slot:item.amount="{ item }">
                    {{ item.price - (item.fee_on_customer ? item.fee : 0) }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn x-small color="error" fab dark @click="removeShipment(item)">
                        <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>

            <v-card-actions>
                <v-btn min-width="100px" @click="unselectAll">{{ $t("options.unselect_all") }}</v-btn>
            </v-card-actions>
        </v-card>
        <!-- End selected shipment -->

        <!-- Start avaliable shipments -->
        <v-card>
            <v-card-title primary-title>{{ $t("attributes.available_shipments") }} ({{ new_shipments.length }})</v-card-title>

            <v-data-table :headers="headers" :items="new_shipments" hide-default-footer :items-per-page="1000" class="elevation-1">
                <template v-slot:item.code="{ item }">
                    <v-btn text color="info" :to="`/shipments/${item.id}`">{{ item.code }}</v-btn>
                </template>
                <template v-slot:item.price_with_shipping="{ item }">
                    {{ item.price + (item.fee_on_customer ? 0 : item.fee) }}
                </template>
                <template v-slot:item.amount="{ item }">
                    {{ item.price - (item.fee_on_customer ? item.fee : 0) }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn x-small color="info" fab @click="addShipment(item)">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
            </v-data-table>

            <v-card-actions>
                <v-btn min-width="100px" class="me-3" color="info" @click="selectAll">{{ $t("options.select_all") }}</v-btn>
            </v-card-actions>
        </v-card>
        <!-- End avaliable shipments -->
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";
import ShipmentAPI from "@/apis/shipmentApi";
import CustomerApi from "@/apis/customerApi";

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        let id = routeTo.params.id;
        if (id) {
            store.dispatch("adjustmentCustomer/fetchById", id).then(() => next());
        } else {
            next();
        }
    },
    data: function() {
        return {
            adjustment: {},
            new_shipments: [],
            errors: {},
            headers: [
                { text: this.$t("attributes.shipment"), value: "code" },
                { text: this.$t("attributes.city"), value: "city.name" },
                { text: this.$t("attributes.receiver_phone"), value: "receiver_phone" },
                { text: this.$t("attributes.price_with_shipping"), value: "price_with_shipping" },
                { text: this.$t("attributes.fee"), value: "fee" },
                { text: this.$t("attributes.amount"), value: "amount" },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ]
        };
    },
    computed: {
        ...mapState({
            data: state => state.adjustmentCustomer.adjustment
        }),

        breadcrumbs() {
            return [
                {
                    text: this.$t("adjustment_customers.title"),
                    to: "/adjustment-customers",
                    exact: true,
                    disabled: false
                },
                {
                    text: this.$t(`options.${this.isUpdate ? "update" : "add"}`),
                    exact: true,
                    disabled: true,
                    to: "/adjustment-customers/create"
                }
            ];
        },

        isUpdate() {
            return !!this.adjustment.id;
        },

        shipments_amount() {
            return this.adjustment.shipments && this.adjustment.shipments.reduce((sum, s) => sum + s.price - (s.fee_on_customer ? s.fee : 0), 0);
        }
    },

    created() {
        this.adjustment = (this.$route.params.id && this.data) || { shipments: [], customer: {}, date: _getCurrentDate() };
        if (this.adjustment.id) {
            this.adjustment.original_shipments = this.adjustment.shipments; // to use this in get original shipments of adjustment in update operation,
            //this.adjustment.shipments = [];
        }
    },

    watch: {
        shipments_amount(val) {
            this.adjustment.amount = val;
            this.adjustment = Object.assign({}, this.adjustment);
        }
    },

    methods: {
        ...mapActions("adjustmentCustomer", ["add", "update"]),

        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        },

        customerChanged() {
            this.adjustment.customer = {};
            this.new_shipments = [];
            this.adjustment.shipments = [];
        },

        bringCustomer() {
            if (!this.adjustment.customer_id) return;

            this.$store.state.app.loading = true;

            CustomerApi.getById(this.adjustment.customer_id)
                .then(r => {
                    this.adjustment.customer = r.data.data;
                    this.bringShipments();
                })
                .catch(() => (this.adjustment.customer = {}))
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Bring shipments from server depends on customer
         */
        bringShipments() {
            ShipmentAPI.getShipmentByCustomer(this.adjustment.customer_id, this.adjustment.id)
                .then(r => {
                    this.adjustment.shipments = [];
                    this.new_shipments = [];
                    if (this.isUpdate) {
                        let original_shipments = this.adjustment.original_shipments.map(s => s.id);
                        r.data.data.forEach(s => {
                            original_shipments.includes(s.id) ? this.adjustment.shipments.push(s) : this.new_shipments.push(s);
                        });
                    } else {
                        this.new_shipments = r.data.data;
                    }
                })
                .catch(e => {
                    window.eee = e;
                    this.adjustment.shipments = [];
                    this.new_shipments = [];
                });
        },

        /**
         * Add shipment
         */
        addShipment(item) {
            this.adjustment.shipments.push(item);
            this.new_shipments = this.new_shipments.filter(s => s.id != item.id);
        },

        /**
         * Remove shipment
         */
        removeShipment(item) {
            this.new_shipments.push(item);
            this.adjustment.shipments = this.adjustment.shipments.filter(s => s.id != item.id);
        },

        /**
         * Add data to server
         */
        save() {
            this.errors = {};
            this.$store.state.app.loading = true;
            let adjustment = Object.assign({}, this.adjustment);
            adjustment.shipments = adjustment.shipments.map(s => s.id);

            (adjustment.id ? this.update(adjustment) : this.add(adjustment))
                .then(r => {
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                    this.$router.push({ path: `/adjustment-customers/${r.data.data.id}` });
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Select all shipments
         */
        selectAll() {
            this.adjustment.shipments = [...this.adjustment.shipments, ...this.new_shipments];
            this.new_shipments = [];
        },

        /**
         * Select all shipments
         */
        unselectAll() {
            this.new_shipments = [...this.new_shipments, ...this.adjustment.shipments];
            this.adjustment.shipments = [];
        }
    }
};
</script>
