var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('base-card',{staticClass:"my-4 mx-auto",attrs:{"icon":_vm.$t('trips.icon')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"headline card-main-title"},[_vm._v(_vm._s(_vm.isUpdate ? _vm.trip.id : _vm.$t("options.add")))])])]},proxy:true},{key:"content",fn:function(){return [_c('v-divider'),_c('validation-observer',{ref:"tripsForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('has-errors',{attrs:{"errors":_vm.errors}}),_c('v-row',{staticClass:"pt-5"},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|select_object","name":_vm.$t('attributes.deliver')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('delivers-field',{attrs:{"outlined":"","error-messages":errors},model:{value:(_vm.trip.deliver),callback:function ($$v) {_vm.$set(_vm.trip, "deliver", $$v)},expression:"trip.deliver"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|select_object","name":_vm.$t('attributes.branch')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('branches-field',{attrs:{"outlined":"","error-messages":errors,"append-outer-icon":"mdi-magnify"},on:{"input":_vm.shippingRoutesChanged},model:{value:(_vm.trip.branch),callback:function ($$v) {_vm.$set(_vm.trip, "branch", $$v)},expression:"trip.branch"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|select_object","name":_vm.$t('attributes.shipping_route')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('shipping-routes-field',{attrs:{"outlined":"","error-messages":errors,"append-outer-icon":"mdi-magnify"},on:{"click:append-outer":_vm.bringShipments,"input":_vm.shippingRoutesChanged},model:{value:(_vm.trip.shipping_route),callback:function ($$v) {_vm.$set(_vm.trip, "shipping_route", $$v)},expression:"trip.shipping_route"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.state')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trip-statuses-field',{attrs:{"outlined":"","error-messages":errors,"exclude-options":!_vm.isUpdate && ['closed']},model:{value:(_vm.trip.state),callback:function ($$v) {_vm.$set(_vm.trip, "state", $$v)},expression:"trip.state"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|numeric|min:0","name":_vm.$t('attributes.amount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.amount'),"error-messages":errors,"counter":"","type":"number"},model:{value:(_vm.trip.amount),callback:function ($$v) {_vm.$set(_vm.trip, "amount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"trip.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|numeric|min:0","name":_vm.$t('attributes.deliver_fee')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.deliver_fee'),"error-messages":errors,"counter":"","readonly":"","type":"number"},model:{value:(_vm.trip.deliver_fee),callback:function ($$v) {_vm.$set(_vm.trip, "deliver_fee", $$v)},expression:"trip.deliver_fee"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|date|before_or_equal:today","name":_vm.$t('attributes.start_date'),"vid":"start_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"outlined":"","label":_vm.$t('attributes.start_date'),"error-messages":errors},model:{value:(_vm.trip.start_date),callback:function ($$v) {_vm.$set(_vm.trip, "start_date", $$v)},expression:"trip.start_date"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|date|after_or_equal:@start_date","name":_vm.$t('attributes.end_date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"outlined":"","label":_vm.$t('attributes.end_date'),"error-messages":errors},model:{value:(_vm.trip.end_date),callback:function ($$v) {_vm.$set(_vm.trip, "end_date", $$v)},expression:"trip.end_date"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"max:200","name":_vm.$t('attributes.note')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('attributes.note'),"error-messages":errors,"counter":"","maxlength":200,"rows":"1","outlined":"","clearable":"","auto-grow":""},model:{value:(_vm.trip.note),callback:function ($$v) {_vm.$set(_vm.trip, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"trip.note"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"max:200","name":_vm.$t('attributes.extra')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('attributes.extra'),"error-messages":errors,"counter":"","maxlength":200,"rows":"1","outlined":"","clearable":"","auto-grow":""},model:{value:(_vm.trip.extra),callback:function ($$v) {_vm.$set(_vm.trip, "extra", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"trip.extra"}})]}}],null,true)})],1)],1),_c('v-divider'),_c('div',{staticClass:"text-end px-5 py-3"},[_c('v-btn',{attrs:{"color":"info","min-width":"150px","type":"submit"}},[_vm._v(_vm._s(_vm.$t("options.save")))])],1)],1)]}}])})]},proxy:true}])}),(_vm.canUpdateShipments)?[_c('v-card',{staticClass:"my-8"},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(_vm._s(_vm.$t("attributes.selected_shipments"))+" ("+_vm._s(_vm.trip.shipments.length)+")")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.trip.shipments,"hide-default-footer":"","items-per-page":1000},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"info","to":("/shipments/" + (item.id))}},[_vm._v(_vm._s(item.code))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price + (item.fee_on_customer ? 0 : item.fee))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","color":"error","fab":"","dark":""},on:{"click":function($event){return _vm.removeShipment(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}],null,false,14789138)}),_c('v-card-actions',[_c('v-btn',{attrs:{"min-width":"100px"},on:{"click":_vm.unselectAll}},[_vm._v(_vm._s(_vm.$t("options.unselect_all")))])],1)],1),_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(_vm._s(_vm.$t("attributes.available_shipments"))+" ("+_vm._s(_vm.new_shipments.length)+")")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.new_shipments,"hide-default-footer":"","items-per-page":1000},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"info","to":("/shipments/" + (item.id))}},[_vm._v(_vm._s(item.code))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price + (item.fee_on_customer ? 0 : item.fee))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","color":"info","fab":""},on:{"click":function($event){return _vm.addShipment(item)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,1603690202)}),_c('v-card-actions',[_c('v-btn',{staticClass:"me-3",attrs:{"min-width":"100px","color":"info"},on:{"click":_vm.selectAll}},[_vm._v(_vm._s(_vm.$t("options.select_all")))])],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }