import Axios from "axios";

const base_url = "cp/adjustment-customers";

export default {
    /**
     * Display a listing of the Adjustment Customers.
     *
     * @param {int} page number of page for pagination
     * @param {object} params filter (search) options
     * @returns {Promise}
     */
    getAll(page = 1, params = {}) {
        return Axios.get(`${base_url}?page=${page}`, { params });
    },

    /**
     * Display a listing of available balnace that need to close.
     *
     * @param {int} page number of page for pagination
     * @param {object} params filter (search) options
     * @returns {Promise}
     */
    getAvailableBalance(page = 1, params = {}) {
        return Axios.get(`${base_url}/available?page=${page}`, { params });
    },

    /**
     * Store a new Adjustment Customers in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    create(data) {
        return Axios.post(base_url, data);
    },

    /**
     * Show data for a given Adjustment.
     *
     * @param {int} id
     * @returns {Promise}
     */
    getById(id) {
        return Axios.get(`${base_url}/${id}`);
    },

    /**
     * Update the given Adjustment Customers in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    update(data) {
        return Axios.put(`${base_url}/${data.id}`, data);
    }

    /**
     * Remove the specified Adjustment Customers from server.
     *
     * @param {int} id
     * @returns {Promise}
     */
    //delete(id) {
    //    return Axios.delete(`${base_url}/${id}`);
    //}
};
