var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-card',{staticClass:"my-4 mx-auto",attrs:{"icon":_vm.$t('customers.icon')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("customers.title")))]),_c('v-btn',{attrs:{"fab":"","small":"","color":_vm.$store.state.app.color},on:{"click":function($event){$event.stopPropagation();return _vm.prepareToAddItem($event)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('dialog-base',{attrs:{"type":(_vm.isUpdate && 'UPDATE') || 'ADD'},on:{"canceled":_vm.formDialogClose,"confirmed":_vm.validateAndSave},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('validation-observer',{ref:"customersForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateAndSave)}}},[_c('has-errors',{attrs:{"errors":_vm.errors}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:3|max:32","name":_vm.$t('attributes.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.name'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(_vm.customer.name),callback:function ($$v) {_vm.$set(_vm.customer, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customer.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:3|max:32","name":_vm.$t('attributes.owner_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.owner_name'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(_vm.customer.owner_name),callback:function ($$v) {_vm.$set(_vm.customer, "owner_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customer.owner_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|phone","name":_vm.$t('attributes.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.phone'),"counter":"","maxlength":10,"error-messages":errors},model:{value:(_vm.customer.phone),callback:function ($$v) {_vm.$set(_vm.customer, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customer.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"phone","name":_vm.$t('attributes.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.phone'),"counter":"","maxlength":10,"error-messages":errors},model:{value:(_vm.customer.phone2),callback:function ($$v) {_vm.$set(_vm.customer, "phone2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customer.phone2"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":_vm.$t('attributes.email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.email'),"counter":"","type":"email","error-messages":errors},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customer.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"url","name":_vm.$t('attributes.website')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.website'),"counter":"","type":"url","error-messages":errors,"maxlength":"120","clearable":""},model:{value:(_vm.customer.website),callback:function ($$v) {_vm.$set(_vm.customer, "website", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customer.website"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":((_vm.isUpdate ? '' : 'required|') + "min:6|max:32"),"name":_vm.$t('attributes.password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('password-field',{attrs:{"label":_vm.$t('attributes.password'),"error-messages":errors,"counter":""},model:{value:(_vm.customer.password),callback:function ($$v) {_vm.$set(_vm.customer, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customer.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"false-value":"0","true-value":"1","label":_vm.$t('attributes.active')},model:{value:(_vm.customer.active),callback:function ($$v) {_vm.$set(_vm.customer, "active", $$v)},expression:"customer.active"}})],1),(!_vm.isUpdate)?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.file')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"label":_vm.$t('attributes.file'),"show-size":"","counter":"","error-messages":errors,"accept":"image/*, application/pdf"},model:{value:(_vm.customer.file),callback:function ($$v) {_vm.$set(_vm.customer, "file", $$v)},expression:"customer.file"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:6|max:100","name":_vm.$t('attributes.address')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('attributes.address'),"error-messages":errors,"counter":"","maxlength":100,"rows":"1","auto-grow":""},model:{value:(_vm.customer.address),callback:function ($$v) {_vm.$set(_vm.customer, "address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customer.address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"max:100","name":_vm.$t('attributes.note')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('attributes.note'),"error-messages":errors,"counter":"","maxlength":100,"rows":"1","auto-grow":""},model:{value:(_vm.customer.note),callback:function ($$v) {_vm.$set(_vm.customer, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"customer.note"}})]}}],null,true)})],1)],1),_c('button',{staticClass:"hide",attrs:{"type":"submit"}})],1)]}}])})]},proxy:true}]),model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}})]},proxy:true},{key:"content",fn:function(){return [_c('v-divider'),_c('validation-observer',{ref:"searchForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.filterData)}}},[_c('v-row',{attrs:{"align":"center"}},[_vm._l((_vm.searchFields),function(field,index){return _c('v-col',{key:index,staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t(("attributes." + field)),"maxlength":"32","clearable":""},model:{value:(_vm.search[field]),callback:function ($$v) {_vm.$set(_vm.search, field, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search[field]"}})],1)}),_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6","lg":"auto"}},[_c('v-btn',{staticClass:"me-2",attrs:{"color":_vm.$store.state.app.color,"type":"submit"}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],2)],1)]}}])}),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data.data,"items-per-page":100,"hide-default-footer":"","item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"80px"}},[_c('v-btn',{staticClass:"me-1",attrs:{"x-small":"","color":"info","fab":"","to":("/customers/" + (item.id))}},[_c('v-icon',[_vm._v("mdi-information-variant")])],1),_c('v-btn',{attrs:{"x-small":"","fab":"","color":"info","dark":""},on:{"click":function($event){return _vm.prepareToUpdateItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}])}),_c('pagination',{attrs:{"data":_vm.data.meta || {}},on:{"page-changed":_vm.filterData}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }