import adjustmentApi from "@/apis/adjustmentCustomerApi";

export default {
    namespaced: true,

    state: {
        adjustments: [],
        adjustment: {}
    },

    mutations: {
        ADD(state, adjustment) {
            if (state.adjustments.data) {
                state.adjustments.data.unshift(adjustment);
                state.adjustments.meta.to++;
                state.adjustments.meta.total++;
            }
        },
        UPDATE(state, adjustment) {
            if (state.adjustments.data) {
                state.adjustments.data = state.adjustments.data.map(c => (c.id !== adjustment.id ? c : adjustment));
            }
        },
        SET_ITEMS(state, adjustments) {
            state.adjustments = adjustments;
        },
        SET_ITEM(state, adjustment) {
            state.adjustment = adjustment;
        }
    },

    actions: {
        add({ commit }, adjustment) {
            return adjustmentApi.create(adjustment).then(r => {
                Toast.success("Added successfully");
                commit("ADD", r.data.data);
                return Promise.resolve(r);
            });
        },

        fetchAll({ commit }, { page = 1, params = {} }) {
            return adjustmentApi.getAll(page, params).then(r => {
                commit("SET_ITEMS", r.data);
            });
        },

        fetchAvailableBalance({ commit }, { page = 1, params = {} }) {
            return adjustmentApi.getAvailableBalance(page, params).then(r => {
                commit("SET_ITEMS", r.data);
            });
        },

        fetchById({ commit }, id) {
            return adjustmentApi.getById(id).then(r => {
                commit("SET_ITEM", r.data.data);
                return Promise.resolve(r);
            });
        },

        update({ commit }, adjustment) {
            return adjustmentApi.update(adjustment).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE", r.data.data);
                return Promise.resolve(r);
            });
        }
    }
};
