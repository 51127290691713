import tripApi from "@/apis/tripApi";
import i18n from "@/plugins/i18n.js";

function formatData(trip, getters, rootGetters) {
    trip.state = getters.getStateById(trip.state);
    trip.shipments && trip.shipments.map(s => (s.state = rootGetters["shipment/getStateById"](s.state)));
    return trip;
}

export default {
    namespaced: true,

    state: {
        trips: [],
        trip: {},
        statuses: [
            //{ id: "prepare_to_deliver", name: i18n.t("attributes.shipment_statuses.prepare_to_deliver"), color: "blue-grey darken-3" },
            { id: "on_the_way", name: i18n.t("attributes.shipment_statuses.on_the_way"), color: "orange" },
            { id: "missing", name: i18n.t("attributes.shipment_statuses.missing"), color: "red accent-4" },
            //{ id: "returned_to_stock", name: i18n.t("attributes.shipment_statuses.returned_to_stock"), color: "red" },
            //{ id: "returned_to_customer", name: i18n.t("attributes.shipment_statuses.returned_to_customer"), color: "red" },
            { id: "deliverd", name: i18n.t("attributes.shipment_statuses.deliverd"), color: "green" },
            { id: "closed", name: i18n.t("attributes.shipment_statuses.closed"), color: "primary" }
        ]
    },

    getters: {
        getStateById: state => id => state.statuses.find(item => item.id === id)
    },

    mutations: {
        ADD_TRIP(state, trip) {
            if (state.trips.data) {
                state.trips.data.unshift(trip);
                state.trips.meta.to++;
                state.trips.meta.total++;
            }
        },
        UPDATE_TRIP(state, trip) {
            if (state.trips.data) {
                state.trips.data = [...state.trips.data.map(c => (c.id !== trip.id ? c : trip))];
            }
        },
        SET_TRIPS(state, trips) {
            state.trips = trips;
        },
        SET_TRIP(state, trip) {
            state.trip = trip;
        },
        DELETE_TRIP(state, id) {
            if (state.trips.data) {
                state.trips.data = state.trips.data.filter(item => item.id !== id);
                state.trips.meta.to--;
                state.trips.meta.total--;
            }
        }
    },

    actions: {
        addTrip({ commit, getters, rootGetters }, trip) {
            return tripApi.create(trip).then(r => {
                Toast.success("Added successfully");
                commit("ADD_TRIP", formatData(r.data.data, getters, rootGetters));
                return Promise.resolve(r);
            });
        },

        fetchTrips({ commit, getters, rootGetters }, { page = 1, params = {} }) {
            return tripApi.getAll(page, params).then(r => {
                r.data.data.map(s => formatData(s, getters, rootGetters));
                commit("SET_TRIPS", r.data);
            });
        },

        fetchTrip({ commit, getters, rootGetters }, id) {
            return tripApi.getById(id).then(r => {
                commit("SET_TRIP", formatData(r.data.data, getters, rootGetters));
                return Promise.resolve(r);
            });
        },

        updateTrip({ commit, getters, rootGetters }, trip) {
            return tripApi.update(trip).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE_TRIP", formatData(r.data.data, getters, rootGetters));
                return Promise.resolve(r);
            });
        }

        /* deleteTrip({ commit }, id) {
            return tripApi.delete(id).then(r => {
                Toast.success("Successfully deleted");
                commit("DELETE_TRIP", id);
            });
        } */
    }
};
