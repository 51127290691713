<template>
    <body class="document">
        <div class="page">
            <h1 class="logo"><img src="@/assets/images/logo.png" /></h1>
            <div style="padding-top:30px">
                <router-view></router-view>
            </div>
        </div>
    </body>
</template>

<script>
export default {
    name: "PaperLayout"
};
</script>

<style src="@/assets/css/sheets-of-paper.css" scoped></style>
<style src="@/assets/css/sheets-of-paper-a4.css" scoped></style>

<style lang="scss" scoped>
body {
    direction: rtl;
    .page {
        padding: 1.5cm;
    }
    .logo {
        text-align: center;
        border-bottom: 1.75px solid #444;
        img {
            margin: auto;
            max-height: 80px;
        }
    }
}
</style>
