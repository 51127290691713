<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('users.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("users.title") }}</div>

                    <!-- btn add new item  -->
                    <v-btn fab small :color="$store.state.app.color" @click.stop="prepareToAddItem"><v-icon>mdi-plus</v-icon></v-btn>
                </div>

                <!-- Start Add form dialog -->
                <dialog-base :type="(isUpdate && 'UPDATE') || 'ADD'" v-model="formDialog" @canceled="formDialogClose" @confirmed="validateAndSave">
                    <template #content>
                        <validation-observer ref="usersForm" v-slot="{ handleSubmit }">
                            <v-form class="px-3" @submit.prevent="handleSubmit(validateAndSave)">
                                <!-- show errors if there is errors when add/update item -->
                                <has-errors :errors="errors"></has-errors>

                                <v-row>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|min:3|max:32" :name="$t('attributes.name')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="user.name"
                                                :label="$t('attributes.name')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="32"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider
                                            rules="required|username|min:3|max:14"
                                            :name="$t('attributes.username')"
                                            v-slot="{ errors }"
                                        >
                                            <v-text-field
                                                v-model.trim="user.username"
                                                :label="$t('attributes.username')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="14"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <validation-provider
                                            :rules="`${isUpdate ? '' : 'required|'}min:6|max:32`"
                                            :name="$t('attributes.password')"
                                            v-slot="{ errors }"
                                        >
                                            <password-field
                                                v-model.trim="user.password"
                                                :label="$t('attributes.password')"
                                                :error-messages="errors"
                                                counter
                                            >
                                            </password-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-switch v-model="user.active" :label="$t('attributes.active')"></v-switch>
                                    </v-col>

                                    <v-col cols="12">
                                        <validation-provider rules="max:100" :name="$t('attributes.note')" v-slot="{ errors }">
                                            <v-textarea
                                                v-model.trim="user.note"
                                                :label="$t('attributes.note')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="100"
                                                rows="1"
                                                auto-grow
                                            ></v-textarea>
                                        </validation-provider>
                                    </v-col>
                                </v-row>

                                <!-- This button to allow to user to submit form by clicking enter -->
                                <button type="submit" class="hide"></button>
                            </v-form>
                        </validation-observer>
                    </template>
                </dialog-base>
                <!-- End Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>

                <!-- Start search options -->
                <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                    <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                        <v-row align="center">
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="search.name" :label="$t('attributes.name')" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="search.username" :label="$t('attributes.username')" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                <v-btn class="me-2" :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </validation-observer>
                <!-- End search options -->

                <v-divider></v-divider>

                <v-data-table
                    :headers="headers"
                    :items="data.data"
                    :items-per-page="100"
                    hide-default-footer
                    class="elevation-1"
                    :item-class="rowClass"
                >
                    <template v-slot:item.actions="{ item }">
                        <div style="width: 40px">
                            <v-btn x-small fab color="info" dark @click="prepareToUpdateItem(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>

                <!-- Start pagination -->
                <pagination :data="data.meta || {}" @page-changed="filterData" />
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;

    store.dispatch("user/fetchUsers", { page, params }).then(() => {
        next();
    });
}

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    data: function() {
        return {
            formDialog: false,
            user: {},
            errors: {},
            search: Object.assign({}, this.$route.query),

            headers: [
                { text: this.$t("attributes.name"), value: "name" },
                { text: this.$t("attributes.username"), value: "username" },
                { text: this.$t("attributes.note"), value: "note" },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions" }
            ]
        };
    },

    computed: {
        ...mapState({
            data: state => state.user.users
        }),
        isUpdate() {
            return !!this.user.id;
        }
    },

    methods: {
        ...mapActions("user", ["addUser", "updateUser"]),

        /**
         * Validate form if valid then call save method to send request to server
         */
        validateAndSave() {
            this.$refs.usersForm.validate().then(success => {
                if (!success) return;
                this.save();
            });
        },

        /**
         * Add data to server
         */
        save() {
            this.$store.state.app.loading = true;
            this.errors = {};

            // I took copy of object becuase I will change in properties of object and I do not want it effect in data that user insert them
            let user = Object.assign({}, this.user);

            (this.isUpdate ? this.updateUser(user) : this.addUser(user))
                .then(() => {
                    this.formDialogClose();
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Close form dialog which responsible on add/delete data
         */
        formDialogClose() {
            this.formDialog = false;
            this.errors = {};
            this.$refs.usersForm.reset();
            this.user = {};
        },

        /**
         *  Set configuration for Add item
         */
        prepareToAddItem() {
            this.user = { active: false };
            this.formDialog = true;
        },

        /**
         *  Set configuration for update item
         */
        prepareToUpdateItem(item) {
            this.formDialog = true;
            this.user = Object.assign({}, item);
        },

        rowClass(item) {
            return !item.active && "red white--text";
        },

        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        }
    }
};
</script>
