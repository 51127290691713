import routeApi from "@/apis/shippingRouteApi";

/**
 * Add short route object
 * This function it returns only properties that shortCitites needs them
 * @returns {object} route
 */
function shortRoute(route) {
    return {
        id: route.id,
        name: route.name,
        note: route.note
    };
}

export default {
    namespaced: true,

    state: {
        routes: [],
        routesShort: []
    },

    getters: {
        getById: state => id => {
            return state.routesShort.length && state.routesShort.find(item => item.id === id);
        }
    },

    mutations: {
        ADD_ROUTE(state, route) {
            if (state.routes.data) {
                state.routes.data.unshift(route);
                state.routes.meta.to++;
                state.routes.meta.total++;
            }
            state.routesShort.push(shortRoute(route));
        },
        UPDATE_ROUTE(state, route) {
            state.routes.data = [...state.routes.data.map(item => (item.id !== route.id ? item : route))];
            let index = state.routesShort.findIndex(e => e.id === route.id);
            state.routesShort[index] = shortRoute(route);
        },
        SET_ROUTES(state, routes) {
            state.routes = routes;
        },
        SET_ROUTES_SHORT(state, routes) {
            state.routesShort = routes;
        }
    },

    actions: {
        addRoute({ commit }, route) {
            return routeApi.create(route).then(r => {
                Toast.success("Added successfully");
                commit("ADD_ROUTE", r.data.data);
            });
        },

        fetchRoutes({ commit }, { page = 1, params = {} }) {
            return routeApi.getAll(page, params).then(r => {
                commit("SET_ROUTES", r.data);
            });
        },

        fetchRoutesShort({ commit, state }) {
            if (!state.routesShort.length) {
                return routeApi.getAllShort().then(r => {
                    commit("SET_ROUTES_SHORT", r.data.data);
                });
            }
        },

        updateRoute({ commit }, route) {
            return routeApi.update(route).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE_ROUTE", r.data.data);
            });
        }
    }
};
