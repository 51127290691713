import Axios from "axios";

export default {
    login(credentials) {
        return Axios.post("cp/login", credentials);
    },

    profile() {
        return Axios.get("cp/profile");
    },

    logout() {
        return Axios.post("cp/logout");
    }
};
