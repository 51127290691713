var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-card',{staticClass:"my-4 mx-auto",attrs:{"icon":_vm.$t('revenues.icon')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("revenues.title")))]),_c('v-btn',{attrs:{"fab":"","small":"","color":_vm.$store.state.app.color},on:{"click":function($event){$event.stopPropagation();return _vm.prepareToAddItem($event)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('dialog-base',{attrs:{"type":(_vm.revenue.id && 'UPDATE') || 'ADD'},on:{"canceled":_vm.formDialogClose,"confirmed":_vm.validateAndSave},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('validation-observer',{ref:"revenuesForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateAndSave)}}},[_c('has-errors',{attrs:{"errors":_vm.errors}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|select_object","name":_vm.$t('attributes.type_of_revenue')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('type-revenues-field',{attrs:{"error-messages":errors},model:{value:(_vm.revenue.type),callback:function ($$v) {_vm.$set(_vm.revenue, "type", $$v)},expression:"revenue.type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|numeric","name":_vm.$t('attributes.amount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.amount'),"counter":"","type":"number","error-messages":errors},model:{value:(_vm.revenue.amount),callback:function ($$v) {_vm.$set(_vm.revenue, "amount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"revenue.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|date|before_or_equal:today","name":_vm.$t('attributes.date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"label":_vm.$t('attributes.date'),"error-messages":errors},model:{value:(_vm.revenue.date),callback:function ($$v) {_vm.$set(_vm.revenue, "date", $$v)},expression:"revenue.date"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"max:100","name":_vm.$t('attributes.extra')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('attributes.extra'),"error-messages":errors,"counter":"","maxlength":100,"rows":"1","auto-grow":""},model:{value:(_vm.revenue.extra),callback:function ($$v) {_vm.$set(_vm.revenue, "extra", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"revenue.extra"}})]}}],null,true)})],1)],1),_c('button',{staticClass:"hide",attrs:{"type":"submit"}})],1)]}}])})]},proxy:true}]),model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}})]},proxy:true},{key:"content",fn:function(){return [_c('v-divider'),_c('div',{staticClass:"px-4"},[_c('v-select',{attrs:{"items":_vm.headers,"label":_vm.$t('options.show_columns'),"multiple":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"filter":""}},[_c('span',[_vm._v(_vm._s(item.text))])])]}}]),model:{value:(_vm.selected_headers),callback:function ($$v) {_vm.selected_headers=$$v},expression:"selected_headers"}})],1),_c('v-divider'),_c('v-expansion-panels',{attrs:{"focusable":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"justify-start"},[_c('h5',{staticClass:"subtitle-1"},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" "+_vm._s(_vm.$t("options.search")))],1)]),_c('v-expansion-panel-content',[_c('validation-observer',{ref:"searchForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.filterData)}}},[_c('v-row',{attrs:{"align":"center"}},[_vm._l((_vm.searchFields),function(field,index){return _c('v-col',{key:index,staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t(("attributes." + field)),"maxlength":"32","clearable":""},model:{value:(_vm.search[field]),callback:function ($$v) {_vm.$set(_vm.search, field, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search[field]"}})],1)}),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"select_object","name":_vm.$t('attributes.type_of_revenue')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('type-revenues-field',{attrs:{"clearable":"","error-messages":errors},model:{value:(_vm.search.type),callback:function ($$v) {_vm.$set(_vm.search, "type", $$v)},expression:"search.type"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"date|before_or_equal:today","vid":"date_from","name":_vm.$t('search.date_from')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"label":_vm.$t('search.date_from'),"error-messages":errors,"clearable":""},model:{value:(_vm.search.date_from),callback:function ($$v) {_vm.$set(_vm.search, "date_from", $$v)},expression:"search.date_from"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"date|before_or_equal:today|after_or_equal:@date_from","vid":"date_to","name":_vm.$t('search.date_to')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"label":_vm.$t('search.date_to'),"error-messages":errors,"clearable":""},model:{value:(_vm.search.date_to),callback:function ($$v) {_vm.$set(_vm.search, "date_to", $$v)},expression:"search.date_to"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"date|before_or_equal:today","vid":"created_at_from","name":_vm.$t('search.created_at_from')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"label":_vm.$t('search.created_at_from'),"error-messages":errors,"clearable":""},model:{value:(_vm.search.created_at_from),callback:function ($$v) {_vm.$set(_vm.search, "created_at_from", $$v)},expression:"search.created_at_from"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"date|before_or_equal:today|after_or_equal:@created_at_from","vid":"created_at_to","name":_vm.$t('search.created_at_to')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"label":_vm.$t('search.created_at_to'),"error-messages":errors,"clearable":""},model:{value:(_vm.search.created_at_to),callback:function ($$v) {_vm.$set(_vm.search, "created_at_to", $$v)},expression:"search.created_at_to"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6","lg":"auto"}},[_c('v-btn',{attrs:{"color":_vm.$store.state.app.color,"type":"submit"}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],2)],1)]}}])})],1)],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.selected_headers,"items":_vm.data.data,"items-per-page":100,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","fab":"","color":"info","dark":""},on:{"click":function($event){return _vm.prepareToUpdateItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])}),_c('pagination',{attrs:{"data":_vm.data.meta || {}},on:{"page-changed":_vm.filterData}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }