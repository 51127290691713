var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-card',{staticClass:"my-4 mx-auto",attrs:{"icon":_vm.$t('branches.icon')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("branches.title")))]),_c('v-btn',{attrs:{"fab":"","small":"","color":_vm.$store.state.app.color},on:{"click":function($event){$event.stopPropagation();return _vm.prepareToAddItem($event)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('dialog-base',{attrs:{"type":(_vm.isUpdate && 'UPDATE') || 'ADD'},on:{"canceled":_vm.formDialogClose,"confirmed":_vm.validateAndSave},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('validation-observer',{ref:"branchesForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateAndSave)}}},[_c('has-errors',{attrs:{"errors":_vm.errors}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:3|max:32","name":_vm.$t('attributes.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.name'),"counter":"","maxlength":32,"error-messages":errors},model:{value:(_vm.branch.name),callback:function ($$v) {_vm.$set(_vm.branch, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"branch.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|select_object","name":_vm.$t('attributes.city')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cities-field',{attrs:{"error-messages":errors},model:{value:(_vm.branch.city),callback:function ($$v) {_vm.$set(_vm.branch, "city", $$v)},expression:"branch.city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|phone","name":_vm.$t('attributes.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.phone'),"counter":"","maxlength":10,"error-messages":errors},model:{value:(_vm.branch.phone),callback:function ($$v) {_vm.$set(_vm.branch, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"branch.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"phone","name":_vm.$t('attributes.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.phone'),"counter":"","maxlength":10,"error-messages":errors},model:{value:(_vm.branch.phone2),callback:function ($$v) {_vm.$set(_vm.branch, "phone2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"branch.phone2"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":_vm.$t('attributes.email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.email'),"counter":"","type":"email","error-messages":errors},model:{value:(_vm.branch.email),callback:function ($$v) {_vm.$set(_vm.branch, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"branch.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|min:9|max:64","name":_vm.$t('attributes.address')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.address'),"counter":"","maxlength":64,"error-messages":errors},model:{value:(_vm.branch.address),callback:function ($$v) {_vm.$set(_vm.branch, "address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"branch.address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('attributes.active')},model:{value:(_vm.branch.active),callback:function ($$v) {_vm.$set(_vm.branch, "active", $$v)},expression:"branch.active"}})],1)],1),_c('button',{staticClass:"hide",attrs:{"type":"submit"}})],1)]}}])})]},proxy:true}]),model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}})]},proxy:true},{key:"content",fn:function(){return [_c('v-divider'),_c('div',{staticClass:"px-4"},[_c('v-select',{attrs:{"items":_vm.headers,"label":_vm.$t('options.show_columns'),"multiple":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"filter":""}},[_c('span',[_vm._v(_vm._s(item.text))])])]}}]),model:{value:(_vm.selected_headers),callback:function ($$v) {_vm.selected_headers=$$v},expression:"selected_headers"}})],1),_c('v-divider'),_c('validation-observer',{ref:"searchForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-4",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.filterData)}}},[_c('v-row',{attrs:{"align":"center"}},[_vm._l((_vm.searchFields),function(field,index){return _c('v-col',{key:index,staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t(("attributes." + field)),"maxlength":"32","clearable":""},model:{value:(_vm.search[field]),callback:function ($$v) {_vm.$set(_vm.search, field, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search[field]"}})],1)}),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"select_object","name":_vm.$t('attributes.city')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cities-field',{attrs:{"clearable":"","error-messages":errors},model:{value:(_vm.search.city),callback:function ($$v) {_vm.$set(_vm.search, "city", $$v)},expression:"search.city"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mb-2",attrs:{"cols":"12","sm":"6","lg":"auto"}},[_c('v-btn',{staticClass:"me-2",attrs:{"color":_vm.$store.state.app.color,"type":"submit"}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)],2)],1)]}}])}),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.selected_headers,"items":_vm.data.data,"items-per-page":100,"hide-default-footer":"","item-class":_vm.rowClass},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"40px"}},[_c('v-btn',{attrs:{"x-small":"","fab":"","color":"info","dark":""},on:{"click":function($event){return _vm.prepareToUpdateItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}])}),_c('pagination',{attrs:{"data":_vm.data.meta || {}},on:{"page-changed":_vm.filterData}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }