<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('expenses.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("expenses.title") }}</div>

                    <!-- btn add new item  -->
                    <v-btn fab small :color="$store.state.app.color" @click.stop="prepareToAddItem"><v-icon>mdi-plus</v-icon></v-btn>
                </div>

                <!-- Start Add form dialog -->
                <dialog-base :type="(expense.id && 'UPDATE') || 'ADD'" v-model="formDialog" @canceled="formDialogClose" @confirmed="validateAndSave">
                    <template #content>
                        <validation-observer ref="expensesForm" v-slot="{ handleSubmit }">
                            <v-form class="px-3" @submit.prevent="handleSubmit(validateAndSave)">
                                <!-- show errors if there is errors when add/update item -->
                                <has-errors :errors="errors"></has-errors>

                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <validation-provider
                                            rules="required|select_object"
                                            :name="$t('attributes.type_of_expense')"
                                            v-slot="{ errors }"
                                        >
                                            <type-expenses-field v-model="expense.type" :error-messages="errors" />
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|numeric" :name="$t('attributes.amount')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="expense.amount"
                                                :label="$t('attributes.amount')"
                                                counter
                                                type="number"
                                                :error-messages="errors"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <validation-provider
                                            rules="required|date|before_or_equal:today"
                                            :name="$t('attributes.date')"
                                            v-slot="{ errors }"
                                        >
                                            <date-field v-model="expense.date" :label="$t('attributes.date')" :error-messages="errors"></date-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider rules="max:100" :name="$t('attributes.extra')" v-slot="{ errors }">
                                            <v-textarea
                                                v-model.trim="expense.extra"
                                                :label="$t('attributes.extra')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="100"
                                                rows="1"
                                                auto-grow
                                            ></v-textarea>
                                        </validation-provider>
                                    </v-col>
                                </v-row>

                                <!-- This button to allow to user to submit form by clicking enter -->
                                <button type="submit" class="hide"></button>
                            </v-form>
                        </validation-observer>
                    </template>
                </dialog-base>
                <!-- End Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>
                <!-- Start show headers options -->
                <div class="px-4">
                    <v-select v-model="selected_headers" :items="headers" :label="$t('options.show_columns')" multiple return-object>
                        <template v-slot:selection="{ item }">
                            <v-chip filter>
                                <span>{{ item.text }}</span>
                            </v-chip>
                        </template>
                    </v-select>
                </div>
                <!-- End show headers options -->

                <v-divider></v-divider>

                <!-- Start search panel -->
                <v-expansion-panels class="" focusable>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="justify-start">
                            <h5 class="subtitle-1"><v-icon>mdi-magnify</v-icon> {{ $t("options.search") }}</h5>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <!-- Start search options -->
                            <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                                <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                                    <v-row align="center">
                                        <v-col cols="12" sm="6" lg="3" class="pb-0" v-for="(field, index) in searchFields" :key="index">
                                            <v-text-field
                                                v-model.trim="search[field]"
                                                :label="$t(`attributes.${field}`)"
                                                maxlength="32"
                                                clearable
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <validation-provider rules="select_object" :name="$t('attributes.type_of_expense')" v-slot="{ errors }">
                                                <type-expenses-field v-model="search.type" clearable :error-messages="errors" />
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <validation-provider
                                                rules="date|before_or_equal:today"
                                                vid="date_from"
                                                :name="$t('search.date_from')"
                                                v-slot="{ errors }"
                                            >
                                                <date-field
                                                    v-model="search.date_from"
                                                    :label="$t('search.date_from')"
                                                    :error-messages="errors"
                                                    clearable
                                                ></date-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <validation-provider
                                                rules="date|before_or_equal:today|after_or_equal:@date_from"
                                                vid="date_to"
                                                :name="$t('search.date_to')"
                                                v-slot="{ errors }"
                                            >
                                                <date-field
                                                    v-model="search.date_to"
                                                    :label="$t('search.date_to')"
                                                    :error-messages="errors"
                                                    clearable
                                                ></date-field>
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <validation-provider
                                                rules="date|before_or_equal:today"
                                                vid="created_at_from"
                                                :name="$t('search.created_at_from')"
                                                v-slot="{ errors }"
                                            >
                                                <date-field
                                                    v-model="search.created_at_from"
                                                    :label="$t('search.created_at_from')"
                                                    :error-messages="errors"
                                                    clearable
                                                ></date-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <validation-provider
                                                rules="date|before_or_equal:today|after_or_equal:@created_at_from"
                                                vid="created_at_to"
                                                :name="$t('search.created_at_to')"
                                                v-slot="{ errors }"
                                            >
                                                <date-field
                                                    v-model="search.created_at_to"
                                                    :label="$t('search.created_at_to')"
                                                    :error-messages="errors"
                                                    clearable
                                                ></date-field>
                                            </validation-provider>
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                            <v-btn :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </validation-observer>
                            <!-- End search options -->
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <!-- End search panel -->

                <v-divider></v-divider>

                <v-data-table :headers="selected_headers" :items="data.data" :items-per-page="100" hide-default-footer class="elevation-1">
                    <template v-slot:item.actions="{ item }">
                        <v-btn x-small fab color="info" dark @click="prepareToUpdateItem(item)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>

                <!-- Start pagination -->
                <pagination :data="data.meta || {}" @page-changed="filterData" />
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";
import TypeExpensesField from "@/components/data/TypeExpensesField.vue";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;

    store.dispatch("expense/fetchAll", { page, params }).then(() => {
        next();
    });
}

export default {
    components: { TypeExpensesField },

    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    data: function() {
        return {
            formDialog: false,
            expense: {},
            errors: {},
            search: Object.assign({}, this.$route.query),
            searchFields: ["id", "amount", "extra"],
            headers: [
                { text: this.$t("attributes.id"), value: "id" },
                { text: this.$t("attributes.type"), value: "type.name" },
                { text: this.$t("attributes.amount"), value: "amount" },
                { text: this.$t("attributes.date"), value: "date" },
                { text: this.$t("attributes.extra"), value: "extra" },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.updated_at"), value: "updated_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ],
            selected_headers: []
        };
    },

    computed: {
        ...mapState({
            data: state => state.expense.expenses
        })
    },

    created() {
        this.selected_headers = this.headers.filter(c => !["created_at", "updated_at"].includes(c.value));
    },

    methods: {
        ...mapActions("expense", ["add", "update"]),

        /**
         * Validate form if valid then call save method to send request to server
         */
        validateAndSave() {
            this.$refs.expensesForm.validate().then(success => {
                if (!success) {
                    return;
                }
                this.save();
            });
        },

        /**
         * Add data to server
         */
        save() {
            this.$store.state.app.loading = true;
            this.errors = {};

            // I took copy of object becuase I will change in properties of object and I do not want it effect in data that user insert them
            let expense = Object.assign({}, this.expense);
            expense.type_of_expense_id = expense.type && expense.type.id;

            (expense.id ? this.update(expense) : this.add(expense))
                .then(() => {
                    this.formDialogClose();
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Close form dialog which responsible on add/delete data
         */
        formDialogClose() {
            this.formDialog = false;
            this.errors = {};
            this.$refs.expensesForm.reset();
            this.expense = {};
        },

        /**
         *  Set configuration for Add item
         */
        prepareToAddItem() {
            this.expense = { date: _getCurrentDate() };
            this.formDialog = true;
        },

        /**
         *  Set configuration for update item
         */
        prepareToUpdateItem(item) {
            this.formDialog = true;
            this.expense = Object.assign({}, item);
        },

        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            params.type = params.type && params.type.id;

            this.$router.push({ query: { ...params, page } }).catch(() => {});
        }
    }
};
</script>
