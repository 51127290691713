import userApi from "@/apis/userApi";

export default {
    namespaced: true,

    state: {
        users: [],
        user: {}
    },

    getters: {
        getById: state => id => {
            return state.users.data && state.users.data.find(item => item.id === id);
        }
    },

    mutations: {
        ADD_USER(state, user) {
            if (state.users.data) {
                state.users.data.unshift(user);
                state.users.meta.to++;
                state.users.meta.total++;
            }
        },
        UPDATE_USER(state, user) {
            state.users.data = [...state.users.data.map(c => (c.id !== user.id ? c : user))];
        },
        SET_USERS(state, users) {
            state.users = users;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        DELETE_USER(state, id) {
            if (state.users.data) {
                state.users.data = state.users.data.filter(item => item.id !== id);
                state.users.meta.to--;
                state.users.meta.total--;
            }
        }
    },

    actions: {
        addUser({ commit }, user) {
            return userApi.create(user).then(r => {
                Toast.success("Added successfully");
                commit("ADD_USER", r.data.data);
            });
        },

        fetchUsers({ commit }, { page = 1, params = {} }) {
            return userApi.getAll(page, params).then(r => {
                commit("SET_USERS", r.data);
            });
        },

        fetchUser({ commit }, id) {
            return userApi.getById(id).then(r => {
                commit("SET_USER", r.data.data);
            });
        },

        updateUser({ commit }, user) {
            return userApi.update(user).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE_USER", r.data.data);
            });
        },

        deleteUser({ commit }, id) {
            return userApi.delete(id).then(r => {
                Toast.success("Successfully deleted");
                commit("DELETE_USER", id);
            });
        }
    }
};
