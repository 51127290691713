<template>
    <div>
        <v-overlay :value="loading" z-index="9999999">
            <v-progress-circular indeterminate size="128"></v-progress-circular>
        </v-overlay>
        <component :is="layout"> </component>
    </div>
</template>

<script>
import DashboardLayout from "./views/dashboard/layouts/Dashboard.vue";
import EmptyLayout from "./views/layouts/Empty.vue";
import PaperLayout from "./views/layouts/Paper.vue";
import { mapState } from "vuex";

const default_layout = "empty-layout";

export default {
    name: "App",

    components: {
        DashboardLayout,
        EmptyLayout,
        PaperLayout
    },

    computed: {
        ...mapState("app", ["loading"]),

        layout() {
            return this.$route.meta.layout || default_layout;
        }
    }
};
</script>

<style lang="scss">
.card-main-title {
    font-weight: bold !important;
    color: rgba(0, 0, 0, 0.54) !important;
}
</style>
