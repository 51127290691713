import cityApi from "@/apis/cityApi";

/**
 * Add short city object
 * This function it returns only properties that shortCitites needs them
 * @returns {object} city
 */
function shortCity(city) {
    return {
        id: city.id,
        name: city.name,
        price: city.price,
        active: city.active
    };
}

export default {
    namespaced: true,

    state: {
        cities: [],
        citiesShort: []
    },

    getters: {
        getById: state => id => {
            return state.citiesShort.length && state.citiesShort.find(item => item.id === id);
        }
    },

    mutations: {
        ADD_CITY(state, city) {
            if (state.cities.data) {
                state.cities.data.unshift(city);
                state.cities.meta.to++;
                state.cities.meta.total++;
            }
            state.citiesShort.push(shortCity(city));
        },
        UPDATE_CITY(state, city) {
            state.cities.data = [...state.cities.data.map(item => (item.id !== city.id ? item : city))];
            let index = state.citiesShort.findIndex(e => e.id === city.id);
            state.citiesShort[index] = shortCity(city);
        },
        SET_CITIES(state, cities) {
            state.cities = cities;
        },
        SET_CITIES_SHORT(state, cities) {
            state.citiesShort = cities;
        }
    },

    actions: {
        addCity({ commit }, city) {
            return cityApi.create(city).then(r => {
                Toast.success("Added successfully");
                commit("ADD_CITY", r.data.data);
            });
        },

        fetchCities({ commit }, { page = 1, params = {} }) {
            return cityApi.getAll(page, params).then(r => {
                commit("SET_CITIES", r.data);
            });
        },

        fetchCitiesShort({ commit, state }) {
            if (!state.citiesShort.length) {
                return cityApi.getAllShort().then(r => {
                    commit("SET_CITIES_SHORT", r.data.data);
                });
            }
        },

        updateCity({ commit }, city) {
            return cityApi.update(city).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE_CITY", r.data.data);
            });
        }
    }
};
