/**
 * This module for Statistic
 */
import financialApi from "@/apis/financialApi";

export default {
    namespaced: true,

    state: {
        financial: {}
    },

    mutations: {
        SET(state, data) {
            state.financial = data;
        }
    },

    actions: {
        fetchData({ commit }, params = {}) {
            return financialApi.getData(params).then(r => {
                commit("SET", r.data.data);
            });
        }
    }
};
