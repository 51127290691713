<template>
    <v-combobox
        :items="data || []"
        item-text="name"
        :value="value"
        :label="label || $t('attributes.type_of_revenue')"
        @input="$emit('input', $event)"
        :clearable="clearable"
        :outlined="outlined"
        :error-messages="errorMessages"
    ></v-combobox>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "TypeRevenuesField",

    props: {
        value: [String, Array, Object],
        label: { type: String },
        errorMessages: [String, Array],
        clearable: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false }
    },

    computed: {
        ...mapState({
            data: state => state.typeRevenue.typesShort
        })
    },

    created() {
        this.$store.dispatch("typeRevenue/fetchAllShort");
    }
};
</script>
