<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('financial.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("financial.title") }}</div>
                </div>
            </template>
            <template #content>
                <v-divider></v-divider>

                <!-- Start search options -->
                <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                    <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                        <v-row align="center">
                            <v-col cols="12" sm="6" lg="3" class="pb-0">
                                <validation-provider
                                    rules="date|before_or_equal:today"
                                    vid="date_from"
                                    :name="$t('search.date_from')"
                                    v-slot="{ errors }"
                                >
                                    <date-field
                                        v-model="search.date_from"
                                        :label="$t('search.date_from')"
                                        :error-messages="errors"
                                        clearable
                                    ></date-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3" class="pb-0">
                                <validation-provider
                                    rules="date|before_or_equal:today|after_or_equal:@date_from"
                                    vid="date_to"
                                    :name="$t('search.date_to')"
                                    v-slot="{ errors }"
                                >
                                    <date-field
                                        v-model="search.date_to"
                                        :label="$t('search.date_to')"
                                        :error-messages="errors"
                                        clearable
                                    ></date-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                <v-btn class="me-2" :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </validation-observer>
                <!-- End search options -->

                <v-divider></v-divider>

                <!-- Start financial -->
                <v-row>
                    <v-col cols="12" sm="6" lg="4" v-for="(item, index) in statisticalData" :key="`cardStats${index}`">
                        <card-stats
                            color="blue-grey"
                            :icon="item.icon"
                            :count="item.count"
                            :title="item.title"
                            :description="item.description"
                            :description-icon="item.descIcon"
                        ></card-stats>
                    </v-col>
                </v-row>
                <!-- End financial -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState } from "vuex";
import CardStats from "@/components/global/CardStats";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let params = routeTo.query;
    store.dispatch("financial/fetchData", params).then(() => next());
}

export default {
    name: "Financial",

    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },
    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    components: { CardStats },

    data: function() {
        return {
            errors: {},
            search: Object.assign({}, this.$route.query)
        };
    },
    computed: {
        ...mapState("financial", ["financial"]),

        statisticalData: function() {
            return [
                {
                    title: this.$t("financial.statistics.shipments_amount.title"),
                    icon: this.$t("financial.statistics.shipments_amount.icon"),
                    description: this.$t("financial.statistics.shipments_amount.desc"),
                    count: `$${Number(this.financial.shipments.amount).toLocaleString()}`
                },
                {
                    title: this.$t("financial.statistics.revenues.title"),
                    icon: this.$t("financial.statistics.revenues.icon"),
                    description: this.$t("financial.statistics.revenues.desc"),
                    count: `$${Number(this.financial.revenues).toLocaleString()}`
                },
                {
                    title: this.$t("financial.statistics.expenses.title"),
                    icon: this.$t("financial.statistics.expenses.icon"),
                    description: this.$t("financial.statistics.expenses.desc"),
                    count: `$${Number(this.financial.expenses).toLocaleString()}`
                },
                {
                    title: this.$t("financial.statistics.closed_balance.title"),
                    icon: this.$t("financial.statistics.closed_balance.icon"),
                    description: this.$t("financial.statistics.closed_balance.desc"),
                    count: `$${Number(this.financial.closed_balance).toLocaleString()}`
                },
                {
                    title: this.$t("financial.statistics.available_balance.title"),
                    icon: this.$t("financial.statistics.available_balance.icon"),
                    description: this.$t("financial.statistics.available_balance.desc"),
                    count: `$${Number(this.financial.available_balance).toLocaleString()}`
                },
                {
                    title: this.$t("financial.statistics.shipments_earnings.title"),
                    icon: this.$t("financial.statistics.shipments_earnings.icon"),
                    description: this.$t("financial.statistics.shipments_earnings.desc"),
                    count: `$${Number(this.financial.shipments.earnings).toLocaleString()}`
                },
                {
                    title: this.$t("financial.statistics.shipments_deliver_fee.title"),
                    icon: this.$t("financial.statistics.shipments_deliver_fee.icon"),
                    description: this.$t("financial.statistics.shipments_deliver_fee.desc"),
                    count: `$${Number(this.financial.shipments.deliver_fee).toLocaleString()}`
                }
            ];
        }
    },
    methods: {
        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         */
        filterData() {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params } }).catch(() => {});
        }
    }
};
</script>
