import branchApi from "@/apis/branchesApi";

/**
 * Add short branch object
 * This function it returns only properties that branches needs them
 * @returns {object} branch
 */
function shortBranch(branch) {
    return {
        id: branch.id,
        name: branch.name
    };
}

export default {
    namespaced: true,

    state: {
        branches: [],
        branchesShort: []
    },

    getters: {
        getById: state => id => {
            return state.branchesShort.length && state.branchesShort.find(item => item.id === id);
        }
    },

    mutations: {
        ADD_ITEM(state, branch) {
            if (state.branches.data) {
                state.branches.data.unshift(branch);
                state.branches.meta.to++;
                state.branches.meta.total++;
            }
            state.branchesShort.push(shortBranch(branch));
        },
        UPDATE_ITEM(state, branch) {
            state.branches.data = [...state.branches.data.map(item => (item.id !== branch.id ? item : branch))];
            let index = state.branchesShort.findIndex(e => e.id === branch.id);
            state.branchesShort[index] = shortBranch(branch);
        },
        SET_ALL(state, branches) {
            state.branches = branches;
        },
        SET_ALL_SHORT(state, branches) {
            state.branchesShort = branches;
        }
    },

    actions: {
        add({ commit }, data) {
            return branchApi.create(data).then(r => {
                Toast.success("Added successfully");
                commit("ADD_ITEM", r.data.data);
            });
        },

        fetchAll({ commit }, { page = 1, params = {} }) {
            return branchApi.getAll(page, params).then(r => {
                commit("SET_ALL", r.data);
            });
        },

        fetchAllShort({ commit, state }) {
            if (!state.branchesShort.length) {
                return branchApi.getAllShort().then(r => {
                    commit("SET_ALL_SHORT", r.data.data);
                });
            }
        },

        update({ commit }, data) {
            return branchApi.update(data).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE_ITEM", r.data.data);
            });
        }
    }
};
