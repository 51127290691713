import expenseApi from "@/apis/expenseApi";

export default {
    namespaced: true,

    state: {
        expenses: [],
        expense: {}
    },

    mutations: {
        ADD(state, expense) {
            if (state.expenses.data) {
                state.expenses.data.unshift(expense);
                state.expenses.meta.to++;
                state.expenses.meta.total++;
            }
        },
        UPDATE(state, expense) {
            if (state.expenses.data) {
                state.expenses.data = state.expenses.data.map(c => (c.id !== expense.id ? c : expense));
            }
        },
        SET_ITEMS(state, expenses) {
            state.expenses = expenses;
        },
        SET_ITEM(state, expense) {
            state.expense = expense;
        }
    },

    actions: {
        add({ commit }, data) {
            return expenseApi.create(data).then(r => {
                Toast.success("Added successfully");
                commit("ADD", r.data.data);
                return Promise.resolve(r);
            });
        },

        fetchAll({ commit }, { page = 1, params = {} }) {
            return expenseApi.getAll(page, params).then(r => {
                commit("SET_ITEMS", r.data);
            });
        },

        fetchById({ commit }, id) {
            return expenseApi.getById(id).then(r => {
                commit("SET_ITEM", r.data.data);
                return Promise.resolve(r);
            });
        },

        update({ commit }, data) {
            return expenseApi.update(data).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE", r.data.data);
                return Promise.resolve(r);
            });
        }
    }
};
