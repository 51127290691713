<template>
    <v-select
        :items="data"
        item-value="id"
        item-text="name"
        :value="value"
        :label="label || $t('attributes.state')"
        @input="$emit('input', $event)"
        :clearable="clearable"
        :outlined="outlined"
        :error-messages="errorMessages"
    />
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "TripStatusesField",
    props: {
        value: [String, Number],
        label: { type: String },
        outlined: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false },
        errorMessages: [String, Array],
        onlyOptions: { type: [Array, Boolean], default: false },
        excludeOptions: { type: [Array, Boolean], default: false }
    },
    computed: {
        ...mapState({
            statuses: state => state.trip.statuses
        }),

        data() {
            if (this.excludeOptions && this.onlyOptions) throw Error("Do not use exclude-options and only-options togather");

            if (this.excludeOptions) {
                return this.statuses.filter(s => !this.excludeOptions.includes(s.id));
            }

            if (this.onlyOptions) {
                return this.statuses.filter(s => this.onlyOptions.includes(s.id));
            }

            return this.statuses;
        }
    }
};
</script>
