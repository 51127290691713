import Vue from "vue";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import { required, required_if, email, min, max, min_value, max_value, digits, alpha_num, length, confirmed } from "vee-validate/dist/rules";
import validator from "validator";

/**
 * Register it globally
 */
Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);

/**
 *
 * Set validation interaction mode
 */
setInteractionMode("eager");

/**
 * ************************************
 *          Start exists rules        *
 * ************************************
 */

extend("required", {
    ...required,
    message: "The {_field_} field is required"
});

extend("required_if", {
    ...required_if,
    message: "The {_field_} field is required when {target} is present"
});

/* Start String rules */
extend("min", {
    ...min,
    message: "The {_field_} must be at least {length} characters"
});

extend("max", {
    ...max,
    message: "{_field_} may not be greater than {length} characters"
});
/* End String rules */

extend("email", {
    ...email,
    message: "The email format is invalid"
});

/* Start Numeric rules */
extend("numeric", {
    validate(value) {
        return !isNaN(value);
    },
    message: "The {_field_} must be a number"
});

extend("min_value", {
    ...min_value,
    message: "The {_field_} must be greater than or equal {min}"
});

extend("max_value", {
    ...max_value,
    message: "The {_field_} must be less than or equal {max}"
});

extend("digits", {
    ...digits,
    message: "The {_field_} must be numeric and have the specified number of {length}"
});

extend("alpha_num", {
    ...alpha_num,
    message: "The {_field_} may contain alphabetic characters or numbers"
});

extend("length", {
    ...length,
    message: "The {_field_} must be {length} characters"
});

extend("confirmed", {
    ...confirmed,
    message: "The {_field_} must have the same value as the {target} field"
});

/* End Numeric rules */

/**
 * ************************************
 *          Start custom rules        *
 * ************************************
 */

/**
 * This validation to validate combobox or selct that has items are objects
 * Validate depneds on id , check if object has an id attribute
 */
extend("select_object", {
    validate(value) {
        if (typeof value !== "object") return false;

        return value.id || Object.keys(value).some(k => k.indexOf("_id"));
    },
    message: "The selected {_field_} is invalid"
});

/**
 * Check if the string is an URL.
 */
extend("url", {
    validate(value) {
        return validator.isURL(value, { require_protocol: true });
    },
    message: "The url format is invalid"
});

/**
 * Check if string correct number format
 */

extend("phone", {
    validate(value) {
        return RegExp("0(9[1-5]|21)[0-9]{7}").test(value);
    },
    message: "The phone number is invalid"
});

/**
 * Check if string correct username format
 */

extend("username", {
    validate(value) {
        return RegExp("^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,30}[a-zA-Z0-9]$").test(value);
    },
    message: "The username is invalid"
});

/**
 * Check if the string is a Date.
 */
extend("date", {
    validate(value) {
        return validator.isDate(value);
    },
    message: "The date format is invalid"
});

/**
 * Check if the string is a Date before or equal another date.
 */
extend("before_or_equal", {
    params: ["date"],

    validate(value, { date }) {
        // The default second date in isAfter() function is now(today)
        if (date === "today") return !validator.isAfter(value);
        // if value of @date paramter is null ,that means we can not validate date , so skipp it
        if (!date) return true;
        return !validator.isAfter(value, date);
    },
    message: "The date must be before or equal {date}"
});

/**
 * Check if the string is a Date after or equal another date.
 */
extend("after_or_equal", {
    params: ["date"],

    validate(value, { date }) {
        // The default second date in isBefore() function is now(today)
        if (date == "today") return !validator.isBefore(value);
        // if value of @date paramter is null ,that means we can not validate date , so skipp it
        if (!date) return true;
        return !validator.isBefore(value, date);
    },
    message: "The date must be after or equal {date}"
});
