var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('base-card',{staticClass:"my-4 mx-auto",attrs:{"icon":_vm.$t('shipments.icon')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"headline card-main-title"},[_vm._v(_vm._s(_vm.isUpdate ? _vm.shipment.code : _vm.$t("options.add")))])])]},proxy:true},{key:"content",fn:function(){return [_c('v-divider'),_c('validation-observer',{ref:"shipmentsForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('has-errors',{attrs:{"errors":_vm.errors}}),_c('v-row',{staticClass:"pt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('app-header',{attrs:{"label":_vm.$t('attributes.sender'),"prepend-inner-icon":_vm.$t('common.icons.sender')}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"numeric|min:1","name":_vm.$t('attributes.customer')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","clearable":"","label":_vm.$t('attributes.customer'),"error-messages":errors,"counter":"","type":"number","append-icon":"mdi-magnify"},on:{"click:append":_vm.bringCustomer},model:{value:(_vm.shipment.customer),callback:function ($$v) {_vm.$set(_vm.shipment, "customer", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shipment.customer"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|min:3|max:32","name":_vm.$t('attributes.sender_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","readonly":_vm.isCustomer,"label":_vm.$t('attributes.sender_name'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(_vm.shipment.sender_name),callback:function ($$v) {_vm.$set(_vm.shipment, "sender_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shipment.sender_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|phone","name":_vm.$t('attributes.sender_phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","readonly":_vm.isCustomer,"label":_vm.$t('attributes.sender_phone'),"error-messages":errors,"counter":"","maxlength":10},model:{value:(_vm.shipment.sender_phone),callback:function ($$v) {_vm.$set(_vm.shipment, "sender_phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shipment.sender_phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"phone","name":_vm.$t('attributes.sender_phone2')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","readonly":_vm.isCustomer,"label":_vm.$t('attributes.sender_phone2'),"error-messages":errors,"counter":"","maxlength":10},model:{value:(_vm.shipment.sender_phone2),callback:function ($$v) {_vm.$set(_vm.shipment, "sender_phone2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shipment.sender_phone2"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('app-header',{attrs:{"label":_vm.$t('attributes.receiver'),"prepend-inner-icon":_vm.$t('common.icons.receiver')}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"min:3|max:32","name":_vm.$t('attributes.receiver_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.receiver_name'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(_vm.shipment.receiver_name),callback:function ($$v) {_vm.$set(_vm.shipment, "receiver_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shipment.receiver_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|phone","name":_vm.$t('attributes.receiver_phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.receiver_phone'),"error-messages":errors,"counter":"","maxlength":10},model:{value:(_vm.shipment.receiver_phone),callback:function ($$v) {_vm.$set(_vm.shipment, "receiver_phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shipment.receiver_phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"phone","name":_vm.$t('attributes.receiver_phone2')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.receiver_phone2'),"error-messages":errors,"counter":"","maxlength":10},model:{value:(_vm.shipment.receiver_phone2),callback:function ($$v) {_vm.$set(_vm.shipment, "receiver_phone2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shipment.receiver_phone2"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|min:3|max:64","name":_vm.$t('attributes.receiver_address')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.receiver_address'),"error-messages":errors,"counter":"","maxlength":64},model:{value:(_vm.shipment.receiver_address),callback:function ($$v) {_vm.$set(_vm.shipment, "receiver_address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shipment.receiver_address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|select_object","name":_vm.$t('attributes.branch')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('branches-field',{attrs:{"outlined":"","error-messages":errors},model:{value:(_vm.shipment.branch),callback:function ($$v) {_vm.$set(_vm.shipment, "branch", $$v)},expression:"shipment.branch"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|select_object","name":_vm.$t('attributes.city')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cities-field',{attrs:{"outlined":"","error-messages":errors},on:{"input":_vm.cityChanged},model:{value:(_vm.shipment.city),callback:function ($$v) {_vm.$set(_vm.shipment, "city", $$v)},expression:"shipment.city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|numeric|min:0","name":_vm.$t('attributes.price')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.price'),"error-messages":errors,"counter":"","type":"number"},model:{value:(_vm.shipment.price),callback:function ($$v) {_vm.$set(_vm.shipment, "price", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shipment.price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|numeric|min:0","name":_vm.$t('attributes.fee')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.fee'),"error-messages":errors,"counter":"","type":"number"},model:{value:(_vm.shipment.fee),callback:function ($$v) {_vm.$set(_vm.shipment, "fee", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shipment.fee"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|numeric|min:0","name":_vm.$t('attributes.deliver_fee')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.deliver_fee'),"error-messages":errors,"counter":"","type":"number"},model:{value:(_vm.shipment.deliver_fee),callback:function ($$v) {_vm.$set(_vm.shipment, "deliver_fee", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shipment.deliver_fee"}})]}}],null,true)})],1),(!_vm.isWaitingAccept)?_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|date|before_or_equal:today","name":_vm.$t('attributes.received_at')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"outlined":"","label":_vm.$t('attributes.received_at'),"error-messages":errors},model:{value:(_vm.shipment.received_at),callback:function ($$v) {_vm.$set(_vm.shipment, "received_at", $$v)},expression:"shipment.received_at"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.state')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('shipment-statuses-field',{attrs:{"outlined":"","error-messages":errors},on:{"input":_vm.statusChanged},model:{value:(_vm.shipment.state),callback:function ($$v) {_vm.$set(_vm.shipment, "state", $$v)},expression:"shipment.state"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('attributes.fee_on_customer')},model:{value:(_vm.shipment.fee_on_customer),callback:function ($$v) {_vm.$set(_vm.shipment, "fee_on_customer", $$v)},expression:"shipment.fee_on_customer"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"max:200","name":_vm.$t('attributes.note')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('attributes.note'),"error-messages":errors,"counter":"","maxlength":200,"rows":"1","outlined":"","clearable":"","auto-grow":""},model:{value:(_vm.shipment.note),callback:function ($$v) {_vm.$set(_vm.shipment, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shipment.note"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"max:200","name":_vm.$t('attributes.extra')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('attributes.extra'),"error-messages":errors,"counter":"","maxlength":200,"rows":"1","outlined":"","clearable":"","auto-grow":""},model:{value:(_vm.shipment.extra),callback:function ($$v) {_vm.$set(_vm.shipment, "extra", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shipment.extra"}})]}}],null,true)})],1)],1),_c('v-divider',{staticClass:"mb-5"}),_c('app-header',{attrs:{"label":_vm.$t('attributes.shipment_items'),"prepend-inner-icon":_vm.$t('shipments.icon')}}),_vm._l((_vm.shipment.items),function(item,index){return _c('div',{key:("Sitems" + index),staticClass:"mt-5"},[_c('shipment-item',{attrs:{"products":_vm.customerProducts || [],"index":index},on:{"input":_vm.updateShipmentItem,"add-click":_vm.addShipmentItem,"delete-click":_vm.deleteShipmentItem},model:{value:(_vm.shipment.items[index]),callback:function ($$v) {_vm.$set(_vm.shipment.items, index, $$v)},expression:"shipment.items[index]"}})],1)}),_c('div',{staticClass:"text-end px-5 py-3"},[_c('v-btn',{attrs:{"color":"info","min-width":"150px","type":"submit"}},[_vm._v(_vm._s(_vm.$t("options.save")))])],1)],2)]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }