var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('base-card',{staticClass:"my-4 mx-auto",attrs:{"icon":_vm.$t('adjustment_customers.icon')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"headline card-main-title"},[_vm._v(_vm._s(_vm.isUpdate ? _vm.adjustment.id : _vm.$t("options.add")))])])]},proxy:true},{key:"content",fn:function(){return [_c('v-divider'),_c('validation-observer',{ref:"adjustmentForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('has-errors',{attrs:{"errors":_vm.errors}}),_c('v-row',{staticClass:"pt-5"},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"numeric|min:1","name":_vm.$t('attributes.customer')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","clearable":"","label":_vm.$t('attributes.customer'),"error-messages":errors,"counter":"","type":"number","append-icon":"mdi-magnify"},on:{"click:append":_vm.bringCustomer,"input":_vm.customerChanged},model:{value:(_vm.adjustment.customer_id),callback:function ($$v) {_vm.$set(_vm.adjustment, "customer_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"adjustment.customer_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|min:3|max:32","name":_vm.$t('attributes.sender_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","readonly":"","label":_vm.$t('attributes.sender_name'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(_vm.adjustment.customer.name),callback:function ($$v) {_vm.$set(_vm.adjustment.customer, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"adjustment.customer.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|phone","name":_vm.$t('attributes.sender_phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","readonly":"","label":_vm.$t('attributes.sender_phone'),"error-messages":errors,"counter":"","maxlength":10},model:{value:(_vm.adjustment.customer.phone),callback:function ($$v) {_vm.$set(_vm.adjustment.customer, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"adjustment.customer.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"phone","name":_vm.$t('attributes.sender_phone2')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","readonly":"","label":_vm.$t('attributes.sender_phone2'),"error-messages":errors,"counter":"","maxlength":10},model:{value:(_vm.adjustment.customer.phone2),callback:function ($$v) {_vm.$set(_vm.adjustment.customer, "phone2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"adjustment.customer.phone2"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|numeric|min:0","name":_vm.$t('attributes.amount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":_vm.$t('attributes.amount'),"error-messages":errors,"counter":"","type":"number","readonly":""},model:{value:(_vm.adjustment.amount),callback:function ($$v) {_vm.$set(_vm.adjustment, "amount", $$v)},expression:"adjustment.amount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":"required|date|before_or_equal:today","name":_vm.$t('attributes.date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-field',{attrs:{"outlined":"","label":_vm.$t('attributes.date'),"error-messages":errors},model:{value:(_vm.adjustment.date),callback:function ($$v) {_vm.$set(_vm.adjustment, "date", $$v)},expression:"adjustment.date"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"max:200","name":_vm.$t('attributes.note')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('attributes.note'),"error-messages":errors,"counter":"","maxlength":200,"rows":"1","outlined":"","clearable":"","auto-grow":""},model:{value:(_vm.adjustment.note),callback:function ($$v) {_vm.$set(_vm.adjustment, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"adjustment.note"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"max:200","name":_vm.$t('attributes.extra')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('attributes.extra'),"error-messages":errors,"counter":"","maxlength":200,"rows":"1","outlined":"","clearable":"","auto-grow":""},model:{value:(_vm.adjustment.extra),callback:function ($$v) {_vm.$set(_vm.adjustment, "extra", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"adjustment.extra"}})]}}],null,true)})],1)],1),_c('v-divider'),_c('div',{staticClass:"text-end px-5 py-3"},[_c('v-btn',{attrs:{"color":"info","min-width":"150px","type":"submit"}},[_vm._v(_vm._s(_vm.$t("options.save")))])],1)],1)]}}])})]},proxy:true}])}),_c('v-card',{staticClass:"my-8"},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(_vm._s(_vm.$t("attributes.selected_shipments"))+" ("+_vm._s(_vm.adjustment.shipments.length)+")")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.adjustment.shipments,"hide-default-footer":"","items-per-page":1000},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"info","to":("/shipments/" + (item.id))}},[_vm._v(_vm._s(item.code))])]}},{key:"item.price_with_shipping",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price + (item.fee_on_customer ? 0 : item.fee))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price - (item.fee_on_customer ? item.fee : 0))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","color":"error","fab":"","dark":""},on:{"click":function($event){return _vm.removeShipment(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}])}),_c('v-card-actions',[_c('v-btn',{attrs:{"min-width":"100px"},on:{"click":_vm.unselectAll}},[_vm._v(_vm._s(_vm.$t("options.unselect_all")))])],1)],1),_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(_vm._s(_vm.$t("attributes.available_shipments"))+" ("+_vm._s(_vm.new_shipments.length)+")")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.new_shipments,"hide-default-footer":"","items-per-page":1000},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"info","to":("/shipments/" + (item.id))}},[_vm._v(_vm._s(item.code))])]}},{key:"item.price_with_shipping",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price + (item.fee_on_customer ? 0 : item.fee))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price - (item.fee_on_customer ? item.fee : 0))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","color":"info","fab":""},on:{"click":function($event){return _vm.addShipment(item)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])}),_c('v-card-actions',[_c('v-btn',{staticClass:"me-3",attrs:{"min-width":"100px","color":"info"},on:{"click":_vm.selectAll}},[_vm._v(_vm._s(_vm.$t("options.select_all")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }