<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('type_of_revenues.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("type_of_revenues.title") }}</div>

                    <!-- btn add new item  -->
                    <v-btn fab small :color="$store.state.app.color" @click.stop="prepareToAddItem"><v-icon>mdi-plus</v-icon></v-btn>
                </div>

                <!-- Start Add form dialog -->
                <dialog-base
                    :type="(type.id && 'UPDATE') || 'ADD'"
                    v-model="formDialog"
                    @canceled="formDialogClose"
                    @confirmed="validateAndSave"
                    max-width="400"
                >
                    <template #content>
                        <validation-observer ref="typeOfRevenuesForm" v-slot="{ handleSubmit }">
                            <v-form class="px-3" @submit.prevent="handleSubmit(validateAndSave)">
                                <!-- show errors if there is errors when add/update item -->
                                <has-errors :errors="errors"></has-errors>

                                <v-row>
                                    <v-col cols="12">
                                        <validation-provider rules="required|min:3|max:32" :name="$t('attributes.name')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="type.name"
                                                :label="$t('attributes.name')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="32"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider rules="max:100" :name="$t('attributes.desc')" v-slot="{ errors }">
                                            <v-textarea
                                                v-model.trim="type.desc"
                                                :label="$t('attributes.desc')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="100"
                                                rows="1"
                                                auto-grow
                                            ></v-textarea>
                                        </validation-provider>
                                    </v-col>
                                </v-row>

                                <!-- This button to allow to user to submit form by clicking enter -->
                                <button type="submit" class="hide"></button>
                            </v-form>
                        </validation-observer>
                    </template>
                </dialog-base>
                <!-- End Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>

                <!-- Start search options -->
                <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                    <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                        <v-row align="center">
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="search.name" :label="$t('attributes.name')" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="search.desc" :label="$t('attributes.desc')" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                <v-btn class="me-2" :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </validation-observer>
                <!-- End search options -->

                <v-divider></v-divider>

                <v-data-table :headers="headers" :items="data.data" :items-per-page="100" hide-default-footer class="elevation-1">
                    <template v-slot:item.actions="{ item }">
                        <v-btn x-small fab color="info" dark @click="prepareToUpdateItem(item)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>

                <!-- Start pagination -->
                <pagination :data="data.meta || {}" @page-changed="filterData" />
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;

    store.dispatch("typeRevenue/fetchAll", { page, params }).then(() => {
        next();
    });
}

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    data: function() {
        return {
            formDialog: false,
            type: {},
            errors: {},
            search: Object.assign({}, this.$route.query),
            headers: [
                { text: this.$t("attributes.name"), value: "name" },
                { text: this.$t("attributes.desc"), value: "desc" },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ]
        };
    },

    computed: {
        ...mapState({
            data: state => state.typeRevenue.types
        })
    },

    methods: {
        ...mapActions("typeRevenue", ["add", "update"]),

        /**
         * Validate form if valid then call save method to send request to server
         */
        validateAndSave() {
            this.$refs.typeOfRevenuesForm.validate().then(success => {
                if (!success) {
                    return;
                }
                this.save();
            });
        },

        /**
         * Add data to server
         */
        save() {
            this.$store.state.app.loading = true;
            this.errors = {};

            (this.type.id ? this.update(this.type) : this.add(this.type))
                .then(() => {
                    this.formDialogClose();
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Close form dialog which responsible on add/delete data
         */
        formDialogClose() {
            this.formDialog = false;
            this.errors = {};
            this.$refs.typeOfRevenuesForm.reset();
            this.type = {};
        },

        /**
         *  Set configuration for Add item
         */
        prepareToAddItem() {
            this.type = { active: false };
            this.formDialog = true;
        },

        /**
         *  Set configuration for update item
         */
        prepareToUpdateItem(item) {
            this.formDialog = true;
            this.type = Object.assign({}, item);
        },

        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        }
    }
};
</script>
