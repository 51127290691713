<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('branches.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("branches.title") }}</div>

                    <!-- btn add new item  -->
                    <v-btn fab small :color="$store.state.app.color" @click.stop="prepareToAddItem"><v-icon>mdi-plus</v-icon></v-btn>
                </div>

                <!-- Start Add form dialog -->
                <dialog-base :type="(isUpdate && 'UPDATE') || 'ADD'" v-model="formDialog" @canceled="formDialogClose" @confirmed="validateAndSave">
                    <template #content>
                        <validation-observer ref="branchesForm" v-slot="{ handleSubmit }">
                            <v-form class="px-3" @submit.prevent="handleSubmit(validateAndSave)">
                                <!-- show errors if there is errors when add/update item -->
                                <has-errors :errors="errors"></has-errors>

                                <v-row>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|min:3|max:32" :name="$t('attributes.name')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="branch.name"
                                                :label="$t('attributes.name')"
                                                counter
                                                :maxlength="32"
                                                :error-messages="errors"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|select_object" :name="$t('attributes.city')" v-slot="{ errors }">
                                            <cities-field v-model="branch.city" :error-messages="errors" />
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|phone" :name="$t('attributes.phone')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="branch.phone"
                                                :label="$t('attributes.phone')"
                                                counter
                                                :maxlength="10"
                                                :error-messages="errors"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="phone" :name="$t('attributes.phone')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="branch.phone2"
                                                :label="$t('attributes.phone')"
                                                counter
                                                :maxlength="10"
                                                :error-messages="errors"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|email" :name="$t('attributes.email')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="branch.email"
                                                :label="$t('attributes.email')"
                                                counter
                                                type="email"
                                                :error-messages="errors"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider rules="required|min:9|max:64" :name="$t('attributes.address')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="branch.address"
                                                :label="$t('attributes.address')"
                                                counter
                                                :maxlength="64"
                                                :error-messages="errors"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-switch v-model="branch.active" :label="$t('attributes.active')"></v-switch>
                                    </v-col>
                                </v-row>

                                <!-- This button to allow to user to submit form by clicking enter -->
                                <button type="submit" class="hide"></button>
                            </v-form>
                        </validation-observer>
                    </template>
                </dialog-base>
                <!-- End Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>
                <!-- Start show headers options -->
                <div class="px-4">
                    <v-select v-model="selected_headers" :items="headers" :label="$t('options.show_columns')" multiple return-object>
                        <template v-slot:selection="{ item }">
                            <v-chip filter>
                                <span>{{ item.text }}</span>
                            </v-chip>
                        </template>
                    </v-select>
                </div>
                <!-- End show headers options -->

                <v-divider></v-divider>

                <!-- Start search options -->
                <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                    <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                        <v-row align="center">
                            <v-col cols="12" sm="6" lg="3" class="pb-0" v-for="(field, index) in searchFields" :key="index">
                                <v-text-field v-model.trim="search[field]" :label="$t(`attributes.${field}`)" maxlength="32" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3" class="pb-0">
                                <validation-provider rules="select_object" :name="$t('attributes.city')" v-slot="{ errors }">
                                    <cities-field v-model="search.city" clearable :error-messages="errors" />
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                <v-btn class="me-2" :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </validation-observer>
                <!-- End search options -->

                <v-divider></v-divider>

                <v-data-table
                    :headers="selected_headers"
                    :items="data.data"
                    :items-per-page="100"
                    hide-default-footer
                    class="elevation-1"
                    :item-class="rowClass"
                >
                    <template v-slot:item.actions="{ item }">
                        <div style="width: 40px">
                            <v-btn x-small fab color="info" dark @click="prepareToUpdateItem(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>

                <!-- Start pagination -->
                <pagination :data="data.meta || {}" @page-changed="filterData" />
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";
import CitiesField from "@/components/data/CitiesField.vue";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;

    store.dispatch("branch/fetchAll", { page, params }).then(() => {
        next();
    });
}

export default {
    components: { CitiesField },

    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    data: function() {
        return {
            formDialog: false,
            branch: {},
            errors: {},
            search: Object.assign({}, this.$route.query),
            searchFields: ["id", "name", "phone", "email", "address"],
            selected_headers: [],
            headers: [
                { text: this.$t("attributes.id"), value: "id" },
                { text: this.$t("attributes.name"), value: "name" },
                { text: this.$t("attributes.city"), value: "city.name" },
                { text: this.$t("attributes.phone"), value: "phone" },
                { text: this.$t("attributes.phone2"), value: "phone2" },
                { text: this.$t("attributes.email"), value: "email" },
                { text: this.$t("attributes.address"), value: "address" },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.updated_at"), value: "updated_at" },
                { text: this.$t("attributes.actions"), value: "actions" }
            ]
        };
    },

    computed: {
        ...mapState({
            data: state => state.branch.branches
        }),
        isUpdate() {
            return !!this.branch.id;
        }
    },

    created() {
        this.selected_headers = this.headers.filter(c => !["address", "created_at", "updated_at"].includes(c.value));
    },

    methods: {
        ...mapActions("branch", ["add", "update"]),

        /**
         * Validate form if valid then call save method to send request to server
         */
        validateAndSave() {
            this.$refs.branchesForm.validate().then(success => {
                if (!success) return;
                this.save();
            });
        },

        /**
         * Add data to server
         */
        save() {
            this.$store.state.app.loading = true;
            this.errors = {};

            // I took copy of object becuase I will change in properties of object and I do not want it effect in data that user insert them
            let branch = Object.assign({}, this.branch);
            branch.city_id = branch.city && branch.city.id;
            delete branch.city;

            (this.isUpdate ? this.update(branch) : this.add(branch))
                .then(() => {
                    this.formDialogClose();
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Close form dialog which responsible on add/delete data
         */
        formDialogClose() {
            this.formDialog = false;
            this.errors = {};
            this.$refs.branchesForm.reset();
            this.branch = {};
        },

        /**
         *  Set configuration for Add item
         */
        prepareToAddItem() {
            this.branch = { active: false };
            this.formDialog = true;
        },

        /**
         *  Set configuration for update item
         */
        prepareToUpdateItem(item) {
            this.formDialog = true;
            this.branch = Object.assign({}, item);
        },

        rowClass(item) {
            return !item.active && "red white--text";
        },

        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            params.city = params.city && params.city.id;
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        }
    }
};
</script>
