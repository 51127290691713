import returningApi from "@/apis/shipmentReturnedToCustomerApi";

function formatData(item, rootGetters) {
    item.shipments && item.shipments.map(s => (s.state = rootGetters["shipment/getStateById"](s.state)));
    return item;
}

export default {
    namespaced: true,

    state: {
        items: [],
        item: {}
    },

    mutations: {
        ADD(state, item) {
            if (state.items.data) {
                state.items.data.unshift(item);
                state.items.meta.to++;
                state.items.meta.total++;
            }
        },
        UPDATE(state, item) {
            if (state.items.data) {
                state.items.data = state.items.data.map(c => (c.id !== item.id ? c : item));
            }
        },
        SET_ITEMS(state, items) {
            state.items = items;
        },
        SET_ITEM(state, item) {
            state.item = item;
        }
    },

    actions: {
        add({ commit }, item) {
            return returningApi.create(item).then(r => {
                Toast.success("Added successfully");
                commit("ADD", r.data.data);
                return Promise.resolve(r);
            });
        },

        fetchAll({ commit }, { page = 1, params = {} }) {
            return returningApi.getAll(page, params).then(r => {
                commit("SET_ITEMS", r.data);
            });
        },

        fetchById({ commit, rootGetters }, id) {
            return returningApi.getById(id).then(r => {
                commit("SET_ITEM", formatData(r.data.data, rootGetters));
                return Promise.resolve(r);
            });
        },

        update({ commit }, item) {
            return returningApi.update(item).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE", r.data.data);
                return Promise.resolve(r);
            });
        }
    }
};
