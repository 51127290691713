import i18n from "@/plugins/i18n.js";

/**
 * This module for app settings
 */
export default {
    namespaced: true,

    state: {
        layouts: {
            dashboard: {
                sidebar: {
                    show: true,
                    links: [
                        { label: i18n.t("home.title"), icon: i18n.t("home.icon"), url: "/home" },
                        { label: i18n.t("financial.title"), icon: i18n.t("financial.icon"), url: "/financial" },
                        { label: i18n.t("shipments.title"), icon: i18n.t("shipments.icon"), url: "/shipments" },
                        { label: i18n.t("trips.title"), icon: i18n.t("trips.icon"), url: "/trips" },
                        { label: i18n.t("adjustment_customers.title"), icon: i18n.t("adjustment_customers.icon"), url: "/adjustment-customers" },
                        {
                            label: i18n.t("adjustment_customers.available_balance.title"),
                            icon: i18n.t("adjustment_customers.available_balance.icon"),
                            url: "/adjustment-customers-available-balance"
                        },
                        {
                            label: i18n.t("shipments_returned_to_customers.title"),
                            icon: i18n.t("shipments_returned_to_customers.icon"),
                            url: "/shipments-returned-to-customers"
                        },
                        { label: i18n.t("users.title"), icon: i18n.t("users.icon"), url: "/users" },
                        { label: i18n.t("customers.title"), icon: i18n.t("customers.icon"), url: "/customers" },
                        { label: i18n.t("delivers.title"), icon: i18n.t("delivers.icon"), url: "/delivers" },
                        { label: i18n.t("branches.title"), icon: i18n.t("branches.icon"), url: "/branches" },
                        {
                            label: i18n.t("cities.title"),
                            icon: i18n.t("cities.icon"),
                            group: true,
                            links: [
                                { label: i18n.t("shipping_routes.title"), icon: i18n.t("shipping_routes.icon"), url: "/shipping-routes" },
                                { label: i18n.t("cities.title"), icon: i18n.t("cities.icon"), url: "/cities" }
                            ]
                        },
                        {
                            label: i18n.t("expenses.title"),
                            icon: i18n.t("expenses.icon"),
                            group: true,
                            links: [
                                { label: i18n.t("type_of_expenses.title"), icon: i18n.t("type_of_expenses.icon"), url: "/type-expenses" },
                                { label: i18n.t("expenses.title"), icon: i18n.t("expenses.icon"), url: "/expenses" }
                            ]
                        },
                        {
                            label: i18n.t("revenues.title"),
                            icon: i18n.t("revenues.icon"),
                            group: true,
                            links: [
                                { label: i18n.t("type_of_revenues.title"), icon: i18n.t("type_of_revenues.icon"), url: "/type-revenues" },
                                { label: i18n.t("revenues.title"), icon: i18n.t("revenues.icon"), url: "/revenues" }
                            ]
                        }
                    ]
                }
            }
        },

        color: "info",
        dark: false,
        loading: true
    }
};
