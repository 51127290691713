import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "./plugins/helpers";
import "./plugins/filters";
import "./plugins/axios";
import "./plugins/global-components";
import "./plugins/vee-validate";
import "./plugins/sweetalert";
import moment from "moment";

window.moment = moment;

Vue.config.productionTip = false;

store.dispatch("auth/checkIfUserAuthenticated").finally(() => {
    window.__app = new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App)
    }).$mount("#app");
});
