<template>
    <v-combobox
        :items="data || []"
        item-text="name"
        :value="value"
        :label="label || $t('attributes.shipping_route')"
        @input="$emit('input', $event)"
        :clearable="clearable"
        :outlined="outlined"
        :error-messages="errorMessages"
        :append-outer-icon="appendOuterIcon"
        @click:append-outer="$emit('click:append-outer', $event)"
        :prepend-icon="prependIcon"
        :append-icon="appendIcon"
        :prepend-inner-icon="prependInnerIcon"
        :append-inner-icon="appendInnerIcon"
    ></v-combobox>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "ShippingRoutesField",

    props: {
        value: [String, Array, Object],
        label: { type: String },
        errorMessages: [String, Array],
        clearable: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false },
        appendOuterIcon: [String],
        prependIcon: [String],
        appendIcon: [String],
        prependInnerIcon: [String],
        appendInnerIcon: [String]
    },

    computed: {
        ...mapState({
            data: state => state.shippingRoute.routesShort
        })
    },
    created() {
        this.$store.dispatch("shippingRoute/fetchRoutesShort");
    }
};
</script>
