<template>
    <base-card class="my-4 pt-5 mx-auto" :icon="$t('customers.products.icon')">
        <template #title>
            <div class="d-flex justify-space-between">
                <div class="headline">{{ $t("customers.products.title") }}</div>

                <!-- btn add new item  -->
                <v-btn fab small :color="$store.state.app.color" @click.stop="prepareToAddItem"><v-icon>mdi-plus</v-icon></v-btn>
            </div>

            <!-- Start Add form dialog -->
            <dialog-base
                :type="(product.id && 'UPDATE') || 'ADD'"
                v-model="formDialog"
                @canceled="formDialogClose"
                @confirmed="validateAndSave"
                max-width="450"
            >
                <template #content>
                    <validation-observer ref="productsForm" v-slot="{ handleSubmit }">
                        <v-form class="px-3" @submit.prevent="handleSubmit(validateAndSave)">
                            <!-- show errors if there is errors when add/update item -->
                            <has-errors :errors="errors"></has-errors>

                            <v-row>
                                <v-col cols="12">
                                    <validation-provider rules="required|min:3|max:32" :name="$t('attributes.name')" v-slot="{ errors }">
                                        <v-text-field
                                            v-model.trim="product.name"
                                            :label="$t('attributes.name')"
                                            :error-messages="errors"
                                            counter
                                            :maxlength="32"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12">
                                    <validation-provider rules="max:100" :name="$t('attributes.note')" v-slot="{ errors }">
                                        <v-textarea
                                            v-model.trim="product.note"
                                            :label="$t('attributes.note')"
                                            :error-messages="errors"
                                            counter
                                            :maxlength="100"
                                            rows="1"
                                            auto-grow
                                        ></v-textarea>
                                    </validation-provider>
                                </v-col>
                            </v-row>

                            <!-- This button to allow to user to submit form by clicking enter -->
                            <button type="submit" class="hide"></button>
                        </v-form>
                    </validation-observer>
                </template>
            </dialog-base>
            <!-- End Add form dialog -->
        </template>

        <template #content>
            <v-divider></v-divider>

            <!-- Start search options -->
            <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                    <v-row align="center">
                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field v-model.trim="search.name" :label="$t('attributes.name')" clearable></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" lg="auto" class="mb-2">
                            <v-btn class="me-2" :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </validation-observer>
            <!-- End search options -->

            <v-divider></v-divider>

            <v-data-table :headers="headers" :items="data.data" :items-per-page="100" hide-default-footer class="elevation-1">
                <template v-slot:item.actions="{ item }">
                    <div style="width: 40px">
                        <v-btn x-small fab color="info" dark @click="prepareToUpdateItem(item)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-data-table>

            <!-- Start pagination -->
            <pagination :data="data.meta || {}" @page-changed="filterData" />
            <!-- End pagination -->
        </template>
    </base-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";

export default {
    data: function() {
        return {
            formDialog: false,
            errors: {},
            product: {},
            search: this.$route.query,
            headers: [
                { text: this.$t("attributes.name"), value: "name" },
                { text: this.$t("attributes.note"), value: "note" },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions" }
            ]
        };
    },

    computed: {
        ...mapState({
            data: state => state.customerProduct.products
        })
    },

    created() {
        this.fetchData(this.$route);
    },

    methods: {
        ...mapActions("customerProduct", ["addProduct", "updateProduct"]),

        /**
         * Validate form if valid then call save method to send request to server
         */
        validateAndSave() {
            this.$refs.productsForm.validate().then(success => {
                if (!success) {
                    return;
                }
                this.save();
            });
        },

        /**
         * Add data to server
         */
        save() {
            this.$store.state.app.loading = true;
            this.errors = {};

            // I took copy of object becuase I will change in properties of object and I do not want it effect in data that user insert them
            //let customer = Object.assign({}, this.customer);

            this.product.customer_id = this.$route.params.id;

            (this.product.id ? this.updateProduct(this.product) : this.addProduct(this.product))
                .then(() => {
                    this.formDialogClose();
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                })
                .catch(e => {
                    window.eee = e;
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Close form dialog which responsible on add/delete data
         */
        formDialogClose() {
            this.formDialog = false;
            this.errors = {};
            this.$refs.productsForm.reset();
            this.product = {};
        },

        /**
         *  Set configuration for Add item
         */
        prepareToAddItem() {
            this.product = {};
            this.formDialog = true;
        },

        /**
         *  Set configuration for update item
         */
        prepareToUpdateItem(item) {
            this.formDialog = true;
            this.product = Object.assign({}, item);
        },

        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params, page } }).catch(e => {
                window.eee = e;
            });
        },

        fetchData(router) {
            let page = router.query.page || 1;
            let params = { ...router.query, customer_id: router.params.id };
            return store.dispatch("customerProduct/fetchProducts", { page, params });
        }
    }
};
</script>
