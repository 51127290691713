<template>
    <div>
        <!-- Start statistics -->
        <v-row>
            <v-col cols="12" sm="6" lg="3" v-for="(item, index) in statisticalData" :key="`cardStats${index}`">
                <card-stats
                    color="blue-grey"
                    :icon="item.icon"
                    :icon-to="item.iconTo"
                    :count="item.count"
                    :title="item.title"
                    :description="item.description"
                    :description-icon="item.descIcon"
                ></card-stats>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6" lg="3" v-for="(item, index) in statistics.shipments" :key="`cardStatsShipments${index}`">
                <card-stats
                    :color="getState(item.state).color"
                    :count="item.count"
                    :icon="$t('home.statistics.shipments.icon')"
                    :icon-to="`/shipments?state=${item.state}`"
                    :title="$t(`attributes.shipment_statuses.${item.state}`)"
                    :description="$t('home.statistics.shipments.desc')"
                ></card-stats>
            </v-col>
        </v-row>
        <!-- End statistics -->
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CardStats from "@/components/global/CardStats";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(next) {
    store.dispatch("statistic/fetchData").then(() => next());
}

export default {
    name: "Home",

    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(next);
    },

    components: { CardStats },

    computed: {
        ...mapState("statistic", ["statistics", "charts"]),
        ...mapGetters("shipment", ["getStateById"]),

        getState: function() {
            return id => this.getStateById(id);
        },

        statisticalData: function() {
            return [
                {
                    title: this.$t("home.statistics.available_balance.title"),
                    icon: this.$t("home.statistics.available_balance.icon"),
                    iconTo: "/adjustment-customers-available-balance",
                    description: this.$t("home.statistics.available_balance.desc"),
                    count: `$${Number(this.statistics.available_balance).toLocaleString()}`
                },
                {
                    title: this.$t("home.statistics.closed_balance.title"),
                    icon: this.$t("home.statistics.closed_balance.icon"),
                    iconTo: "",
                    description: this.$t("home.statistics.closed_balance.desc"),
                    count: `$${Number(this.statistics.closed_balance).toLocaleString()}`
                },
                {
                    title: this.$t("home.statistics.shipments.title"),
                    icon: this.$t("home.statistics.shipments.icon"),
                    iconTo: "/shipments",
                    description: this.$t("home.statistics.shipments.desc"),
                    count: (this.statistics.shipments && this.statistics.shipments.reduce((sum, s) => sum + Number(s.count), 0)) || 0
                },
                {
                    title: this.$t("home.statistics.trips.title"),
                    icon: this.$t("home.statistics.trips.icon"),
                    iconTo: "/trips",
                    description: this.$t("home.statistics.trips.desc"),
                    count: this.statistics.trips
                },
                {
                    title: this.$t("home.statistics.customers.title"),
                    icon: this.$t("home.statistics.customers.icon"),
                    iconTo: "/customers",
                    description: this.$t("home.statistics.customers.desc"),
                    count: this.statistics.customers || 0
                },
                {
                    title: this.$t("home.statistics.delivers.title"),
                    icon: this.$t("home.statistics.delivers.icon"),
                    iconTo: "/delivers",
                    description: this.$t("home.statistics.delivers.desc"),
                    count: this.statistics.delivers || 0
                },
                {
                    title: this.$t("home.statistics.users.title"),
                    icon: this.$t("home.statistics.users.icon"),
                    iconTo: "/users",
                    description: this.$t("home.statistics.users.desc"),
                    count: this.statistics.users || 0
                },
                {
                    title: this.$t("home.statistics.cities.title"),
                    icon: this.$t("home.statistics.cities.icon"),
                    iconTo: "/cities",
                    description: this.$t("home.statistics.cities.desc"),
                    count: this.statistics.cities || 0
                },
                {
                    title: this.$t("home.statistics.shipping_routes.title"),
                    icon: this.$t("home.statistics.shipping_routes.icon"),
                    iconTo: "/shipping-routes",
                    description: this.$t("home.statistics.shipping_routes.desc"),
                    count: this.statistics.shipping_routes || 0
                }
            ];
        }
    }
};
</script>
