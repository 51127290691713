import deliverApi from "@/apis/deliverApi";

/**
 * Add short deliver object
 * This function it returns only properties that shortDelivers needs them
 * @returns {object} deliver
 */
function shortDeliver(deliver) {
    return {
        id: deliver.id,
        name: deliver.name
    };
}

export default {
    namespaced: true,

    state: {
        delivers: [],
        deliversShort: [],
        deliver: {}
    },

    getters: {
        getById: state => id => {
            return state.deliversShort.length && state.deliversShort.find(item => item.id === id);
        }
    },

    mutations: {
        ADD_DELIVER(state, deliver) {
            if (state.delivers.data) {
                state.delivers.data.unshift(deliver);
                state.delivers.meta.to++;
                state.delivers.meta.total++;
            }
            state.deliversShort.push(shortDeliver(deliver));
        },
        UPDATE_DELIVER(state, deliver) {
            state.delivers.data = [...state.delivers.data.map(c => (c.id !== deliver.id ? c : deliver))];
            let index = state.deliversShort.findIndex(e => e.id === deliver.id);
            state.deliversShort[index] = shortDeliver(deliver);
        },
        SET_DELIVERS(state, delivers) {
            state.delivers = delivers;
        },
        SET_DELIVERS_SHORT(state, delivers) {
            state.deliversShort = delivers;
        },
        SET_DELIVER(state, deliver) {
            state.deliver = deliver;
        },
        DELETE_DELIVER(state, id) {
            if (state.delivers.data) {
                state.delivers.data = state.delivers.data.filter(item => item.id !== id);
                state.delivers.meta.to--;
                state.delivers.meta.total--;
            }
        }
    },

    actions: {
        addDeliver({ commit }, deliver) {
            return deliverApi.create(deliver).then(r => {
                Toast.success("Added successfully");
                commit("ADD_DELIVER", r.data.data);
            });
        },

        fetchDelivers({ commit }, { page = 1, params = {} }) {
            return deliverApi.getAll(page, params).then(r => {
                commit("SET_DELIVERS", r.data);
            });
        },

        fetchDeliversShort({ commit, state }) {
            if (!state.deliversShort.length) {
                return deliverApi.getAllShort().then(r => {
                    commit("SET_DELIVERS_SHORT", r.data.data);
                });
            }
        },

        fetchDeliver({ commit }, id) {
            return deliverApi.getById(id).then(r => {
                commit("SET_DELIVER", r.data.data);
            });
        },

        updateDeliver({ commit }, deliver) {
            return deliverApi.update(deliver).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE_DELIVER", r.data.data);
            });
        }
    }
};
