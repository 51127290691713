import Axios from "axios";

const base_url = "cp/shipments/comments";

export default {
    /**
     * Get Shipment comments by shipment id.
     *
     * @param {string} type type of comment
     * @param {int} shipment id of shipment
     * @returns {Promise}
     */
    getByShipmentId(type, shipment_id) {
        if (!["private", "public"].includes(type)) {
            throw Error(`Comments type ${type} not exits`);
        }
        return Axios.get(`${base_url}/${type}`, { params: { shipment_id } });
    },

    /**
     * Store a new Shipment comment in server.
     *
     * @param {string} type type of comment
     * @param {object} data item properties
     * @returns {Promise}
     */
    create(type, data) {
        if (!["private", "public"].includes(type)) {
            throw Error(`Comments type ${type} not exits`);
        }
        return Axios.post(`${base_url}/${type}`, data);
    },

    /**
     * Remove the specified Shipment comment from server.
     *
     * @param {int} id
     * @returns {Promise}
     */
    delete(type, shipment_id, id) {
        return Axios.delete(`${base_url}/${type}/${shipment_id}/${id}`);
    }
};
