<template>
    <base-card :color="color" :icon="icon" :icon-to="iconTo">
        <template #title>
            <div class="subtitle-1 font-weight-light">{{ title }}</div>

            <div class="headline">{{ count }}</div>
        </template>

        <template #content>
            <v-card-text class="pt-0">
                <v-divider class="mb-2"></v-divider>
                <v-icon class="me-1">{{ descriptionIcon }}</v-icon>
                <span class="caption grey--text text--darken-1 font-weight-light">{{ description }}</span>
            </v-card-text>
        </template>
    </base-card>
</template>

<script>
export default {
    name: "CardStats",
    props: {
        color: { type: String, required: true },
        icon: { type: String, required: true },
        iconTo: { type: [String, Object], default: "" },
        title: { type: String, required: true },
        count: { type: [String, Number], required: true },
        descriptionIcon: { type: String, default: "mdi-information-outline" },
        description: { type: String, required: true }
    }
};
</script>
