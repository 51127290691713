import Axios from "axios";

export default {
    /**
     * Bring statistical data from server
     *
     * @returns {Promise} Response of request
     */
    getData() {
        return Axios.get("cp/statistics");
    }
};
