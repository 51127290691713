import Axios from "axios";

export default {
    /**
     * Bring financial data from server
     *
     * @param {object} params filter (search) options
     * @returns {Promise}
     */
    getData(params) {
        return Axios.get("cp/financial", { params });
    }
};
