<template>
    <div>
        <breadcrumbs :items="breadcrumbs"></breadcrumbs>

        <base-card class="my-4 mx-auto" :icon="$t('shipments.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline card-main-title">{{ isUpdate ? shipment.code : $t("options.add") }}</div>
                </div>

                <!-- Start Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>

                <validation-observer ref="shipmentsForm" v-slot="{ handleSubmit }">
                    <v-form class="px-3" @submit.prevent="handleSubmit(save)">
                        <!-- show errors if there is errors when add/update item -->
                        <has-errors :errors="errors"></has-errors>

                        <v-row class="pt-5">
                            <!-- Start Sender data -->
                            <v-col cols="12">
                                <app-header :label="$t('attributes.sender')" :prepend-inner-icon="$t('common.icons.sender')"></app-header>
                            </v-col>

                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="numeric|min:1" :name="$t('attributes.customer')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        clearable
                                        v-model.trim="shipment.customer"
                                        :label="$t('attributes.customer')"
                                        :error-messages="errors"
                                        counter
                                        type="number"
                                        append-icon="mdi-magnify"
                                        @click:append="bringCustomer"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|min:3|max:32" :name="$t('attributes.sender_name')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        v-model.trim="shipment.sender_name"
                                        :readonly="isCustomer"
                                        :label="$t('attributes.sender_name')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="32"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|phone" :name="$t('attributes.sender_phone')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        :readonly="isCustomer"
                                        v-model.trim="shipment.sender_phone"
                                        :label="$t('attributes.sender_phone')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="10"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="phone" :name="$t('attributes.sender_phone2')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        :readonly="isCustomer"
                                        v-model.trim="shipment.sender_phone2"
                                        :label="$t('attributes.sender_phone2')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="10"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <!-- End Sender data -->

                            <!-- Start Receiver data -->
                            <v-col cols="12">
                                <app-header :label="$t('attributes.receiver')" :prepend-inner-icon="$t('common.icons.receiver')"></app-header>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="min:3|max:32" :name="$t('attributes.receiver_name')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        v-model.trim="shipment.receiver_name"
                                        :label="$t('attributes.receiver_name')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="32"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|phone" :name="$t('attributes.receiver_phone')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        v-model.trim="shipment.receiver_phone"
                                        :label="$t('attributes.receiver_phone')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="10"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="phone" :name="$t('attributes.receiver_phone2')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        v-model.trim="shipment.receiver_phone2"
                                        :label="$t('attributes.receiver_phone2')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="10"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|min:3|max:64" :name="$t('attributes.receiver_address')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        v-model.trim="shipment.receiver_address"
                                        :label="$t('attributes.receiver_address')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="64"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <!-- End Receiver data -->

                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|select_object" :name="$t('attributes.branch')" v-slot="{ errors }">
                                    <branches-field outlined v-model="shipment.branch" :error-messages="errors" />
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|select_object" :name="$t('attributes.city')" v-slot="{ errors }">
                                    <cities-field outlined v-model="shipment.city" :error-messages="errors" @input="cityChanged" />
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|numeric|min:0" :name="$t('attributes.price')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        v-model.trim="shipment.price"
                                        :label="$t('attributes.price')"
                                        :error-messages="errors"
                                        counter
                                        type="number"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|numeric|min:0" :name="$t('attributes.fee')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        v-model.trim="shipment.fee"
                                        :label="$t('attributes.fee')"
                                        :error-messages="errors"
                                        counter
                                        type="number"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|numeric|min:0" :name="$t('attributes.deliver_fee')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        v-model.trim="shipment.deliver_fee"
                                        :label="$t('attributes.deliver_fee')"
                                        :error-messages="errors"
                                        counter
                                        type="number"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" sm="6" lg="3" v-if="!isWaitingAccept">
                                <validation-provider
                                    rules="required|date|before_or_equal:today"
                                    :name="$t('attributes.received_at')"
                                    v-slot="{ errors }"
                                >
                                    <date-field
                                        outlined
                                        v-model="shipment.received_at"
                                        :label="$t('attributes.received_at')"
                                        :error-messages="errors"
                                    ></date-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required" :name="$t('attributes.state')" v-slot="{ errors }">
                                    <shipment-statuses-field outlined v-model="shipment.state" :error-messages="errors" @input="statusChanged" />
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-checkbox v-model="shipment.fee_on_customer" :label="$t('attributes.fee_on_customer')"></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider rules="max:200" :name="$t('attributes.note')" v-slot="{ errors }">
                                    <v-textarea
                                        v-model.trim="shipment.note"
                                        :label="$t('attributes.note')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="200"
                                        rows="1"
                                        outlined
                                        clearable
                                        auto-grow
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider rules="max:200" :name="$t('attributes.extra')" v-slot="{ errors }">
                                    <v-textarea
                                        v-model.trim="shipment.extra"
                                        :label="$t('attributes.extra')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="200"
                                        rows="1"
                                        outlined
                                        clearable
                                        auto-grow
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>
                        </v-row>

                        <v-divider class="mb-5"></v-divider>

                        <!-- Start shipment items -->
                        <app-header :label="$t('attributes.shipment_items')" :prepend-inner-icon="$t('shipments.icon')"></app-header>

                        <div class="mt-5" v-for="(item, index) in shipment.items" :key="`Sitems${index}`">
                            <shipment-item
                                v-model="shipment.items[index]"
                                :products="customerProducts || []"
                                @input="updateShipmentItem"
                                :index="index"
                                @add-click="addShipmentItem"
                                @delete-click="deleteShipmentItem"
                            ></shipment-item>
                        </div>
                        <!-- End shipment items -->

                        <div class="text-end px-5 py-3">
                            <v-btn color="info" min-width="150px" type="submit">{{ $t("options.save") }}</v-btn>
                        </div>
                    </v-form>
                </validation-observer>
                <!-- End Add form dialog -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";
import CitiesField from "@/components/data/CitiesField.vue";
import BranchesField from "@/components/data/BranchesField.vue";
import ShipmentStatusesField from "@/components/data/ShipmentStatusesField.vue";
import ShipmentItem from "@/components/data/ShipmentItem.vue";
import CustomerApi from "@/apis/customerApi";

export default {
    components: { ShipmentStatusesField, CitiesField, BranchesField, ShipmentItem },
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        let id = routeTo.params.id;
        if (id) {
            store.dispatch("shipment/fetchShipment", id).then(() => {
                next();
            });
        } else {
            next();
        }
    },
    data: function() {
        return {
            shipmentName: "",
            shipment: {},
            customerProducts: [],
            errors: {}
        };
    },
    computed: {
        ...mapState({
            data: state => state.shipment.shipment,
            statuses: state => state.shipment.statuses
        }),

        breadcrumbs() {
            return [
                {
                    text: this.$t("shipments.title"),
                    to: "/shipments",
                    exact: true,
                    disabled: false
                },
                {
                    text: this.$t(`options.${this.isUpdate ? "update" : "add"}`),
                    exact: true,
                    disabled: true,
                    to: "/shipments/create"
                }
            ];
        },

        isUpdate() {
            return !!this.shipment.id;
        },
        isCustomer() {
            return !!this.shipment.customer;
        },
        isWaitingAccept() {
            return this.shipment.state == "waiting_accept";
        }
    },

    created() {
        this.shipment = (this.$route.params.id && this.data) || { fee_on_customer: false };
        if (this.shipment.id) {
            this.customer = this.shipment.customer;
            this.customerProducts = this.shipment.customer && this.shipment.customer.products;
            this.shipment.customer = this.shipment.customer_id;
            this.shipment.sender_name = this.shipment.sender.name;
            this.shipment.sender_phone = this.shipment.sender.phone;
            this.shipment.sender_phone2 = this.shipment.sender.phone2;

            this.shipment.state = this.shipment.state.id;

            this.shipment.updated_items = []; // to use it to save updated items
            this.shipment.deleted_items = []; // to use it to save deleted items
        }

        if (!this.shipment.items || !this.shipment.items.length) {
            this.addShipmentItem();
        }
    },

    methods: {
        ...mapActions("shipment", ["addShipment", "updateShipment"]),
        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        },

        /**
         * Add data to server
         */
        save() {
            this.errors = {};
            let shipment = Object.assign({}, this.shipment);
            shipment.city_id = shipment.city.id;
            shipment.branch_id = shipment.branch.id;
            delete shipment.branch;
            delete shipment.city;

            if (this.isUpdate) {
                // save new items in seprate array , any element dose not have id
                shipment.new_items = shipment.items.filter(i => !i.id);
            }

            this.$store.state.app.loading = true;
            (shipment.id ? this.updateShipment(shipment) : this.addShipment(shipment))
                .then(r => {
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                    this.$router.push({ path: `/shipments/${r.data.data.id}` });
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        cityChanged(city) {
            if (!city) return;
            this.shipment.fee = city.fee;
            this.shipment.deliver_fee = city.deliver_fee;
            this.shipment = { ...this.shipment };
        },

        statusChanged() {
            if (this.isWaitingAccept || this.shipment.received_at) return;
            this.shipment.received_at = _getCurrentDate();
        },

        bringCustomer() {
            if (!this.shipment.customer) return;

            this.$store.state.app.loading = true;

            CustomerApi.getById(this.shipment.customer, true)
                .then(r => {
                    let customer = r.data.data;
                    this.customerProducts = customer.products;
                    this.shipment = {
                        ...this.shipment,
                        sender_name: customer.name,
                        sender_phone: customer.phone,
                        sender_phone2: customer.phone2
                    };
                })
                .catch(() => {
                    this.customerProducts = [];
                    this.shipment.sender_name = null;
                    this.shipment.sender_phone = null;
                    this.shipment.sender_phone2 = null;
                })
                .finally(() => {
                    this.$store.state.app.loading = false;
                });
        },

        /**
         * Add empty item to shipment
         */
        addShipmentItem() {
            if (!this.shipment.items) {
                this.shipment.items = [];
            }
            this.shipment.items.push({ customer_product_id: null, count: 1, desc: "" });
        },

        /**
         * Updated item of items shipment
         */
        updateShipmentItem(item) {
            if (!item.id) return;
            let index = this.shipment.updated_items.findIndex(i => i.id == item.id);
            if (index > -1) {
                this.shipment.updated_items[index] = item;
            } else {
                this.shipment.updated_items.push(item);
            }
        },

        /**
         * Delete item from shipment
         */
        deleteShipmentItem(item) {
            if (item.id) {
                this.shipment.deleted_items.push(item.id);
                this.shipment.updated_items = this.shipment.updated_items.filter(e => e.id != item.id);
            }

            this.shipment.items = this.shipment.items.filter((e, i) => i != item.index);

            if (this.shipment.items.length == 0) {
                this.addShipmentItem();
            }
        }
    }
};
</script>
