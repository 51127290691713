<template>
    <div>
        <breadcrumbs :items="breadcrumbs"></breadcrumbs>

        <base-card class="my-4 mx-auto" :icon="$t('trips.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline card-main-title">{{ isUpdate ? trip.id : $t("options.add") }}</div>
                </div>

                <!-- Start Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>

                <validation-observer ref="tripsForm" v-slot="{ handleSubmit }">
                    <v-form class="px-3" @submit.prevent="handleSubmit(save)">
                        <!-- show errors if there is errors when add/update item -->
                        <has-errors :errors="errors"></has-errors>

                        <!-- Start Trip data -->
                        <v-row class="pt-5">
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|select_object" :name="$t('attributes.deliver')" v-slot="{ errors }">
                                    <delivers-field outlined v-model="trip.deliver" :error-messages="errors" />
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|select_object" :name="$t('attributes.branch')" v-slot="{ errors }">
                                    <branches-field
                                        outlined
                                        v-model="trip.branch"
                                        :error-messages="errors"
                                        append-outer-icon="mdi-magnify"
                                        @input="shippingRoutesChanged"
                                    />
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|select_object" :name="$t('attributes.shipping_route')" v-slot="{ errors }">
                                    <shipping-routes-field
                                        outlined
                                        v-model="trip.shipping_route"
                                        :error-messages="errors"
                                        append-outer-icon="mdi-magnify"
                                        @click:append-outer="bringShipments"
                                        @input="shippingRoutesChanged"
                                    />
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required" :name="$t('attributes.state')" v-slot="{ errors }">
                                    <trip-statuses-field
                                        outlined
                                        v-model="trip.state"
                                        :error-messages="errors"
                                        :exclude-options="!isUpdate && ['closed']"
                                    />
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|numeric|min:0" :name="$t('attributes.amount')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        v-model.trim="trip.amount"
                                        :label="$t('attributes.amount')"
                                        :error-messages="errors"
                                        counter
                                        type="number"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|numeric|min:0" :name="$t('attributes.deliver_fee')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        v-model="trip.deliver_fee"
                                        :label="$t('attributes.deliver_fee')"
                                        :error-messages="errors"
                                        counter
                                        readonly
                                        type="number"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider
                                    rules="required|date|before_or_equal:today"
                                    :name="$t('attributes.start_date')"
                                    v-slot="{ errors }"
                                    vid="start_date"
                                >
                                    <date-field
                                        outlined
                                        v-model="trip.start_date"
                                        :label="$t('attributes.start_date')"
                                        :error-messages="errors"
                                    ></date-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider
                                    rules="required|date|after_or_equal:@start_date"
                                    :name="$t('attributes.end_date')"
                                    v-slot="{ errors }"
                                >
                                    <date-field
                                        outlined
                                        v-model="trip.end_date"
                                        :label="$t('attributes.end_date')"
                                        :error-messages="errors"
                                    ></date-field>
                                </validation-provider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider rules="max:200" :name="$t('attributes.note')" v-slot="{ errors }">
                                    <v-textarea
                                        v-model.trim="trip.note"
                                        :label="$t('attributes.note')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="200"
                                        rows="1"
                                        outlined
                                        clearable
                                        auto-grow
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider rules="max:200" :name="$t('attributes.extra')" v-slot="{ errors }">
                                    <v-textarea
                                        v-model.trim="trip.extra"
                                        :label="$t('attributes.extra')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="200"
                                        rows="1"
                                        outlined
                                        clearable
                                        auto-grow
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <!-- End Trip data -->

                        <v-divider></v-divider>

                        <div class="text-end px-5 py-3">
                            <v-btn color="info" min-width="150px" type="submit">{{ $t("options.save") }}</v-btn>
                        </div>
                    </v-form>
                </validation-observer>
                <!-- End Add form dialog -->
            </template>
        </base-card>

        <!-- Start Shipment -->
        <template v-if="canUpdateShipments">
            <!-- Start selected shipment -->
            <v-card class="my-8">
                <v-card-title primary-title>{{ $t("attributes.selected_shipments") }} ({{ trip.shipments.length }})</v-card-title>

                <v-data-table :headers="headers" :items="trip.shipments" hide-default-footer :items-per-page="1000" class="elevation-1">
                    <template v-slot:item.code="{ item }">
                        <v-btn text color="info" :to="`/shipments/${item.id}`">{{ item.code }}</v-btn>
                    </template>
                    <template v-slot:item.price="{ item }">
                        {{ item.price + (item.fee_on_customer ? 0 : item.fee) }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn x-small color="error" fab dark @click="removeShipment(item)">
                            <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>

                <v-card-actions>
                    <v-btn min-width="100px" @click="unselectAll">{{ $t("options.unselect_all") }}</v-btn>
                </v-card-actions>
            </v-card>
            <!-- End selected shipment -->

            <!-- Start avaliable shipments -->
            <v-card>
                <v-card-title primary-title>{{ $t("attributes.available_shipments") }} ({{ new_shipments.length }})</v-card-title>

                <v-data-table :headers="headers" :items="new_shipments" hide-default-footer :items-per-page="1000" class="elevation-1">
                    <template v-slot:item.code="{ item }">
                        <v-btn text color="info" :to="`/shipments/${item.id}`">{{ item.code }}</v-btn>
                    </template>
                    <template v-slot:item.price="{ item }">
                        {{ item.price + (item.fee_on_customer ? 0 : item.fee) }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-btn x-small color="info" fab @click="addShipment(item)">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>

                <v-card-actions>
                    <v-btn min-width="100px" class="me-3" color="info" @click="selectAll">{{ $t("options.select_all") }}</v-btn>
                </v-card-actions>
            </v-card>
            <!-- End avaliable shipments -->
        </template>
        <!-- End Shipment -->
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";
import ShippingRoutesField from "@/components/data/ShippingRoutesField.vue";
import DeliversField from "@/components/data/DeliversField.vue";
import BranchesField from "@/components/data/BranchesField.vue";
import TripStatusesField from "@/components/data/TripStatusesField.vue";
import ShipmentAPI from "@/apis/shipmentApi";

export default {
    components: { TripStatusesField, DeliversField, BranchesField, ShippingRoutesField },
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        let id = routeTo.params.id;
        if (id) {
            store.dispatch("trip/fetchTrip", id).then(() => next());
        } else {
            next();
        }
    },
    data: function() {
        return {
            trip: {},
            new_shipments: [],
            errors: {},
            headers: [
                { text: this.$t("attributes.shipment"), value: "code" },
                { text: this.$t("attributes.price_with_shipping"), value: "price" },
                { text: this.$t("attributes.fee"), value: "fee" },
                { text: this.$t("attributes.deliver_fee"), value: "deliver_fee" },
                { text: this.$t("attributes.city"), value: "city.name" },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ]
        };
    },
    computed: {
        ...mapState({
            data: state => state.trip.trip
        }),

        breadcrumbs() {
            return [
                {
                    text: this.$t("trips.title"),
                    to: "/trips",
                    exact: true,
                    disabled: false
                },
                {
                    text: this.$t(`options.${this.isUpdate ? "update" : "add"}`),
                    exact: true,
                    disabled: true,
                    to: "/trips/create"
                }
            ];
        },

        isUpdate() {
            return !!this.trip.id;
        },

        canUpdateShipments() {
            return !this.isUpdate || this.trip.state == "on_the_way";
        },

        shipments_amount() {
            return this.trip.shipments && this.trip.shipments.reduce((sum, s) => (s.fee_on_customer && sum + s.price) || sum + s.price + s.fee, 0);
        },

        deliver_fee() {
            if (this.trip.state != "deliverd") return 0;
            return this.trip.shipments && this.trip.shipments.reduce((sum, s) => sum + s.deliver_fee, 0);
        }
    },

    created() {
        this.trip = (this.$route.params.id && this.data) || { shipments: [], deliver_fee: 0, start_date: _getCurrentDate() };
        if (this.trip.id) {
            this.trip.state = this.trip.state.id;
            this.trip.shipments = this.trip.shipments.map(s => s.shipment);
            this.trip.original_shipments = this.trip.shipments; // to use this in get original shipments of trip in update operation,
        }
    },

    watch: {
        shipments_amount(val) {
            this.trip.amount = val;
        },
        deliver_fee(val) {
            this.trip.deliver_fee = val;
            this.trip = Object.assign({}, this.trip); // to fix bug which dose not update value in field automaticly
        }
    },

    methods: {
        ...mapActions("trip", ["addTrip", "updateTrip"]),

        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        },

        /**
         * Add data to server
         */
        save() {
            this.errors = {};
            let trip = Object.assign({}, this.trip);
            trip.shipping_route_id = trip.shipping_route.id;
            trip.deliver_id = trip.deliver.id;
            trip.branch_id = trip.branch.id;
            delete trip.shipping_route;
            delete trip.deliver;
            delete trip.branch;
            trip.shipments = trip.shipments.map(s => s.id); // get all ids of shipments as array to send it to server

            this.$store.state.app.loading = true;
            (trip.id ? this.updateTrip(trip) : this.addTrip(trip))
                .then(r => {
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                    this.$router.push({ path: `/trips/${r.data.data.id}` });
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Bring shipments from server depends on shipping route
         */
        bringShipments() {
            if (!this.trip.shipping_route || !this.trip.branch) return;

            this.$store.state.app.loading = true;

            ShipmentAPI.getShipmentByShippingRoute(this.trip.branch.id, this.trip.shipping_route.id, this.trip.id)
                .then(r => {
                    this.trip.shipments = [];
                    this.new_shipments = [];
                    if (this.isUpdate) {
                        let original_shipments = this.trip.original_shipments.map(s => s.id);
                        r.data.data.forEach(s => {
                            original_shipments.includes(s.id) ? this.trip.shipments.push(s) : this.new_shipments.push(s);
                        });
                    } else {
                        this.new_shipments = r.data.data;
                    }
                })
                .catch(() => {
                    this.trip.shipments = [];
                    this.new_shipments = [];
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Add shipment
         */
        addShipment(item) {
            this.trip.shipments.push(item);
            this.new_shipments = this.new_shipments.filter(s => s.id != item.id);
        },

        /**
         * Remove shipment
         */
        removeShipment(item) {
            this.new_shipments.push(item);
            this.trip.shipments = this.trip.shipments.filter(s => s.id != item.id);
        },

        shippingRoutesChanged() {
            this.old_shipments = [];
            this.shipments = [];
            this.trip.shipments = []; // clear selected shipments of trip
        },

        /**
         * Select all shipments
         */
        selectAll() {
            this.trip.shipments = [...this.trip.shipments, ...this.new_shipments];
            this.new_shipments = [];
        },

        /**
         * Select all shipments
         */
        unselectAll() {
            this.new_shipments = [...this.new_shipments, ...this.trip.shipments];
            this.trip.shipments = [];
        }
    }
};
</script>
