<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('cities.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("cities.title") }}</div>

                    <!-- btn add new item  -->
                    <v-btn fab small :color="$store.state.app.color" @click.stop="prepareToAddItem"><v-icon>mdi-plus</v-icon></v-btn>
                </div>

                <!-- Start Add form dialog -->
                <dialog-base :type="(city.id && 'UPDATE') || 'ADD'" v-model="formDialog" @canceled="formDialogClose" @confirmed="validateAndSave">
                    <template #content>
                        <validation-observer ref="citiessForm" v-slot="{ handleSubmit }">
                            <v-form class="px-3" @submit.prevent="handleSubmit(validateAndSave)">
                                <!-- show errors if there is errors when add/update item -->
                                <has-errors :errors="errors"></has-errors>

                                <v-row>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|min:3|max:32" :name="$t('attributes.name')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="city.name"
                                                :label="$t('attributes.name')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="32"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|min:1|max:12" :name="$t('attributes.code')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="city.code"
                                                :label="$t('attributes.code')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="12"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|numeric" :name="$t('attributes.fee')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="city.fee"
                                                :label="$t('attributes.fee')"
                                                counter
                                                type="number"
                                                :error-messages="errors"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|numeric" :name="$t('attributes.deliver_fee')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="city.deliver_fee"
                                                :label="$t('attributes.deliver_fee')"
                                                counter
                                                type="number"
                                                :error-messages="errors"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <v-switch v-model="city.active" :label="$t('attributes.active')"></v-switch>
                                    </v-col>

                                    <v-col cols="12">
                                        <validation-provider rules="max:100" :name="$t('attributes.note')" v-slot="{ errors }">
                                            <v-textarea
                                                v-model.trim="city.note"
                                                :label="$t('attributes.note')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="100"
                                                rows="1"
                                                auto-grow
                                            ></v-textarea>
                                        </validation-provider>
                                    </v-col>
                                </v-row>

                                <!-- This button to allow to user to submit form by clicking enter -->
                                <button type="submit" class="hide"></button>
                            </v-form>
                        </validation-observer>
                    </template>
                </dialog-base>
                <!-- End Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>

                <!-- Start search options -->
                <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                    <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                        <v-row align="center">
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="search.name" :label="$t('attributes.name')" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field v-model.trim="search.code" :label="$t('attributes.code')" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                <v-btn class="me-2" :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                                <!-- <export-to-excel :url="'cities'" :filter="searchParamters"></export-to-excel> -->
                            </v-col>
                        </v-row>
                    </v-form>
                </validation-observer>
                <!-- End search options -->

                <v-divider></v-divider>

                <v-data-table
                    :headers="headers"
                    :items="data.data"
                    :items-per-page="100"
                    hide-default-footer
                    class="elevation-1"
                    :item-class="rowClass"
                >
                    <template v-slot:item.actions="{ item }">
                        <div style="width: 40px">
                            <v-btn x-small fab color="info" dark @click="prepareToUpdateItem(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>

                <!-- Start pagination -->
                <pagination :data="data.meta || {}" @page-changed="filterData" />
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;

    store.dispatch("city/fetchCities", { page, params }).then(() => {
        next();
    });
}

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    data: function() {
        return {
            formDialog: false,
            city: {},
            errors: {},
            search: Object.assign({}, this.$route.query),
            headers: [
                { text: this.$t("attributes.name"), value: "name" },
                { text: this.$t("attributes.code"), value: "code" },
                { text: this.$t("attributes.fee"), value: "fee" },
                { text: this.$t("attributes.deliver_fee"), value: "deliver_fee" },
                { text: this.$t("attributes.note"), value: "note" },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions" }
            ]
        };
    },

    computed: {
        ...mapState({
            data: state => state.city.cities
        })
    },

    methods: {
        ...mapActions("city", ["addCity", "updateCity"]),

        /**
         * Validate form if valid then call save method to send request to server
         */
        validateAndSave() {
            this.$refs.citiessForm.validate().then(success => {
                if (!success) {
                    return;
                }
                this.save();
            });
        },

        /**
         * Add data to server
         */
        save() {
            this.$store.state.app.loading = true;
            this.errors = {};

            // I took copy of object becuase I will change in properties of object and I do not want it effect in data that user insert them
            let city = Object.assign({}, this.city);

            (city.id ? this.updateCity(city) : this.addCity(city))
                .then(() => {
                    this.formDialogClose();
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Close form dialog which responsible on add/delete data
         */
        formDialogClose() {
            this.formDialog = false;
            this.errors = {};
            this.$refs.citiessForm.reset();
            this.city = {};
        },

        /**
         *  Set configuration for Add item
         */
        prepareToAddItem() {
            this.city = { active: false };
            this.formDialog = true;
        },

        /**
         *  Set configuration for update item
         */
        prepareToUpdateItem(item) {
            this.formDialog = true;
            this.city = Object.assign({}, item);
        },

        rowClass(item) {
            return item.active ? "" : "red white--text";
        },

        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        }
    }
};
</script>
