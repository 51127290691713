/**
 * Add global componenets to Vue
 *
 * This file to add global components to project
 */
import Vue from "vue";

Vue.component("base-card", require("@/components/global/BaseCard").default);
Vue.component("has-errors", require("@/components/global/HasErrors").default);
Vue.component("pagination", require("@/components/global/Pagination").default);
Vue.component("breadcrumbs", require("@/components/global/Breadcrumbs").default);
Vue.component("app-header", require("@/components/global/Header").default);
Vue.component("date-field", require("@/components/global/DateField").default);
Vue.component("password-field", require("@/components/global/PasswordField").default);
Vue.component("export-to-excel", require("@/components/global/ExportToExcel").default);
Vue.component("dialog-base", require("@/components/global/DialogBase").default);
