/**
 * This file to set routes for dashboard
 */

/**
 * Properties for these routes
 */
const meta = {
    requiresAuth: true /* route requires authentication */,
    layout: "dashboard-layout" /* route uses dashboard-layout */
};

const routes = [
    {
        path: "/Home",
        component: require(`@/views/dashboard/Home.vue`).default,
        meta,
        alias: "/"
    },
    {
        path: "/financial",
        component: require(`@/views/dashboard/Financial.vue`).default,
        meta
    },
    {
        path: "/users",
        component: require(`@/views/dashboard/users/Index.vue`).default,
        meta
    },
    {
        path: "/customers",
        component: require(`@/views/dashboard/customers/Index.vue`).default,
        meta
    },
    {
        path: "/customers/:id",
        component: require(`@/views/dashboard/customers/Show.vue`).default,
        meta,
        props: true
    },
    {
        path: "/delivers",
        component: require(`@/views/dashboard/delivers/Index.vue`).default,
        meta
    },
    {
        path: "/shipments",
        component: require(`@/views/dashboard/shipments/Index.vue`).default,
        meta
    },
    {
        path: "/shipments/print-multi",
        component: require(`@/views/dashboard/shipments/PrintShipments.vue`).default,
        meta: { ...meta, layout: "paper-layout" }
    },
    {
        path: "/shipments/create",
        component: require(`@/views/dashboard/shipments/AddAndUpdate.vue`).default,
        meta
    },
    {
        path: "/shipments/:id",
        component: require(`@/views/dashboard/shipments/Show.vue`).default,
        meta
    },
    {
        path: "/shipments/print/:id",
        component: require(`@/views/dashboard/shipments/Print.vue`).default,
        meta: { ...meta, layout: "paper-layout" }
    },
    {
        path: "/shipments/update/:id",
        component: require(`@/views/dashboard/shipments/AddAndUpdate.vue`).default,
        meta
    },
    {
        path: "/cities",
        component: require(`@/views/dashboard/Cities.vue`).default,
        meta
    },
    {
        path: "/shipping-routes",
        component: require(`@/views/dashboard/ShippingRoutes.vue`).default,
        meta
    },
    {
        path: "/trips",
        component: require(`@/views/dashboard/trips/Index.vue`).default,
        meta
    },
    {
        path: "/trips/create",
        component: require(`@/views/dashboard/trips/AddAndUpdate.vue`).default,
        meta
    },
    {
        path: "/trips/print/:id",
        component: require(`@/views/dashboard/trips/Print.vue`).default,
        meta: { ...meta, layout: "paper-layout" }
    },
    {
        path: "/trips/:id",
        component: require(`@/views/dashboard/trips/Show.vue`).default,
        meta
    },
    {
        path: "/trips/update/:id",
        component: require(`@/views/dashboard/trips/AddAndUpdate.vue`).default,
        meta
    },
    {
        path: "/adjustment-customers-available-balance",
        component: require(`@/views/dashboard/adjustment_customers/AvailableBalance.vue`).default,
        meta
    },
    {
        path: "/adjustment-customers",
        component: require(`@/views/dashboard/adjustment_customers/Index.vue`).default,
        meta
    },
    {
        path: "/adjustment-customers/create",
        component: require(`@/views/dashboard/adjustment_customers/AddAndUpdate.vue`).default,
        meta
    },
    {
        path: "/adjustment-customers/print/:id",
        component: require(`@/views/dashboard/adjustment_customers/Print.vue`).default,
        meta: { ...meta, layout: "paper-layout" }
    },
    {
        path: "/adjustment-customers/:id",
        component: require(`@/views/dashboard/adjustment_customers/Show.vue`).default,
        meta
    },
    {
        path: "/adjustment-customers/update/:id",
        component: require(`@/views/dashboard/adjustment_customers/AddAndUpdate.vue`).default,
        meta
    },
    {
        path: "/shipments-returned-to-customers",
        component: require(`@/views/dashboard/shipments_returned_to_customers/Index.vue`).default,
        meta
    },
    {
        path: "/shipments-returned-to-customers/create",
        component: require(`@/views/dashboard/shipments_returned_to_customers/AddAndUpdate.vue`).default,
        meta
    },
    {
        path: "/shipments-returned-to-customers/print/:id",
        component: require(`@/views/dashboard/shipments_returned_to_customers/Print.vue`).default,
        meta: { ...meta, layout: "paper-layout" }
    },
    {
        path: "/shipments-returned-to-customers/:id",
        component: require(`@/views/dashboard/shipments_returned_to_customers/Show.vue`).default,
        meta
    },
    {
        path: "/shipments-returned-to-customers/update/:id",
        component: require(`@/views/dashboard/shipments_returned_to_customers/AddAndUpdate.vue`).default,
        meta
    },
    {
        path: "/type-expenses",
        component: require(`@/views/dashboard/TypeOfExpenses.vue`).default,
        meta
    },
    {
        path: "/expenses",
        component: require(`@/views/dashboard/Expenses.vue`).default,
        meta
    },
    {
        path: "/type-revenues",
        component: require(`@/views/dashboard/TypeOfRevenues.vue`).default,
        meta
    },
    {
        path: "/revenues",
        component: require(`@/views/dashboard/Revenues.vue`).default,
        meta
    },
    {
        path: "/branches",
        component: require(`@/views/dashboard/Branches.vue`).default,
        meta
    }
];

export default routes;
