import axios from "axios";

window.axios = axios;

if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = "http://127.0.0.1:8000/api";
} else {
    axios.defaults.baseURL = "/api";
}

// Add a response interceptor
axios.interceptors.response.use(null, function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        location.reload();
    } else if (error.response && error.response.data.message) {
        Toast.error(error.response.data.message);
    } else {
        Toast.error(error);
    }

    return Promise.reject(error);
});
