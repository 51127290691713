import revenueApi from "@/apis/revenueApi";

export default {
    namespaced: true,

    state: {
        revenues: [],
        revenue: {}
    },

    mutations: {
        ADD(state, revenue) {
            if (state.revenues.data) {
                state.revenues.data.unshift(revenue);
                state.revenues.meta.to++;
                state.revenues.meta.total++;
            }
        },
        UPDATE(state, revenue) {
            if (state.revenues.data) {
                state.revenues.data = state.revenues.data.map(c => (c.id !== revenue.id ? c : revenue));
            }
        },
        SET_ITEMS(state, revenues) {
            state.revenues = revenues;
        },
        SET_ITEM(state, revenue) {
            state.revenue = revenue;
        }
    },

    actions: {
        add({ commit }, data) {
            return revenueApi.create(data).then(r => {
                Toast.success("Added successfully");
                commit("ADD", r.data.data);
                return Promise.resolve(r);
            });
        },

        fetchAll({ commit }, { page = 1, params = {} }) {
            return revenueApi.getAll(page, params).then(r => {
                commit("SET_ITEMS", r.data);
            });
        },

        fetchById({ commit }, id) {
            return revenueApi.getById(id).then(r => {
                commit("SET_ITEM", r.data.data);
                return Promise.resolve(r);
            });
        },

        update({ commit }, data) {
            return revenueApi.update(data).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE", r.data.data);
                return Promise.resolve(r);
            });
        }
    }
};
