<template>
    <v-text-field
        :prepend-icon="showIcon ? 'mdi-lock' : ''"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        :value="value"
        :label="label || $t('attributes.password')"
        :maxlength="maxlength"
        @input="$emit('input', $event)"
        :error-messages="errorMessages"
        :clearable="clearable"
        :outlined="outlined"
        counter
    ></v-text-field>
</template>

<script>
export default {
    name: "PasswordField",

    props: {
        value: { type: String },
        label: { type: String },
        errorMessages: { type: [String, Array] },
        maxlength: { type: Number, default: 32 },
        showIcon: { type: [String, Boolean], default: true },
        clearable: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false }
    },

    data: function() {
        return {
            showPassword: false
        };
    }
};
</script>
