import Axios from "axios";

const base_url = "cp/shipments";

export default {
    /**
     * Display a listing of the Shipments.
     *
     * @param {int} page number of page for pagination
     * @param {object} params filter (search) options
     * @returns {Promise}
     */
    getAll(page = 1, params = {}) {
        return Axios.get(`${base_url}?page=${page}`, { params });
    },

    /**
     * Display a listing of the Shipments depends on branch , shipping_route and trip_id.
     *
     * @param {int} branch branch id
     * @param {int} shipping_route shipping_route id
     * @param {int} trip_id trip_id id
     * @returns {Promise}
     */
    getShipmentByShippingRoute(branch, shipping_route, trip_id) {
        return Axios.get(`${base_url}/shipping-route/${branch}/${shipping_route}`, { params: { trip_id } });
    },

    /**
     * Display a listing of the Shipments depends on customer and adjustment_customer_id.
     *
     * @param {int} customer customer id
     * @param {int} adjustment_customer_id adjustment_customer id
     * @returns {Promise}
     */
    getShipmentByCustomer(customer, adjustment_customer_id) {
        return Axios.get(`${base_url}/customer/${customer}`, { params: { adjustment_customer_id } });
    },

    /**
     * Display a listing of the Shipments depends on customer and returned_to_customer_id .
     *
     * @param {int} customer customer id
     * @param {int} returned_to_customer_id  adjustment_customer id
     * @returns {Promise}
     */
    getShipmentByCustomerAndReturning(customer, returned_to_customer_id) {
        return Axios.get(`${base_url}/for-returning/${customer}`, { params: { returned_to_customer_id } });
    },

    /**
     * Store a new Shipment in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    create(data) {
        return Axios.post(base_url, data);
    },

    /**
     * Show data for a given Shipment.
     *
     * @param {int} id
     * @returns {Promise}
     */
    getById(id) {
        return Axios.get(`${base_url}/${id}`);
    },

    /**
     * Update the given Shipment in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    update(data) {
        return Axios.put(`${base_url}/${data.id}`, data);
    },

    /**
     * Remove the specified Shipment from server.
     *
     * @param {int} id
     * @returns {Promise}
     */
    delete(id) {
        return Axios.delete(`${base_url}/${id}`);
    }
};
