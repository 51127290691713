<template>
    <div>
        <breadcrumbs :items="breadcrumbs"></breadcrumbs>

        <base-card class="my-4 mx-auto" :icon="$t('shipments_returned_to_customers.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline card-main-title">{{ isUpdate ? returning.id : $t("options.add") }}</div>
                </div>

                <!-- Start Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>

                <validation-observer ref="returningForm" v-slot="{ handleSubmit }">
                    <v-form class="px-3" @submit.prevent="handleSubmit(save)">
                        <!-- show errors if there is errors when add/update item -->
                        <has-errors :errors="errors"></has-errors>

                        <!-- Start returning data -->
                        <v-row class="pt-5">
                            <!-- Start Customer data -->
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="numeric|min:1" :name="$t('attributes.customer')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        clearable
                                        v-model.trim="returning.customer_id"
                                        :label="$t('attributes.customer')"
                                        :error-messages="errors"
                                        counter
                                        type="number"
                                        append-icon="mdi-magnify"
                                        @click:append="bringCustomer"
                                        @input="customerChanged"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|min:3|max:32" :name="$t('attributes.sender_name')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        v-model.trim="returning.customer.name"
                                        readonly
                                        :label="$t('attributes.sender_name')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="32"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|phone" :name="$t('attributes.sender_phone')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        readonly
                                        v-model.trim="returning.customer.phone"
                                        :label="$t('attributes.sender_phone')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="10"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="phone" :name="$t('attributes.sender_phone2')" v-slot="{ errors }">
                                    <v-text-field
                                        outlined
                                        readonly
                                        v-model.trim="returning.customer.phone2"
                                        :label="$t('attributes.sender_phone2')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="10"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <!-- End Customer data -->

                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field
                                    outlined
                                    :value="returning.shipments.length || 0"
                                    :label="$t('attributes.count')"
                                    counter
                                    type="number"
                                    readonly
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" lg="3">
                                <validation-provider rules="required|date|before_or_equal:today" :name="$t('attributes.date')" v-slot="{ errors }">
                                    <date-field
                                        outlined
                                        v-model="returning.date"
                                        :label="$t('attributes.date')"
                                        :error-messages="errors"
                                    ></date-field>
                                </validation-provider>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="6">
                                <validation-provider rules="max:200" :name="$t('attributes.note')" v-slot="{ errors }">
                                    <v-textarea
                                        v-model.trim="returning.note"
                                        :label="$t('attributes.note')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="200"
                                        rows="1"
                                        outlined
                                        clearable
                                        auto-grow
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12" md="6">
                                <validation-provider rules="max:200" :name="$t('attributes.extra')" v-slot="{ errors }">
                                    <v-textarea
                                        v-model.trim="returning.extra"
                                        :label="$t('attributes.extra')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="200"
                                        rows="1"
                                        outlined
                                        clearable
                                        auto-grow
                                    ></v-textarea>
                                </validation-provider>
                            </v-col>
                        </v-row>
                        <!-- End returning data -->

                        <v-divider></v-divider>

                        <div class="text-end px-5 py-3">
                            <v-btn color="info" min-width="150px" type="submit">{{ $t("options.save") }}</v-btn>
                        </div>
                    </v-form>
                </validation-observer>
                <!-- End Add form dialog -->
            </template>
        </base-card>

        <!-- Start selected shipment -->
        <v-card class="my-8">
            <v-card-title primary-title>{{ $t("attributes.selected_shipments") }} ({{ returning.shipments.length }})</v-card-title>

            <v-data-table :headers="headers" :items="returning.shipments" hide-default-footer :items-per-page="1000" class="elevation-1">
                <template v-slot:item.code="{ item }">
                    <v-btn text color="info" :to="`/shipments/${item.id}`">{{ item.code }}</v-btn>
                </template>
                <template v-slot:item.state.name="{ item }">
                    <v-chip :color="item.state.color" dark>{{ item.state.name }}</v-chip>
                </template>
                <template v-slot:item.price_with_shipping="{ item }">
                    {{ item.price + (item.fee_on_customer ? 0 : item.fee) }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn x-small color="error" fab dark @click="removeShipment(item)">
                        <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                </template>
            </v-data-table>

            <v-card-actions>
                <v-btn min-width="100px" @click="unselectAll">{{ $t("options.unselect_all") }}</v-btn>
            </v-card-actions>
        </v-card>
        <!-- End selected shipment -->

        <!-- Start avaliable shipments -->
        <v-card>
            <v-card-title primary-title>{{ $t("attributes.available_shipments") }} ({{ new_shipments.length }})</v-card-title>

            <v-data-table :headers="headers" :items="new_shipments" hide-default-footer :items-per-page="1000" class="elevation-1">
                <template v-slot:item.code="{ item }">
                    <v-btn text color="info" :to="`/shipments/${item.id}`">{{ item.code }}</v-btn>
                </template>
                <template v-slot:item.state.name="{ item }">
                    <v-chip :color="item.state.color" dark>{{ item.state.name }}</v-chip>
                </template>
                <template v-slot:item.price_with_shipping="{ item }">
                    {{ item.price + (item.fee_on_customer ? 0 : item.fee) }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn x-small color="info" fab @click="addShipment(item)">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
            </v-data-table>

            <v-card-actions>
                <v-btn min-width="100px" class="me-3" color="info" @click="selectAll">{{ $t("options.select_all") }}</v-btn>
            </v-card-actions>
        </v-card>
        <!-- End avaliable shipments -->
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";
import ShipmentAPI from "@/apis/shipmentApi";
import CustomerApi from "@/apis/customerApi";

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        let id = routeTo.params.id;
        if (id) {
            store.dispatch("shipmentReturnedToCustomer/fetchById", id).then(() => next());
        } else {
            next();
        }
    },
    data: function() {
        return {
            returning: {},
            new_shipments: [],
            errors: {},
            headers: [
                { text: this.$t("attributes.shipment"), value: "code" },
                { text: this.$t("attributes.state"), value: "state.name" },
                { text: this.$t("attributes.city"), value: "city.name" },
                { text: this.$t("attributes.receiver_phone"), value: "receiver_phone" },
                { text: this.$t("attributes.price_with_shipping"), value: "price_with_shipping" },
                { text: this.$t("attributes.fee"), value: "fee" },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ]
        };
    },
    computed: {
        ...mapState({
            data: state => state.shipmentReturnedToCustomer.item
        }),

        breadcrumbs() {
            return [
                {
                    text: this.$t("shipments_returned_to_customers.title"),
                    to: "/shipments-returned-to-customers",
                    exact: true,
                    disabled: false
                },
                {
                    text: this.$t(`options.${this.isUpdate ? "update" : "add"}`),
                    exact: true,
                    disabled: true,
                    to: "/shipments-returned-to-customers/create"
                }
            ];
        },

        isUpdate() {
            return !!this.returning.id;
        }
    },

    created() {
        this.returning = (this.$route.params.id && this.data) || { shipments: [], customer: {}, date: _getCurrentDate() };
        if (this.returning.id) {
            this.returning.original_shipments = this.returning.shipments; // to use this in get original shipments of returning in update operation,
        }
    },

    methods: {
        ...mapActions("shipmentReturnedToCustomer", ["add", "update"]),

        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        },

        customerChanged() {
            this.returning.customer = {};
            this.new_shipments = [];
            this.returning.shipments = [];
        },

        bringCustomer() {
            if (!this.returning.customer_id) return;

            this.$store.state.app.loading = true;

            CustomerApi.getById(this.returning.customer_id)
                .then(r => {
                    this.returning.customer = r.data.data;
                    this.bringShipments();
                })
                .catch(() => (this.returning.customer = {}))
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Bring shipments from server depends on customer
         */
        bringShipments() {
            ShipmentAPI.getShipmentByCustomerAndReturning(this.returning.customer_id, this.returning.id)
                .then(r => {
                    this.returning.shipments = [];
                    this.new_shipments = [];
                    r.data.data.map(s => (s.state = this.$store.getters["shipment/getStateById"](s.state)));
                    if (this.isUpdate) {
                        let original_shipments = this.returning.original_shipments.map(s => s.id);
                        r.data.data.forEach(s => {
                            original_shipments.includes(s.id) ? this.returning.shipments.push(s) : this.new_shipments.push(s);
                        });
                    } else {
                        this.new_shipments = r.data.data;
                    }
                })
                .catch(e => {
                    this.returning.shipments = [];
                    this.new_shipments = [];
                });
        },

        /**
         * Add shipment
         */
        addShipment(item) {
            this.returning.shipments.push(item);
            this.new_shipments = this.new_shipments.filter(s => s.id != item.id);
        },

        /**
         * Remove shipment
         */
        removeShipment(item) {
            this.new_shipments.push(item);
            this.returning.shipments = this.returning.shipments.filter(s => s.id != item.id);
        },

        /**
         * Add data to server
         */
        save() {
            this.errors = {};
            this.$store.state.app.loading = true;
            let returning = Object.assign({}, this.returning);
            returning.shipments = returning.shipments.map(s => s.id);

            (returning.id ? this.update(returning) : this.add(returning))
                .then(r => {
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                    this.$router.push({ path: `/shipments-returned-to-customers/${r.data.data.id}` });
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Select all shipments
         */
        selectAll() {
            this.returning.shipments = [...this.returning.shipments, ...this.new_shipments];
            this.new_shipments = [];
        },

        /**
         * Select all shipments
         */
        unselectAll() {
            this.new_shipments = [...this.new_shipments, ...this.returning.shipments];
            this.returning.shipments = [];
        }
    }
};
</script>
