<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('customers.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("customers.title") }}</div>

                    <!-- btn add new item  -->
                    <v-btn fab small :color="$store.state.app.color" @click.stop="prepareToAddItem"><v-icon>mdi-plus</v-icon></v-btn>
                </div>

                <!-- Start Add form dialog -->
                <dialog-base :type="(isUpdate && 'UPDATE') || 'ADD'" v-model="formDialog" @canceled="formDialogClose" @confirmed="validateAndSave">
                    <template #content>
                        <validation-observer ref="customersForm" v-slot="{ handleSubmit }">
                            <v-form class="px-3" @submit.prevent="handleSubmit(validateAndSave)">
                                <!-- show errors if there is errors when add/update item -->
                                <has-errors :errors="errors"></has-errors>

                                <v-row>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|min:3|max:32" :name="$t('attributes.name')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="customer.name"
                                                :label="$t('attributes.name')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="32"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|min:3|max:32" :name="$t('attributes.owner_name')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="customer.owner_name"
                                                :label="$t('attributes.owner_name')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="32"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|phone" :name="$t('attributes.phone')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="customer.phone"
                                                :label="$t('attributes.phone')"
                                                counter
                                                :maxlength="10"
                                                :error-messages="errors"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="phone" :name="$t('attributes.phone')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="customer.phone2"
                                                :label="$t('attributes.phone')"
                                                counter
                                                :maxlength="10"
                                                :error-messages="errors"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <validation-provider rules="required|email" :name="$t('attributes.email')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="customer.email"
                                                :label="$t('attributes.email')"
                                                counter
                                                type="email"
                                                :error-messages="errors"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider rules="url" :name="$t('attributes.website')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="customer.website"
                                                :label="$t('attributes.website')"
                                                counter
                                                type="url"
                                                :error-messages="errors"
                                                maxlength="120"
                                                clearable
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <validation-provider
                                            :rules="`${isUpdate ? '' : 'required|'}min:6|max:32`"
                                            :name="$t('attributes.password')"
                                            v-slot="{ errors }"
                                        >
                                            <password-field
                                                v-model.trim="customer.password"
                                                :label="$t('attributes.password')"
                                                :error-messages="errors"
                                                counter
                                            >
                                            </password-field>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-switch
                                            v-model="customer.active"
                                            false-value="0"
                                            true-value="1"
                                            :label="$t('attributes.active')"
                                        ></v-switch>
                                    </v-col>

                                    <v-col cols="12" v-if="!isUpdate">
                                        <validation-provider rules="required" :name="$t('attributes.file')" v-slot="{ errors }">
                                            <v-file-input
                                                v-model="customer.file"
                                                :label="$t('attributes.file')"
                                                show-size
                                                counter
                                                :error-messages="errors"
                                                accept="image/*, application/pdf"
                                            ></v-file-input>
                                        </validation-provider>
                                    </v-col>

                                    <v-col cols="6">
                                        <validation-provider rules="required|min:6|max:100" :name="$t('attributes.address')" v-slot="{ errors }">
                                            <v-textarea
                                                v-model.trim="customer.address"
                                                :label="$t('attributes.address')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="100"
                                                rows="1"
                                                auto-grow
                                            ></v-textarea>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="6">
                                        <validation-provider rules="max:100" :name="$t('attributes.note')" v-slot="{ errors }">
                                            <v-textarea
                                                v-model.trim="customer.note"
                                                :label="$t('attributes.note')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="100"
                                                rows="1"
                                                auto-grow
                                            ></v-textarea>
                                        </validation-provider>
                                    </v-col>
                                </v-row>

                                <!-- This button to allow to user to submit form by clicking enter -->
                                <button type="submit" class="hide"></button>
                            </v-form>
                        </validation-observer>
                    </template>
                </dialog-base>
                <!-- End Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>

                <!-- Start search options -->
                <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                    <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                        <v-row align="center">
                            <v-col cols="12" sm="6" lg="3" class="pb-0" v-for="(field, index) in searchFields" :key="index">
                                <v-text-field v-model.trim="search[field]" :label="$t(`attributes.${field}`)" maxlength="32" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                <v-btn class="me-2" :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </validation-observer>
                <!-- End search options -->

                <v-divider></v-divider>

                <v-data-table
                    :headers="headers"
                    :items="data.data"
                    :items-per-page="100"
                    hide-default-footer
                    class="elevation-1"
                    :item-class="rowClass"
                >
                    <template v-slot:item.actions="{ item }">
                        <div style="width: 80px">
                            <v-btn x-small color="info" class="me-1" fab :to="`/customers/${item.id}`">
                                <v-icon>mdi-information-variant</v-icon>
                            </v-btn>
                            <v-btn x-small fab color="info" dark @click="prepareToUpdateItem(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>

                <!-- Start pagination -->
                <pagination :data="data.meta || {}" @page-changed="filterData" />
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;

    store.dispatch("customer/fetchCustomers", { page, params }).then(() => {
        next();
    });
}

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    data: function() {
        return {
            formDialog: false,
            customer: {},
            errors: {},
            search: Object.assign({}, this.$route.query),
            searchFields: ["id", "name", "owner_name", "phone", "email", "address"],
            headers: [
                { text: this.$t("attributes.id"), value: "id" },
                { text: this.$t("attributes.name"), value: "name" },
                { text: this.$t("attributes.owner_name"), value: "owner_name" },
                { text: this.$t("attributes.phone"), value: "phone" },
                { text: this.$t("attributes.email"), value: "email" },
                { text: this.$t("attributes.note"), value: "note" },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions" }
            ]
        };
    },

    computed: {
        ...mapState({
            data: state => state.customer.customers
        }),
        isUpdate() {
            return !!this.customer.id;
        }
    },

    methods: {
        ...mapActions("customer", ["addCustomer", "updateCustomer"]),

        /**
         * Validate form if valid then call save method to send request to server
         */
        validateAndSave() {
            this.$refs.customersForm.validate().then(success => {
                if (!success) {
                    return;
                }
                this.save();
            });
        },

        /**
         * Add data to server
         */
        save() {
            this.$store.state.app.loading = true;
            this.errors = {};

            // I took copy of object becuase I will change in properties of object and I do not want it effect in data that user insert them
            let customer = Object.assign({}, this.customer);
            let data = null;

            if (this.isUpdate) {
                data = customer;
            } else {
                data = new FormData();
                for (let e in customer) {
                    data.append(e, customer[e]);
                }
                if (typeof customer.file != "string" && customer.file != null) {
                    data.append("file", customer.file, customer.file.name);
                } else {
                    data.delete("file");
                }
                data.id = customer.id;
            }

            (this.isUpdate ? this.updateCustomer(data) : this.addCustomer(data))
                .then(() => {
                    this.formDialogClose();
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Close form dialog which responsible on add/delete data
         */
        formDialogClose() {
            this.formDialog = false;
            this.errors = {};
            this.$refs.customersForm.reset();
            this.customer = {};
        },

        /**
         *  Set configuration for Add item
         */
        prepareToAddItem() {
            this.customer = { active: false };
            this.formDialog = true;
        },

        /**
         *  Set configuration for update item
         */
        prepareToUpdateItem(item) {
            this.formDialog = true;
            this.customer = Object.assign({}, item);
        },

        rowClass(item) {
            return (!item.activated_at && "green white--text") || (!item.active && "red white--text");
        },

        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        }
    }
};
</script>
