import customerApi from "@/apis/customerApi";

export default {
    namespaced: true,

    state: {
        customers: [],
        customer: {}
    },

    getters: {
        getById: state => id => {
            return state.customers.data && state.customers.data.find(item => item.id === id);
        }
    },

    mutations: {
        ADD_CUSTOMER(state, customer) {
            if (state.customers.data) {
                state.customers.data.unshift(customer);
                state.customers.meta.to++;
                state.customers.meta.total++;
            }
        },
        UPDATE_CUSTOMER(state, customer) {
            state.customers.data = [...state.customers.data.map(c => (c.id !== customer.id ? c : customer))];
        },
        SET_CUSTOMERS(state, customers) {
            state.customers = customers;
        },
        SET_CUSTOMER(state, customer) {
            state.customer = customer;
        },
        DELETE_CUSTOMER(state, id) {
            if (state.customers.data) {
                state.customers.data = state.customers.data.filter(item => item.id !== id);
                state.customers.meta.to--;
                state.customers.meta.total--;
            }
        }
    },

    actions: {
        addCustomer({ commit }, customer) {
            return customerApi.create(customer).then(r => {
                Toast.success("Added successfully");
                commit("ADD_CUSTOMER", r.data.data);
            });
        },

        fetchCustomers({ commit }, { page = 1, params = {} }) {
            return customerApi.getAll(page, params).then(r => {
                commit("SET_CUSTOMERS", r.data);
            });
        },

        fetchCustomer({ commit }, id) {
            return customerApi.getById(id).then(r => {
                commit("SET_CUSTOMER", r.data.data);
            });
        },

        updateCustomer({ commit }, customer) {
            return customerApi.update(customer).then(r => {
                Toast.success("The update was successful");
                commit("UPDATE_CUSTOMER", r.data.data);
            });
        },

        deleteCustomer({ commit }, id) {
            return customerApi.delete(id).then(r => {
                Toast.success("Successfully deleted");
                commit("DELETE_CUSTOMER", id);
            });
        }
    }
};
