import Axios from "axios";

const base_url = "cp/expenses";

export default {
    /**
     * Display a listing of the Expenses.
     *
     * @param {int} page number of page for pagination
     * @param {object} params filter (search) options
     * @returns {Promise}
     */
    getAll(page = 1, params = {}) {
        return Axios.get(`${base_url}?page=${page}`, { params });
    },

    /**
     * Store a new Expenses in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    create(data) {
        return Axios.post(base_url, data);
    },

    /**
     * Show data for a given Expense.
     *
     * @param {int} id
     * @returns {Promise}
     */
    getById(id) {
        return Axios.get(`${base_url}/${id}`);
    },

    /**
     * Update the given Expenses in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    update(data) {
        return Axios.put(`${base_url}/${data.id}`, data);
    }
};
