import delegatesApi from "@/apis/customerDelegateApi";

export default {
    namespaced: true,

    state: {
        delegates: []
    },

    getters: {
        getById: state => id => {
            return state.delegates.data && state.delegates.data.find(item => item.id === id);
        }
    },

    mutations: {
        ADD_DELEGATE(state, delegate) {
            if (state.delegates.data) {
                state.delegates.data.unshift(delegate);
                state.delegates.meta.to++;
                state.delegates.meta.total++;
            }
        },
        UPDATE_DELEGATE(state, delegate) {
            state.delegates.data = [...state.delegates.data.map(e => (e.id !== delegate.id ? e : delegate))];
        },
        SET_DELEGATES(state, delegates) {
            state.delegates = delegates;
        },
        DELETE_DELEGATE(state, id) {
            if (state.delegates.data) {
                state.delegates.data = state.delegates.data.filter(item => item.id !== id);
                state.delegates.meta.to--;
                state.delegates.meta.total--;
            }
        }
    },

    actions: {
        addDelegate({ commit }, data) {
            return delegatesApi.create(data).then(r => {
                Toast.success("Added successfully");
                commit("ADD_DELEGATE", r.data.data);
            });
        },

        fetchDelegates({ commit }, { page = 1, params = {} }) {
            return delegatesApi.getAll(page, params).then(r => {
                commit("SET_DELEGATES", r.data);
            });
        },

        fetchDelegate({ commit }, id) {
            return delegatesApi.getById(id).then(r => {
                commit("SET_DELEGATES", r.data.data);
            });
        },

        fetchDelegateByCustomerId({ commit }, id) {
            return delegatesApi.getByCustomerId(id).then(r => {
                commit("SET_DELEGATES", r.data.data);
            });
        },

        updateDelegate({ commit }, delegate) {
            return delegatesApi.update(delegate).then(r => {
                Toast.success("The update was successful");
                window.rrr = r;
                commit("UPDATE_DELEGATE", r.data.data);
            });
        },

        deleteDelegate({ commit }, id) {
            return delegatesApi.delete(id).then(r => {
                Toast.success("Successfully deleted");
                commit("DELETE_DELEGATE", id);
            });
        }
    }
};
